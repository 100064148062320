//Pathway.jsx
import React, { useEffect, useState } from "react";
import Button from "../../commonComponents/Button";
import AutocompleteTextField from "../../commonComponents/AutocompleteTextField";
import {
  AspirationAPI,
  IndustryAPI,
  QualificationAPI,
  CourseListAPI,
  JobAPI,
  GetIndustryListFilteredAPI,
} from "../../utils/apiUrl";
import callAPI from "../../utils/apiAction";
import PathwayPersonalize from "./PathwayPersonalize";
import PathwayCombo from "./PathwayCombo";
import AlertComponent from "../../commonComponents/AlertComponent";
import { useScroll } from "../../commonComponents/ScrollContext";
import { useLoginState } from "../../utils/LoginStateContext";
const Pathway = () => {
  const roleURL = AspirationAPI;
  const industryURL = IndustryAPI;
  const qualificationURL = QualificationAPI;
  const jobURL = JobAPI;
  const [aspirationData, setAspirationData] = useState(null);
  const [industryData, setIndustryData] = useState(null);
  const [qualificationData, setQualificationData] = useState(null);
  const [jobData, setJobData] = useState(null);
  const [allSelectionMade, setAllSelectionMade] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [isRoleDisabled, setIsRoleDisabled] = useState(true);
  const [isIndustryDisabled, setIsIndustryDisabled] =  useState(true);
  const {
    selectedQualification,
    selectedIndustry,
    selectedRole,
    setSelectedRole,
    setSelectedIndustry,
    setSelectedQualification,
  } = useLoginState();

  const [displayBundle, setDisplayBundle] = useState(false);
  const [displayFindButton, setDisplayFindButton] = useState(true);
  const [roleId, setRoleId] = useState(0);
  const [industryId, setIndustryId] = useState(0);
  const [qualificationId, setQualificationId] = useState(0);
  const [qualificationError, setQualificationError] = useState("");
const [roleError, setRoleError] = useState("");
const [industryError, setIndustryError] = useState("");


  const { pathwayRef } = useScroll();

  const noOptions = [
    {
      id: "no_options_001",
      qualification: "No Options",
      aspirationDesc: "No Options",
      industryName: "No Options",
    },
  ];
  const isLoggedIn = () => {
    const userinfo = sessionStorage.getItem("userInfo");
    return userinfo && userinfo !== "null" && userinfo !== "undefined";
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [qualificationResponse, industryResponse, roleResponse] =
          await Promise.all([
            callAPI(qualificationURL, "POST"),
            callAPI(industryURL, "POST"),
            callAPI(roleURL, "POST"),
          ]);
        const { data: qualificationData } = qualificationResponse;
        const { data: industryData } = industryResponse;
        const { data: roleData } = roleResponse;
        // console.log(industryData);
        setAspirationData(roleData);
        setIndustryData(industryData);
        setQualificationData(qualificationData);
      } catch (e) {
        console.log(e);
      }
    };
    fetchData();
  }, []);
  const updateIds = () => {
    setRoleId(selectedRole?.id);
    setIndustryId(selectedIndustry?.id);
    setQualificationId(selectedQualification?.qualification_id);
  };
  useEffect(() => {
    if (!isLoggedIn()) {
      // Reset state and session storage if the user is not logged in
      setSelectedQualification(null);
      setSelectedRole(null);
      setSelectedIndustry(null);
      sessionStorage.removeItem("selectedQualification");
      sessionStorage.removeItem("selectedRole");
      sessionStorage.removeItem("selectedIndustry");
    }
  }, []);
  sessionStorage.setItem("RoleID", roleId);
  sessionStorage.setItem("IndustryID", industryId);
  sessionStorage.setItem("QualificationID", qualificationId);
  
  // const handleQualificationChange = (event, newValue) => {
  //   setSelectedQualification(newValue);
  //   sessionStorage.setItem("selectedQualification", JSON.stringify(newValue));
  //   setDisplayFindButton(true);
  //   setJobData(null);
  //   setDisplayBundle(false);
  //   setIsRoleDisabled(false);
  // };
  // const handleRoleChange = (event, newValue) => {
  //   setSelectedRole(newValue);
  //   sessionStorage.setItem("selectedRole", JSON.stringify(newValue));
  //   setDisplayFindButton(true);
  //   setJobData(null);

  //   setDisplayBundle(false);
  //   setIsIndustryDisabled(false);
  // };

  const handleIndustryChange = (event, newValue) => {
    if (newValue) {
      if(newValue && newValue.industryName === "No Options"){
        setIndustryError("Please select an industry.")
      }
      else{
        setIndustryError(""); // Clear error when a valid option is selected

      }
  }
  console.log(newValue && newValue.industryName);
    setSelectedIndustry(newValue);
    sessionStorage.setItem("selectedIndustry", JSON.stringify(newValue));
    setDisplayFindButton(true);
    setJobData(null);

    setDisplayBundle(false);
  };

//   const handleQualificationChange = (event, newValue) => {
//   // Reset the role and industry when qualification is changed
//   setSelectedQualification(newValue);
//   sessionStorage.setItem("selectedQualification", JSON.stringify(newValue));

//   // Reset role and industry
//   setSelectedRole(null);
//   setSelectedIndustry(null);
  
//   sessionStorage.removeItem("selectedRole");
//   sessionStorage.removeItem("selectedIndustry");

//   // Disable the role and industry dropdowns after reset
//   setIsRoleDisabled(false);   // Enable role dropdown after qualification change
//   setIsIndustryDisabled(true); // Keep industry dropdown disabled

//   setDisplayFindButton(true);
//   setJobData(null);
//   setDisplayBundle(false);
// };

// const handleRoleChange = (event, newValue) => {
//   // Reset the industry when role is changed
//   setSelectedRole(newValue);
//   sessionStorage.setItem("selectedRole", JSON.stringify(newValue));

//   // Reset industry
//   setSelectedIndustry(null);
//   sessionStorage.removeItem("selectedIndustry");

//   // Disable the industry dropdown after reset
//   setIsIndustryDisabled(false);  // Enable industry dropdown after role change

//   setDisplayFindButton(true);
//   setJobData(null);
//   setDisplayBundle(false);
// };

const handleQualificationChange = (event, newValue) => {
  // Set the selected qualification and reset role and industry if qualification is changed
  if (newValue) {
    setQualificationError(""); // Clear error when a valid option is selected
  }
  setSelectedQualification(newValue);
  sessionStorage.setItem("selectedQualification", JSON.stringify(newValue));

  // If the qualification is cleared (deleted), reset and disable role and industry dropdowns
  if (!newValue) {
    setSelectedRole(null);
    setSelectedIndustry(null);
    sessionStorage.removeItem("selectedRole");
    sessionStorage.removeItem("selectedIndustry");
    setIsRoleDisabled(true);      // Disable role dropdown when no qualification
    setIsIndustryDisabled(true);  // Disable industry dropdown when no qualification
  } else {
    setSelectedRole(null);        // Reset role when qualification is changed
    setSelectedIndustry(null);    // Reset industry when qualification is changed
    setIsRoleDisabled(false);     // Enable role dropdown when qualification exists
    setIsIndustryDisabled(true);  // Industry remains disabled until role is selected
  }

  setDisplayFindButton(true);
  setJobData(null);
  setDisplayBundle(false);
};

const handleRoleChange = (event, newValue) => {
  if (newValue) {
    setRoleError(""); // Clear error when a valid option is selected
  }
  // Set the selected role and reset the industry if role is changed
  setSelectedRole(newValue);
  sessionStorage.setItem("selectedRole", JSON.stringify(newValue));

  // If the role is cleared (deleted), reset and disable the industry dropdown
  if (!newValue) {
    setSelectedIndustry(null);
    sessionStorage.removeItem("selectedIndustry");
    setIsIndustryDisabled(true);  // Disable industry dropdown when no role
  } else {
    setSelectedIndustry(null);    // Reset industry when role is changed
    setIsIndustryDisabled(false); // Enable industry dropdown when role exists
  }

  setDisplayFindButton(true);
  setJobData(null);
  setDisplayBundle(false);
};


  const checkAllSelection = () => {
    const hasRole = selectedQualification !== null;
    const hasIndustry = selectedIndustry !== null;
    const hasQualification = selectedQualification !== null;
    setAllSelectionMade(hasIndustry && hasQualification && hasRole);
  };

  useEffect(() => {
    checkAllSelection();
    updateIds();
    
  }, [selectedIndustry, selectedQualification, selectedRole]);

  // const handleFindPathway = () => {
  //   if (!allSelectionMade) {
  //     setShowAlert(true);
  //     return;
  //   }

  //   setDisplayBundle(true);
  //   setDisplayFindButton(false);

  // };
  const handleFindPathway = () => {
  let hasError = false;

  // Reset errors
  setQualificationError("");
  setRoleError("");
  setIndustryError("");

  // Check qualification
  if (!selectedQualification) {
    setQualificationError("Please select a qualification.");
    hasError = true;
  }

  // Check role
  if (!selectedRole) {
    setRoleError("Please select a role.");
    hasError = true;
  }

  // Check industry
  if (!selectedIndustry) {
    setIndustryError("Please select an industry.");
      hasError = true;
    
  }
  if(selectedIndustry && selectedIndustry.industryName === "No Options")
    {
      setIndustryError("Please select an industry.");
      hasError = true;
    }

  if (hasError) {
    return;
  }

  setDisplayBundle(true);
  setDisplayFindButton(false);
};


  useEffect(() => {
    const queryParams = {
      _industry: selectedIndustry?.id,
      _qualification: selectedQualification?.qualification_id,
      _aspiration: selectedRole?.id,
      _courseId: 0,
      _unvId: 0,
      _locationId: 0,
      _jobId: 0,
    };
    const fetchComboData = async () => {
      try {
        const { data } = await callAPI(jobURL, "POST", queryParams, {
          Authorization: "Bearer YourAccessToken",
        });
        // Sort the data
    // const sortedData = data.sort((a, b) => {
    //   if (a.companyLogo && b.companyLogo) return 0;
    //   if (a.companyLogo) return -1;
    //   if (b.companyLogo) return 1;
    //   return 0;
    // });

    setJobData(data);
      } catch (error) {
        console.log(error);
      }
    };
    if (allSelectionMade) {
      fetchComboData();
    }
  }, [selectedIndustry, selectedQualification, selectedRole, allSelectionMade]);
  
  useEffect(() => {
    let pathwayButton = document.querySelector('.pathway-button'); 
    const classnameToAdd = "findpathway";
  
    if (pathwayButton) {
      pathwayButton.children[0].classList.add(classnameToAdd); 
    }
  },[]);



const GetIndustryListFilteredURL = GetIndustryListFilteredAPI;
  const fetchQualificationAspirationData = async () => {
    if (selectedQualification && selectedRole) {
      const queryParams = {
        _qualification: selectedQualification.qualification_id,
        _aspiration: selectedRole.id,
      };

      try {
        const response = await callAPI(GetIndustryListFilteredURL, "POST", queryParams, {
          Authorization: "Bearer YourAccessToken",
        });
        // console.log(response.data); // Log the response data
        setIndustryData(response.data);
      } catch (error) {
        console.error("Error fetching qualification and aspiration data:", error);
      }
    }
  };

  useEffect(() => {
    fetchQualificationAspirationData();
  }, [selectedQualification, selectedRole]); 

  useEffect(() => {
  const savedQualification = JSON.parse(sessionStorage.getItem("selectedQualification"));
  const savedRole = JSON.parse(sessionStorage.getItem("selectedRole"));
  const savedIndustry = JSON.parse(sessionStorage.getItem("selectedIndustry"));

  // Check if there is a saved qualification, role, or industry
  if (savedQualification) {
    setSelectedQualification(savedQualification);
    setIsRoleDisabled(false);  // Enable the role dropdown if qualification is available
  }
  
  if (savedRole) {
    setSelectedRole(savedRole);
    setIsIndustryDisabled(false);  // Enable the industry dropdown if role is available
  }

  if (savedIndustry) {
    setSelectedIndustry(savedIndustry);
  }
}, []);
  return (
    <div className="pathway" ref={pathwayRef} id="personalise-home-div">
      <div className="main-pathway">
        <h2>
          Corporate Career, Government Job, Entrepreneurship! <br></br>{" "}
          <span>What's On Your Mind?</span>
        </h2>
        <div className="pathway-select">
          <div className="pathway-select-title">
            <p>I am</p>
            <AutocompleteTextField
              options={qualificationData ? qualificationData : noOptions}
              id="home-I am"
              placeholder="Select Qualifications"
              className={"combobox"}
              TextFieldStyle={"textfield-box"}
              value={selectedQualification}
              onChange={handleQualificationChange}
              getOptionLabel={(option) => option?.qualification}
            />
            {qualificationError && <p className="error-text">{qualificationError}</p>}
          </div>
          <div className="pathway-select-title">
            <p>I want to be</p>
            <AutocompleteTextField
              options={aspirationData ? aspirationData : noOptions}
              id="home-I want to be"
              placeholder="Select Role"
              className={"combobox"}
              TextFieldStyle={"textfield-box"}
              value={selectedRole}
              onChange={handleRoleChange}
              getOptionLabel={(option) => option?.aspirationDesc}
              disabled={isRoleDisabled}
            />
            {roleError && !isRoleDisabled && <p className="error-text">{roleError}</p>}
          </div>
          <div className="pathway-select-title">
            <p>In </p>
            <AutocompleteTextField
              options={industryData ? industryData : noOptions}
              id="home-In"
              placeholder="Select Industry"
              className={"combobox"}
              TextFieldStyle={"textfield-box"}
              value={selectedIndustry}
              onChange={handleIndustryChange}
              getOptionLabel={(option) => option?.industryName}
              disabled={isIndustryDisabled}

            />
            {industryError && !isIndustryDisabled && <p className="error-text">{industryError}</p>}
          </div>
          <div className="pathway-button" id="home-pathway-button">
          {displayFindButton && (
            <>
              <Button className={"gradient-button"} onClick={handleFindPathway}>
                Find Pathway
              </Button>
            </>
          )}
        </div>
        </div>
        
      </div>

      {displayBundle && (
        <>
          <PathwayPersonalize />
        </>
      )}

      {displayBundle && jobData !== null && jobData.length !== 0 && (
        <>
          <div className="or-section">
            <div>
              {" "}
              <hr></hr>{" "}
            </div>
            <h2>OR</h2>
            <div>
              {" "}
              <hr></hr>{" "}
            </div>
          </div>
          <PathwayCombo jobData={jobData} />
        </>
      )}

      <AlertComponent
        open={showAlert}
        onClose={() => setShowAlert(false)}
        message="Please select all fields"
        severity="error"
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      />
    </div>
  );
};

export default Pathway;
