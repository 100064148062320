import React , {useState, useEffect}from 'react'
import HorizontalStepper from '../../commonComponents/HorizontalStepper'
import Signin from './Signin'
import Degree from './Degree'
import University from './University'
import Location from './Location'
import Opportunity from './Opportunity'
import FindPathway from './FindPathway'
import { useActiveStep } from '../../utils/ActiveStepContext'
import { useNavigate } from 'react-router-dom'
const PathwayStepper = () => {
  const navigate = useNavigate();
  const isLoggedIn = sessionStorage.getItem('signInCompleted'); 

  const {activeStep, setActiveStep, setSelectedDegree, sSource, setSource, setSelectedUniversity, setSelectedLocation, setSelectedJob, dataAvailable} = useActiveStep(); 
  const handleNavigate = () =>{
    navigate("/pathway-comparison/");
  }
  useEffect(() => {
    if (activeStep === 5) { 
      setActiveStep(isLoggedIn===true? 1 : 0); 
      setSelectedDegree(null);
      setSelectedUniversity(null);
      setSelectedLocation(null);
      setSelectedJob([]);
    }
  }, [activeStep, isLoggedIn, setActiveStep, setSelectedDegree, setSelectedUniversity, setSelectedLocation, setSelectedJob]);
  

  const [nextButton, setNextButton]= useState(null);

  
  // useEffect(() => {
  //   console.log(activeStep);
  //   const handleNextAction = () => {
  //     setTimeout(() => {
  //       let nextButtonElement;
  //       let classnameToAdd;
  //       const classesToRemove = ["degree_next", "university_next", "location_next", "opportunity_next"]; // Array of classes to remove
        
  //       switch (activeStep) {
  //         case 1:
  //           nextButtonElement = document.querySelector('.degree-page');
  //           classnameToAdd = "degree_next";
  //           break;
  //         case 2:
  //           nextButtonElement = document.querySelector('.university-page');
  //           nextButtonElement.nextElementSibling.style.display =" flex";
  //           classnameToAdd = "university_next";
  //           break;
  //         case 3:
  //           console.log("Hi, I m in switch case - location")
  //           nextButtonElement = document.querySelector('.location-page');
  //           classnameToAdd = "location_next";
  //           break;
  //         case 4:
  //           nextButtonElement = document.querySelector('.opportunity-page');
  //           classnameToAdd = "opportunity_next";
  //           break;
  //         default:
  //           break;
  //       }
  
  //       if (nextButtonElement) {
  //         // console.log("Setting nextButton:", nextButtonElement);
  //         setNextButton(() => {
  //           // console.log("Next Button Updated in callback:", nextButtonElement);
  //           return nextButtonElement;
  //         });
  
  //         const siblingElement = nextButtonElement.nextElementSibling;
  //         siblingElement.className = ""; // Clear sibling's className
  
  //         if(siblingElement.childElementCount>1){// Remove all classes from both children[2] and children[1]
  //         classesToRemove.forEach((cls) => {
  //           if (siblingElement.children[2]) {
  //             console.log("Removing", cls);
  //             siblingElement.children[2].classList.remove(cls);
  //           }
  //           if (siblingElement.children[1]) {
  //             siblingElement.children[1].classList.remove(cls);
  //           }
  //         });
  
  //         // Add the correct class based on the active step
  //         if (siblingElement.children[2] && activeStep !== 1) {
  //           console.log("Adding", classnameToAdd)
  //           siblingElement.children[2].classList.add(classnameToAdd);
  //           // console.log("Class added to sibling[2]:", siblingElement);
  //         } else {
  //           siblingElement.children[1].classList.add(classnameToAdd);
  //           // console.log("Class added to sibling[1]:", siblingElement);
  //         }}
  //       }
  //     }, 1000); // Adjust the delay if needed
  //   };
  
  //   handleNextAction();
  // }, [activeStep]);
  
  useEffect(() => {
    const classesToRemove = ["degree_next", "university_next", "location_next", "opportunity_next"]; // Array of classes to remove
  
    const handleNextAction = (retryCount = 0) => {
      if (retryCount > 5) {
        // Stop retrying after 5 attempts (adjust as needed)
        console.warn('Element not found after several retries.');
        return;
      }
  
      setTimeout(() => {
        let nextButtonElement;
        let classnameToAdd;
        let idToAdd;
  
        // Switch case based on activeStep
        switch (activeStep) {
          case 1:
            nextButtonElement = document.querySelector('.degree-page');
            classnameToAdd = "degree_next";
            idToAdd = "personalizepathway-degree-next";
            break;
          case 2:
            nextButtonElement = document.querySelector('.university-page');
            if (nextButtonElement?.nextElementSibling) {
              nextButtonElement.nextElementSibling.style.display = "flex";
            }
            classnameToAdd = "university_next";
            idToAdd = "personalizepathway-university-next";
            break;
          case 3:
            console.log("Hi, I am in switch case - location");
            nextButtonElement = document.querySelector('.location-page');
            classnameToAdd = "location_next";
            idToAdd = "personalizepathway-location-next";
            break;
          case 4:
            nextButtonElement = document.querySelector('.opportunity-page');
            classnameToAdd = "opportunity_next";
            idToAdd = "personalizepathway-opportunity-next";
            break;
          default:
            break;
        }
  
        // Retry mechanism if the element doesn't exist
        if (!nextButtonElement) {
          console.log('Next button element not found, retrying...');
          handleNextAction(retryCount + 1); // Retry after 5 seconds
          return;
        }
  
        // If the element is found, proceed with the rest of the logic
        setNextButton(() => nextButtonElement);
  
        const siblingElement = nextButtonElement.nextElementSibling;
        siblingElement.className = ""; // Clear sibling's className
  
        if (siblingElement.childElementCount > 1) {
          // Remove all classes from both children[2] and children[1]
          classesToRemove.forEach((cls) => {
            if (siblingElement.children[2]) {
              siblingElement.children[2].classList.remove(cls);
            }
            if (siblingElement.children[1]) {
              siblingElement.children[1].classList.remove(cls);
            }
          });
  
          // Add the correct class based on the active step
          if (siblingElement.children[2] && activeStep !== 1) {
            siblingElement.children[2].classList.add(classnameToAdd);
            siblingElement.children[2].id = idToAdd;
          } else {
            siblingElement.children[1].classList.add(classnameToAdd);
            siblingElement.children[1].id = idToAdd;
          }
        }
      }, 1000); // Delay between retries (adjust if necessary)
    };
  
    handleNextAction(); // Initial call
  }, [activeStep]);
  
  return (
    <>
        <HorizontalStepper 
            stepLabels={['Signin', 'Degree', 'University', 'Location', 'Opportunity']}
            stepComponents={[
                <Signin/>,
                <Degree />,
                <University />,
                <Location />,
                <Opportunity />
            ]}
            FinishComponent={() => <FindPathway />}
            showNextButton={activeStep >0 && dataAvailable}
            showBackButton={activeStep>1 && dataAvailable}
            stepperClassName={'stepper-connector'}
            finalButtonContent="Next"
            buttonNextClassName={'next-button'}
            buttonBackClassName={'back-button-stepper'}
            finalButtonAction={handleNavigate}
        />

        {activeStep ===2 && (
          <div className='university-disclaimer'>
            <p><span>*Please note:</span> Enrollment is subject to meeting the eligibility criteria of the university.</p>
          </div>
        )}
    </>
  )
}
export default PathwayStepper
