import React, {useState, useEffect} from 'react'
import { DegreeCard } from './Degree';
import DummyImage from "../../assets/DummyUniversity.png";
import { useActiveStep } from '../../utils/ActiveStepContext';
import { UniversityListAPI } from '../../utils/apiUrl';
import callAPI from '../../utils/apiAction';
import OhNoComponent from './OhNoComponent';
import Lottie from "react-lottie"
import animationData from "../../assets/spinner_loader.json"
import { Helmet } from 'react-helmet-async';
const University = () => {
  const {selectUniversity, selectedUniversity} = useActiveStep();
  const universityURL = UniversityListAPI;
  const [universityData, setUniversityData] = useState(null);
  const [universityDataAvailable, setUniversityDataAvailable] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { updateDataAvailability } = useActiveStep();
  sessionStorage.setItem("UniversityID", selectedUniversity);
  useEffect(() => {
    const queryParams = {
      _courseId: sessionStorage.getItem("DegreeID"),
    };
    const fetchComboData = async () => {
      setIsLoading(true);
      try{
        const { data } = await callAPI(universityURL, "POST", queryParams, {
          Authorization: "Bearer YourAccessToken",
        });
        setUniversityData(data);
        if (data.length !== 0 && data !== null){
          setUniversityDataAvailable(true);
          updateDataAvailability(true);
        }else{
          setUniversityDataAvailable(false);
          updateDataAvailability(false);
        }
      }
      catch(error){
        console.log(error);
      }
      finally{
        setIsLoading(false);
    }

    };
    fetchComboData();
  }, []);
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
}

if (isLoading){
  return(
    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
    <Lottie options={defaultOptions} height={300} width={300}/>
    </div>
  )
}
  return (
    <>
    <Helmet>
    <script>
        {`!function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '517004411113375');
        fbq('track', 'PageView');
        fbq('track', 'Add to cart');`}
      </script>
      <noscript>{`
        <img
          height="1"
          width="1"
          style="display:none"
          src="https://www.facebook.com/tr?id=517004411113375&ev=PageView&noscript=1"
          alt="Facebook Pixel"
        />
      `}</noscript>
    </Helmet>
    {universityDataAvailable!==null && universityDataAvailable?(
          <div className='university-page'>
          <h1>Your Future Starts with the Right University!</h1>
          <h4>Choose your University</h4>
          <div className="degree-card">
            {universityData!==null &&  universityData.slice(0, 3).map((item, index) => {
              return (
                <>
                  <DegreeCard
                    title={item?.universityName}
                    img_src={item?.logo ? item?.logo : (DummyImage) }
                    selected={selectedUniversity === item?.id}
                    onSelect={()=>selectUniversity(item?.id)}
                    id={`university-card-${index}`}
                  />
                </>
              )
            })}
          </div>
    
        </div>
    ):(<OhNoComponent />)}
    </>
  )
}
export default University