import React, {useState} from "react";
import Events1 from "../../assets/events1.png";
import Events2 from "../../assets/events2.png";
import Events3 from "../../assets/events3.png";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ButtonComponent from "../../commonComponents/Button";
import { Link } from "react-router-dom";
export const events = [
  {
    id: "001",
    eventType: "upcoming event",
    date: "August 15, 2024",
    title: "Industry Academia Symposium",
    content: "Join us for an interactive symposium where academia meets industry. Keynote speakers from leading corporates and top universities will discuss the future of work-integrated learning and the evolving job market.",
    image_src: Events1,
    location: "Mumbai, India",
    fee: "Free",
    goals: [
      {goalId: "000001", goal_desc: "Gain insights into emerging trends"},
      {goalId: "000002", goal_desc: "Network with industry leaders"},
      {goalId: "000003", goal_desc: "Discover new opportunities for collaboration."},
    ]
  },
  {
    id: "002",
    eventType: "upcoming event",
    date: "October 5-7, 2024",
    title: "Digital Skills Bootcamp",
    content: "A three-day intensive bootcamp focusing on the latest digital skills, including data analytics, digital marketing, and coding. Led by industry experts, this hands-on training will equip you with the skills needed to excel in the digital economy.",
    image_src: Events2,
    location: "Delhi, India",
    fee: "Free",
    goals: [
      {goalId: "000001", goal_desc: "Acquire in-demand digital skills"},
      {goalId: "000002", goal_desc: "Engage in practical learning experiences"},
      {goalId: "000003", goal_desc: "Boost your employability in the tech-driven job market."},
    ]
  },
  {
    id: "003",
    eventType: "upcoming event",
    date: "August 20, 2024",
    title: "Webinar on Work Integrated Learning",
    content: "A webinar focusing on the benefits and implementation of work-integrated learning. Experts will share their insights on how this approach bridges the gap between education and employment.",
    image_src: Events3,
    location: "Online (via Google Meet)",
    fee: "Free",
    goals: [
      {goalId: "000001", goal_desc: "Understand the value of work-integrated learning"},
      {goalId: "000002", goal_desc: "Learn best practices"},
      {goalId: "000003", goal_desc: "Discover how this model can enhance your career prospects."},
    ]
  },
];

const EventsCard = ({ id, image_src, eventType, date, title, className }) => {
  return (
    <>
      <div className={className} id={id}>
        <img src={image_src} alt="news" className="new-image-size" />
        <div className="events-content">
          <h4>{eventType}</h4>
          <h3>{title}</h3>
          <h6>{date}</h6>
        </div>
      </div>
    </>
  );
};
const LatestEvents = () => {
  const displayedEvents = events.slice(0,3);
  const formatTitleForURL = (title) => {
    return title.toLowerCase().replace(/ /g, '-');
  };
  const [eventsExist, setEventExist] = useState(false);
  return (
    <>
    {eventsExist && (
      <div className="latest-events-section">
      <div className="heading">
        <h1>Latest Events</h1>
        <Link to="/events" style={{color: "black"}}>
        <div className='icon-text-inline'>
          <h5>View More </h5>
          <ArrowForwardIcon />
        </div></Link>
      </div>
      <div className="content">
      {displayedEvents.map((item, index)=>{
        return (
          <div>
          <Link to={`/events/${formatTitleForURL(item.title)}`}>
            <EventsCard
              image_src={item.image_src}
              eventType={item.eventType}
              date={item.date}
              title={item.title}
              content={item.content}
              className={'eventscard'}
            />
            </Link>
          </div>
        );
      })}
      </div>
      <div className='mobile-view-more-section'>
        <Link to="/events" style={{ color: "white" }}>
          <ButtonComponent className='mobile-view-more-button' children="View More Events" />
        </Link>
      </div>
    </div>
    ) }
    </>
  );
};
export default LatestEvents;
