import React from 'react';
import EcoSystemBanner from "./EcoSystemBanner";
import Partners from './Partners';
import './style.scss';
function index() {
  return (
    <>
    <EcoSystemBanner/>
    <Partners/>
    </>
  )
}
export default index