import React from 'react';

const ZoomVerify = () => {
  return (
    <iframe
      src={`${process.env.PUBLIC_URL}${process.env.REACT_APP_ZOOM_ROUTE}`}
      style={{ width: '100%', height: '100vh', border: 'none' }}
      title="Zoom Verification"
    />
  );
};

export default ZoomVerify;