import React from "react";
import AboutImage from "../../assets/About-Image.png";
import AboutBridgeSVG from "../../assets/AboutBridgeSVG.svg";
const AboutBridge = () => {
  return (
    <div className="bridge-section">
      <img className="imageSVG" src={AboutBridgeSVG} alt="" />
      <div className="bridge-left">
        <h2>Building the Bridge:</h2>
        <h3>Where Education Takes You To The Work Opportunities</h3>
        <p>
          At Digivarsity, we envision a nation where every individual,
          regardless of their background, possesses the skills and opportunities
          to secure fulfilling careers. Our journey is fueled by a dream to
          bridge the gap between education and employment, ensuring that every
          student not only graduates but steps confidently into their first job.
          <br></br>
          <br />
          We are a movement towards creating an enabling ecosystem that
          empowers, sustains, and encourages learning, relearning, skilling, and
          entrepreneurship.
        </p>
      </div>
      <div className="bridge-right">
        <img src={AboutImage} alt="bridge-image" />
      </div>
    </div>
  );
};
export default AboutBridge;
