// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.discparentdiv {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #D7DBF3;
}

.disclaimer-div {
  background-color: #fff;
  border-radius: 10px;
  margin-top: 25vh;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-self: center;
  align-items: center;
  justify-content: center;
  row-gap: 25px;
  max-width: 65vw;
  padding: 24px 48px 10vh 48px;
  margin-bottom: 20vh;
}

.headdiv h2 {
  font-weight: 700;
  font-size: 24px;
}

.ponediv {
  max-width: 70%;
}

.continuediv {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.primary-btn {
  height: 40px;
  flex-shrink: 0;
  color: #fff;
  border-color: none;
  background: linear-gradient(90deg, #B03A6E 0%, #773BC5 51.5%, #2B4DC0 100%);
  border-radius: 6px;
  padding: 10px 10vw;
  border: 0;
}

.primary-btn:hover {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/pages/Summary/disclaimer.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,mBAAA;AACJ;;AACA;EACI,sBAAA;EACA,mBAAA;EACA,gBAAA;EACA,aAAA;EACA,sBAAA;EACA,kBAAA;EACA,kBAAA;EACA,mBAAA;EACA,uBAAA;EACA,aAAA;EACA,eAAA;EACA,4BAAA;EACA,mBAAA;AAEJ;;AACI;EACI,gBAAA;EACA,eAAA;AAER;;AACA;EACI,cAAA;AAEJ;;AAAA;EACI,aAAA;EACA,WAAA;EACA,mBAAA;EACA,uBAAA;AAGJ;;AADA;EAEI,YAAA;EACA,cAAA;EACA,WAAA;EACA,kBAAA;EACA,2EAAA;EACA,kBAAA;EACA,kBAAA;EACA,SAAA;AAGJ;;AADA;EACI,eAAA;AAIJ","sourcesContent":[".discparentdiv{\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    background: #D7DBF3;\n}\n.disclaimer-div{\n    background-color: #fff;\n    border-radius: 10px;\n    margin-top: 25vh;\n    display: flex;\n    flex-direction: column;\n    text-align: center;\n    align-self: center;\n    align-items: center;\n    justify-content: center;\n    row-gap: 25px;\n    max-width:65vw ;\n    padding: 24px 48px 10vh 48px;\n    margin-bottom: 20vh;\n}\n.headdiv{\n    h2{\n        font-weight: 700;\n        font-size: 24px;\n    }\n}\n.ponediv{\n    max-width: 70%;\n}\n.continuediv{\n    display: flex;\n    width: 100%;\n    align-items: center;\n    justify-content: center;\n}\n.primary-btn{\n    // width: 80%;\n    height: 40px;\n    flex-shrink: 0;\n    color: #fff;\n    border-color: none;\n    background: linear-gradient(90deg, #B03A6E 0%, #773BC5 51.5%, #2B4DC0 100%);\n    border-radius: 6px;\n    padding: 10px 10vw;\n    border: 0;\n}\n.primary-btn:hover{\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
