import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

const UsePageTracking = () => {
  const location = useLocation();
  useEffect(() => {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: "react_pageview",
        page: location.pathname + location.search,
      });
    }
  }, [location]);
  return <div></div>;
};

export default UsePageTracking;
