import React, { useState } from "react";
import BreadCrumbs from "../../commonComponents/BreadCrumbs";
import ButtonComponent from "../../commonComponents/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const ContactThank = () => {
  const [partnerMode, setPartnerMode] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const location = useLocation();
  const isPartnerMode = location.state?.mode === "partner";
  const paths = [
    { label: "Home", url: "/" },
    {
      label: "Contact us",
      url: "/contact-us/",
      customOnClick: () => {
        setPartnerMode(false);
        setIsVisible(true);
      },
    },
  ];
  if (partnerMode) {
    paths.splice(2, 0, { label: "Partner with us", url: "" });
  }
  const navigate = useNavigate();
  return (
    <div>
    <Helmet>
      {/* Meta Pixel Code */}
      <script>
          {`
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '517004411113375');
            fbq('track', 'PageView');
          `}
        </script>
        <noscript>
          {`<img height="1" width="1" style="display:none"
          src="https://www.facebook.com/tr?id=517004411113375&ev=PageView&noscript=1" />`}
        </noscript>
        {/* Meta Pixel Code */}
    </Helmet>
      <div className="contact-container make-me-white">
        {/* <AlertComponent
          open={showAlert}
          onClose={() => setShowAlert(false)}
          message="Invalid phone number! Please enter phone number having 10 digits."
          severity="error"
          autoHideDuration={6000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        /> */}
        <div className="breadcrumbs-div">
          <BreadCrumbs paths={paths} color="black" />
        </div>
        <div className="thank-container">
          <div className="thank-box">
            <div className="thank-inner-box">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="117"
                height="119"
                viewBox="0 0 117 119"
                fill="none"
              >
                <g filter="url(#filter0_d_11093_24068)">
                  <rect
                    x="58.5703"
                    width="80"
                    height="80"
                    rx="12"
                    transform="rotate(45 58.5703 0)"
                    fill="#E2E9FF"
                  />
                  <rect
                    x="58.5703"
                    y="0.707107"
                    width="79"
                    height="79"
                    rx="11.5"
                    transform="rotate(45 58.5703 0.707107)"
                    stroke="#D8E0F6"
                  />
                </g>
                <path
                  d="M60.9431 78.7939C60.6163 77.7714 59.5282 77.2059 58.5126 77.5305C57.497 77.8551 56.9386 78.9469 57.2655 79.9694C57.5923 80.9919 58.6805 81.5577 59.6961 81.2331C60.7116 80.9085 61.27 79.8164 60.9431 78.7939ZM64.2068 51.2774L72.7548 78.0208C73.0779 79.0315 72.5259 80.1107 71.5223 80.4315L57.2365 84.9977C56.2329 85.3185 55.1572 84.7595 54.8342 83.7488L46.2862 57.0054C45.9631 55.9947 46.515 54.9153 47.5186 54.5945L61.8044 50.0283C62.808 49.7075 63.8837 50.2668 64.2068 51.2774ZM103.685 53.1324L102.906 53.3812C105.047 52.7611 107.308 53.9629 107.996 56.1133C108.695 58.3006 107.5 60.6365 105.328 61.3309L104.333 61.6553C106.568 60.9408 108.968 62.2428 109.588 64.5511C110.17 66.7132 108.871 68.9344 106.753 69.6114L102.781 70.8809C102.781 70.8809 101.436 71.311 98.9974 72.0904C96.326 72.9442 92.3425 74.2175 87.3649 75.8085C81.5471 77.668 77.7747 77.5215 75.7198 77.1261C74.5838 76.9072 73.6584 76.0788 73.3042 74.9709L65.9537 51.974C65.593 50.8456 65.8964 49.6227 66.7149 48.775C69.1693 46.2337 71.0643 39.7781 70.104 34.9387C68.741 28.0699 66.37 25.3219 68.6766 24.5665C72.8906 23.1863 76.1183 25.1624 77.9763 28.1741C80.6595 32.5236 80.7525 36.3 80.8113 38.4444C80.9242 42.5624 85.2184 41.575 85.2184 41.575L91.8627 39.4513L92.6546 39.1982L99.6184 36.9723C101.736 36.2953 104.083 37.3516 104.863 39.4498C105.696 41.69 104.496 44.1429 102.261 44.8574L101.264 45.1761C103.499 44.4617 105.899 45.7642 106.52 48.0722C107.101 50.234 105.803 52.4554 103.685 53.1324Z"
                  fill="#2C5FF8"
                />
                <defs>
                  <filter
                    id="filter0_d_11093_24068"
                    x="0.972656"
                    y="2.9707"
                    width="115.195"
                    height="115.196"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                  >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="3" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0.46375 0 0 0 0 0.505909 0 0 0 0 0.618333 0 0 0 0.3 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="BackgroundImageFix"
                      result="effect1_dropShadow_11093_24068"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect1_dropShadow_11093_24068"
                      result="shape"
                    />
                  </filter>
                </defs>
              </svg>
              <h2 className="gradient-text">Thank You!</h2>
              {isPartnerMode ? (
                <p>
                  Thanks for reaching out! <br />
                  <span>
                    We'll connect with you to discuss partnership opportunities.
                  </span>
                </p>
              ) : (
                <p>
                  We've received your details. <br />
                  <span>
                    Our advisor will call you to guide you on your career
                    pathway.
                  </span>
                </p>
              )}

              <div className="thank-buttons">
                <ButtonComponent
                  id={"contactus-back"}
                  className={"back-button"}
                  onClick={() => navigate('/contact-us/')}
                  // disabled={!isSlotAvailable}
                >
                  <h2>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="19"
                      height="18"
                      viewBox="0 0 19 18"
                      fill="none"
                    >
                      <mask
                        id="mask0_11093_24288"
                        style={{ "mask-type": "alpha" }}
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="0"
                        width="19"
                        height="18"
                      >
                        <rect x="0.5" width="18" height="18" fill="#D9D9D9" />
                      </mask>
                      <g mask="url(#mask0_11093_24288)">
                        <path
                          d="M7.36537 13.2425L3.125 9.00209L7.36537 4.76172L8.15574 5.55209L5.26822 8.43961H15.875V9.56457H5.26822L8.15574 12.4521L7.36537 13.2425Z"
                          fill="#666666"
                        />
                      </g>
                    </svg>
                    Back
                  </h2>
                </ButtonComponent>
                <ButtonComponent
                  id={"contactus-gotohomepage"}
                  className={"gradient-button"}
                  onClick={() => navigate("/")}
                  // disabled={!isSlotAvailable}
                >
                  <h2>Go to Homepage</h2>
                </ButtonComponent>
              </div>
            </div>
          </div>
          <div className="mobile-thank-buttons">
                <ButtonComponent
                  className={"back-button"}
                  onClick={() => navigate('/contact-us/')}
                  // disabled={!isSlotAvailable}
                >
                  <h2>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="19"
                      height="18"
                      viewBox="0 0 19 18"
                      fill="none"
                    >
                      <mask
                        id="mask0_11093_24288"
                        style={{ "mask-type": "alpha" }}
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="0"
                        width="19"
                        height="18"
                      >
                        <rect x="0.5" width="18" height="18" fill="#D9D9D9" />
                      </mask>
                      <g mask="url(#mask0_11093_24288)">
                        <path
                          d="M7.36537 13.2425L3.125 9.00209L7.36537 4.76172L8.15574 5.55209L5.26822 8.43961H15.875V9.56457H5.26822L8.15574 12.4521L7.36537 13.2425Z"
                          fill="#666666"
                        />
                      </g>
                    </svg>
                    Back
                  </h2>
                </ButtonComponent>
                <ButtonComponent
                  className={"gradient-button"}
                  onClick={() => navigate("/")}
                  // disabled={!isSlotAvailable}
                >
                  <h2>Go to Homepage</h2>
                </ButtonComponent>
              </div>
        </div>
        {/* <div className="contact-info">
          {partnerMode ? (
            <h1 className="contact-title" id="partner-h1">
              Partner with Digivarsity
            </h1>
          ) : (
            <h1 className="contact-title">Contact Us</h1>
          )}
          <p className="contact-description">
            You may reach us via phone, email or choose to fill up the form{" "}
            {partnerMode ? (
              <span> to partner with us.</span>
            ) : (
              <span> for any queries.</span>
            )}
          </p>
          <div className="contact-details">
            <p>
              <img src={CallIcon} />
              <span>(+91) 720 467 7888</span>
            </p>
            <p>
              <img src={ChatIcon} />
              <span>(+91) 720 467 7888</span>
            </p>
            <p>
              <img src={MailIcon} />{" "}
              {partnerMode ? (
                <span>Info@digivarsity.com</span>
              ) : (
                <span>partner@digivarsity.com</span>
              )}
            </p>
          </div>
          {!partnerMode && (
            <div className="contact-support">
              <div className="support-box">
                <h3>Customer Support</h3>
                <p>
                  Our support team is available from Monday to Saturday between
                  9.00 am - 7.00 pm to address any concerns or queries you have.
                </p>
              </div>
              <div className="support-box">
                <h3>Career Related Inquiries</h3>
                <p>
                  For career related questions or inquiries please contact us at{" "}
                  <br />
                  (+91) 867 776 8899.
                </p>
              </div>
            </div>
          )}
        </div>
        <div className="contact-form">
          <div className="contact-headers">
            {partnerMode ? <h2>Get in Touch</h2> : <h2>Request a call back</h2>}
            {partnerMode && <p>You can reach us anytime</p>}
          </div>
          <form onSubmit={handleSubmit}>
            <div className="flex-form">
              <input
                type="text"
                placeholder="First name"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                required
              />
              <input
                type="text"
                placeholder="Last name"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                required
              />
            </div>
            {partnerMode && (
              <div className="form-field">
                <input
                  id="companyName"
                  type="text"
                  placeholder="Your company name"
                  name="companyName"
                  value={formData.companyName}
                  onChange={handleChange}
                  required
                />
              </div>
            )}
            <div className="form-field">
              <img src={messageIcon} alt="" />
              <input
                type="email"
                placeholder="Your email"
                name="emailId"
                value={formData.emailId}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-field">
              <span>+91</span>
              <input
                type="text"
                placeholder="Phone number"
                name="phoneNo"
                value={formData.phoneNo}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-field">
              <textarea
                name="message"
                placeholder="Your message"
                rows={5}
                value={formData.message}
                onChange={handleChange}
                required
              ></textarea>
            </div>
            {success ? (
              <button className="successBtn">Sent Successfully</button>
            ) : (
              <button type="submit">Send</button>
            )}
          </form>
        </div> */}
      </div>
    </div>
  );
};

export default ContactThank;
