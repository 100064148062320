import CryptoJS from 'crypto-js';

// Encryption function

export  default function encrypt(text) {
    const key = 'D!d!V@rs!tY@2023M0bile#TE@mLe@se'; // Must be 32 characters (256 bits)

    const keyBytes = CryptoJS.enc.Utf8.parse(key);

    // Encrypt using AES-256 in ECB mode
    const encrypted = CryptoJS.AES.encrypt(text, keyBytes, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
    });
    const encryptedData = encrypted.toString().replace(/\+/g, '-');
    // Convert ciphertext to base64-encoded string
    return encryptedData;
}
