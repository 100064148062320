//UniTree.jsx
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import callAPI from '../../utils/apiAction'
import { OurEcoSystemAPI } from '../../utils/apiUrl'
// import Dummy1 from "../../assets/company_logo_1.png"
// import Dummy2 from "../../assets/company_logo_2.png"
// import Dummy3 from "../../assets/company_logo_3.png"
// import Dummy4 from "../../assets/company_logo_4.png"
// import Dummy5 from "../../assets/company_logo_5.png"
import Button from '../../commonComponents/Button'
import { useIds } from '../../utils/IdsContext'
import DummyJob from "../../assets/DummyJob.png"
import { public_private_partnership } from '../EcoSystem/Partners'
const UniTree = () => {
    // const specialIndex = [11, 14, 16, 17, 18, 20];
    const [ecoData, setEcoData] = useState(null);
    const OurEcoSystemURL = OurEcoSystemAPI;
    const {setUniversityId, setCorporateId} = useIds();

    const isMobileView = () => {
        return window.innerWidth <= 640 && window.innerHeight <= 800;
    };
    const isCurrentViewMobile = isMobileView();
    const itemsToShow = isCurrentViewMobile ? 4 : 5;

    useEffect(() => {
        const queryParams = {
          _courseId: 0
        };
        const fetchEcoData = async () => {
          
          try {
            const { data } = await callAPI(OurEcoSystemURL, "POST", queryParams, {
              Authorization: "Bearer YourAccessToken",
            });
            setEcoData(data);
          } catch (error) {
            console.log(error);
          }
        };
        fetchEcoData();
      }, []);

    //   const defaultLogos = [
    //     { id: "1", imageUrl:(DummyJob), name:"Abc"},
    //     { id: "2", imageUrl:(DummyJob), name:"Abc"},
    //     { id: "3", imageUrl:(DummyJob), name:"Abc"},
    //     { id: "4", imageUrl:(DummyJob), name:"Abc"},
    //     { id: "5", imageUrl:(DummyJob), name:"Abc"},
    //     { id: "6", imageUrl:(DummyJob), name:"Abc"},
    //     { id: "7", imageUrl:(DummyJob), name:"Abc"},
    //     { id: "8", imageUrl:(DummyJob), name:"Abc"},
    //     { id: "9", imageUrl:(DummyJob), name:"Abc"},
    //     { id: "10", imageUrl:(DummyJob), name:"Abc"},
    //     { id: "11", imageUrl:(DummyJob), name:"Abc"},
    //     { id: "12", imageUrl:(DummyJob), name:"Abc"},
    //     { id: "13", imageUrl:(DummyJob), name:"Abc"},
    //     { id: "14", imageUrl:(DummyJob), name:"Abc"},
    //     { id: "15", imageUrl:(DummyJob), name:"Abc"},
    //     { id: "16", imageUrl:(DummyJob), name:"Abc"},
    //     { id: "17", imageUrl:(DummyJob), name:"Abc"},
    //     { id: "18", imageUrl:(DummyJob), name:"Abc"},
    //     { id: "19", imageUrl:(DummyJob), name:"Abc"},
    //     { id: "20", imageUrl:(DummyJob), name:"Abc"},
    //     { id: "21", imageUrl:(DummyJob), name:"Abc"},
    //   ];
    //   const companyLogos = [
    //     { id: "1", imageUrl:(Dummy1), name:"Abc"},
    //     { id: "2", imageUrl:(Dummy5), name:"Abc"},
    //     { id: (ecoData!==null && ecoData[0]?.universities[0]?.id), imageUrl:(ecoData!==null && ecoData[0]?.universities[0]?.logo || DummyJob) , name:"Abc", link_url: (ecoData!==null && ecoData[0]?.universities[0]?.id)?`/eco-system/university/university-details`:"/", onClick: () => setUniversityId(ecoData!==null && ecoData[0]?.universities[0]?.id)},
    //     { id: "4", imageUrl:(Dummy2), name:"Abc"},
    //     { id: "5", imageUrl:(Dummy4), name:"Abc"},
    //     { id: (ecoData!==null && ecoData[0]?.corporatePartner[0]?.corporateId), imageUrl:(ecoData!==null && ecoData[0]?.corporatePartner[0]?.logo || DummyJob), name:"Abc", link_url: (ecoData!==null && ecoData[0]?.corporatePartner[0]?.corporateId)?`/eco-system/corporate-partnership/corporate-details`: "/", onClick: () => setCorporateId(ecoData!==null && ecoData[0]?.corporatePartner[0]?.corporateId)},
    //     { id: (ecoData!==null && ecoData[0]?.universities[1]?.id), imageUrl:(ecoData!==null && ecoData[0]?.universities[1]?.logo || DummyJob) , name:"Abc", link_url: (ecoData!==null && ecoData[0]?.universities[1]?.id)? `/eco-system/university/university-details`:"/", onClick: () => setUniversityId(ecoData!==null && ecoData[0]?.universities[1]?.id)},
    //     { id: "8", imageUrl:(Dummy3), name:"Abc"},
    //     { id: (ecoData!==null && ecoData[0]?.corporatePartner[1]?.corporateId), imageUrl:(ecoData!==null && ecoData[0]?.corporatePartner[1]?.logo) || (DummyJob), name:"Abc",  link_url:(ecoData!==null && ecoData[0]?.corporatePartner[1]?.corporateId)?`/eco-system/corporate-partnership/corporate-details`: "/", onClick: () => setCorporateId(ecoData!==null && ecoData[0]?.corporatePartner[1]?.corporateId)},
    //     { id: (ecoData!==null && ecoData[0]?.universities[2]?.id), imageUrl:(ecoData!==null && ecoData[0]?.universities[2]?.logo) || (DummyJob), name:"Abc", link_url: (ecoData!==null && ecoData[0]?.universities[2]?.id)?`/eco-system/university/university-details`:"/", onClick: () => setUniversityId(ecoData!==null && ecoData[0]?.universities[2]?.id)},
    //     { id: (ecoData!==null && ecoData[0]?.universities[3]?.id), imageUrl:(ecoData!==null && ecoData[0]?.universities[3]?.logo) || (DummyJob), name:"Abc", link_url: (ecoData!==null && ecoData[0]?.universities[3]?.id)?`/eco-system/university/university-details`:"/", onClick: () => setUniversityId(ecoData!==null && ecoData[0]?.universities[3]?.id)},
    //     { id: "12", imageUrl:(Dummy2), name:"Abc"}, //Let this be Dummy, don't change
    //     { id: (ecoData!==null && ecoData[0]?.corporatePartner[2]?.corporateId), imageUrl:(ecoData!==null && ecoData[0]?.corporatePartner[2]?.logo) || (DummyJob), name:"Abc",  link_url:(ecoData!==null && ecoData[0]?.corporatePartner[2]?.corporateId)?`/eco-system/corporate-partnership/corporate-details`: "/", onClick: () => setCorporateId(ecoData!==null && ecoData[0]?.corporatePartner[2]?.corporateId)},     
    //     { id: (ecoData!==null && ecoData[0]?.corporatePartner[3]?.corporateId), imageUrl:(ecoData!==null && ecoData[0]?.corporatePartner[3]?.logo) || (DummyJob), name:"Abc",  link_url:(ecoData!==null && ecoData[0]?.corporatePartner[3]?.corporateId)?`/eco-system/corporate-partnership/corporate-details`: "/", onClick: () => setCorporateId(ecoData!==null && ecoData[0]?.corporatePartner[3]?.corporateId)},
    //     { id: "15", imageUrl:(Dummy1), name:"Abc"}, //Let this be Dummy, don't change
    //     { id: (ecoData!==null && ecoData[0]?.universities[4]?.id), imageUrl:(ecoData!==null && ecoData[0]?.universities[4]?.logo) || (DummyJob), name:"Abc", link_url: (ecoData!==null && ecoData[0]?.universities[4]?.id)?`/eco-system/university/university-details`:"/", onClick: () => setUniversityId(ecoData!==null && ecoData[0]?.universities[4]?.id)},  
    //     { id: (ecoData!==null && ecoData[0]?.universities[5]?.id), imageUrl:(ecoData!==null && ecoData[0]?.universities[5]?.logo) || (DummyJob), name:"Abc", link_url: (ecoData!==null && ecoData[0]?.universities[5]?.id)?`/eco-system/university/university-details`:"/", onClick: () => setUniversityId(ecoData!==null && ecoData[0]?.universities[5]?.id)},  

    //     { id: "18", imageUrl:(Dummy1), name:"Abc"}, //Let this be Dummy, don't change
    //     { id: "19", imageUrl:(Dummy1), name:"Abc"}, //Let this be Dummy, don't change
    //     { id: (ecoData!==null && ecoData[0]?.corporatePartner[4]?.corporateId), imageUrl:(ecoData!==null && ecoData[0]?.corporatePartner[4]?.logo) || (DummyJob), name:"Abc",  link_url:(ecoData!==null && ecoData[0].corporatePartner[4].corporateId)?`/eco-system/corporate-partnership/corporate-details`: "/", onClick: () => setCorporateId(ecoData!==null && ecoData[0]?.corporatePartner[4]?.corporateId)}, 
    //     { id: "21", imageUrl:(Dummy1), name:"Abc"}, //Let this be Dummy, don't change   
    // ];

    
  return (
    <div className="uni-tree-container">
        <h2>Our Ecosystem</h2>
        <div className='unitree-title-section'>
            <hr /><p className='unitree-title'>Public Private Partnerships</p><hr />
        </div>
        <div className="partners-section">
            {ecoData !== null && ecoData[0]?.pubPvtPartners
              ? ecoData[0]?.pubPvtPartners.map((item, index) => {
                  let marginTopValue = 0;
                  if (index % 4 === 1 || index % 4 === 3) {
                    marginTopValue = "30px";
                  } else if (index % 4 === 2) {
                    marginTopValue = "60px";
                  } else {
                    marginTopValue = 0;
                  }
                  return (
                    <>
                      <div
                        className="public-partners"
                        style={{ marginTop: marginTopValue }}
                        key={item.id}
                      >
                        <img src={item.img_src} width={80} height={75} />
                      </div>
                    </>
                    
                  );
                })
              : public_private_partnership.slice(0, itemsToShow).map((item, index) => {
                  let marginTopValue = 0;
                  if (!isMobileView()) {
                    if (index % 4 === 1 || index % 4 === 3) {
                      marginTopValue = "30px";
                    } else if (index % 4 === 2) {
                      marginTopValue = "60px";
                    } else {
                      marginTopValue = 0;
                    }
                  } else {
                    if (index % 4 === 1 || index % 4 === 2) {
                      marginTopValue = "30px";
                    } else {
                      marginTopValue = 0;
                    }
                  }
                  return (
                    <div className="public-partners-container-box">
                    <div
                      className="public-partners"
                      style={{ marginTop: marginTopValue }}
                      key={item.id}
                    >
                      <img src={item.img_src} width={80} height={75} />
                    </div>
                    <p>{item.name}</p>
                    
                    </div>

                  );
                })}
          </div>

        <div className='unitree-title-section'>
            <hr /><p className='unitree-title'>University Partnerships</p><hr />
        </div>
        <div className="partners-section">
          {ecoData !== null && ecoData[0]?.universities.slice(0,itemsToShow).map((item, index) => {
                let marginTopValue = 0;
                if (!isMobileView()) {
                  if (index % 5 === 1 || index % 5 === 3) {
                    marginTopValue = "30px";
                  } else if (index % 5 === 2) {
                    marginTopValue = "60px";
                  }
                } else {
                  if (index % 4 === 1 || index % 4 === 2) {
                    marginTopValue = "30px";
                  } else {
                    marginTopValue = 0;
                  }
                }
                const univId = item.id;
                return (
                  <Link
                    to={`eco-system/university-partnership/${item?.universityName.toLowerCase().replace(/[^\w\s-]/g, '')  // Remove all non-word characters except spaces and hyphens
    .replace(/[\s-]+/g, '-')
                    }/`}
                    onClick={() => setUniversityId(univId)}
                    className="public-partners-container-box"
                    id={`home-ecosystem-university-${item?.universityName.toLowerCase().replace(/[^\w\s-]/g, '')  // Remove all non-word characters except spaces and hyphens
    .replace(/[\s-]+/g, '-')}`}
                  >
                    <div
                      className="public-partners"
                      style={{ marginTop: marginTopValue }}
                      key={item?.id}
                    >
                      <img
                        src={item?.logo}
                        width={80}
                        height={75}
                        alt="partner logo"
                      />
                    </div>
                    <p>{item?.universityName}</p>
                  </Link>
                );
            })}
        </div>

        <div className='unitree-title-section'>
            <hr /><p className='unitree-title'>Corporate Partnerships</p><hr />
        </div>
        <div className="partners-section">
            {ecoData !== null && ecoData[0]?.corporatePartner.slice(0,itemsToShow).map((item, index) => {
                let marginTopValue = 0;
                if (!isMobileView()) {
                  if (index % 5 === 1 || index % 5 === 3) {
                    marginTopValue = "30px";
                  } else if (index % 5 === 2) {
                    marginTopValue = "60px";
                  }
                } else {
                  if (index % 4 === 1 || index % 4 === 2) {
                    marginTopValue = "30px";
                  } else {
                    marginTopValue = 0;
                  }
                }
                const corporateId = item.corporateId;
                const corporateType = item.corporateType;
                return (
                  <Link
                    to={
                      corporateType === 0
                        ? `/eco-system/curated-partnership/curated-details`
                        : `/eco-system/corporate-partnership/${item?.corporateName.toLowerCase().replace(/[^\w\s-]/g, '')  // Remove all non-word characters except spaces and hyphens
    .replace(/[\s-]+/g, '-')
                    }/`}
                    key={item.id}
                    onClick={() => setCorporateId(corporateId)}
                    className="public-partners-container-box"
                    id={`home-ecosystem-corporate-${item?.corporateName.toLowerCase().replace(/[^\w\s-]/g, '')  // Remove all non-word characters except spaces and hyphens
                      .replace(/[\s-]+/g, '-')}`}
                  >
                    <div
                      className="public-partners"
                      style={{ marginTop: marginTopValue }}
                    >
                      <img
                        src={item.logo || DummyJob}
                        width={80}
                        height={75}
                        alt={`Partner ${index + 1}`}
                      />
                    </div>
                    <p>{item?.corporateName}</p>
                  </Link>
                );
              })}
          </div>

        {/* <div className="section1">
        {ecoData===null ? ( <>
            <div className="v-portion">
                {defaultLogos.slice(0,2).map((logo, index)=>{
                    return(
                        <div className='row1'>
                            <img key={logo?.id} src={logo?.imageUrl} alt={logo?.name} />
                        </div>
                    )
                })}
            </div>

            <div className="v-portion">
                {defaultLogos.slice(2,6).map((logo, index)=>{
                    return(
                        <Link to={logo.link_url} className='row2' onClick={logo.onClick}>
                        <div className='row2'>
                            <img key={index} src={logo.imageUrl} alt={logo.name} />
                        </div>
                        </Link>
                    )
                })}
            </div>

            <div className="v-portion">
                {defaultLogos.slice(6,9).map((logo, index)=>{
                    return(
                        <Link to={logo.link_url} className='row3' onClick={logo.onClick}>
                        <div className='row3'>
                            <img key={index} src={logo.imageUrl} alt={logo.name} />
                        </div>
                        </Link>
                    )
                })}
            </div>

            <div className="v-portion">
                {defaultLogos.slice(9,14).map((logo, index)=>{
                    const actualIndex = index + 9;
                    const isSpecialLogo = specialIndex.includes(actualIndex);
                    return(
                        <Link to={logo.link_url} className='row4' onClick={logo.onClick}>
                        <div className='row4' style={{visibility: isSpecialLogo? "hidden" : "visible"}}>
                            <img key={index} src={logo.imageUrl} alt={logo.name} />
                        </div>
                        </Link>
                    )
                })}
            </div>

            <div className="v-portion">
                {defaultLogos.slice(14,21).map((logo, index)=>{
                    const actualIndex = index + 14;
                    const isSpecialLogo = specialIndex.includes(actualIndex);
                    return(
                        <Link to={logo.link_url} className='row5' onClick={logo.onClick}>
                        <div className='row5' style={{visibility: isSpecialLogo? "hidden" : "visible"}}>
                            <img key={index} src={logo.imageUrl} alt={logo.name} />
                        </div>
                        </Link>
                    )
                })}
            </div> </>
        ):(
            <>
            <div className="v-portion">
                {companyLogos.slice(0,2).map((logo, index)=>{
                    return(
                        <div className='row1'>
                            <img key={logo?.id} src={logo?.imageUrl} alt={logo?.name} />
                        </div>
                    )
                })}
            </div>

            <div className="v-portion">
                {companyLogos.slice(2,6).map((logo, index)=>{
                    return(
                        <Link to={logo.link_url} className='row2' onClick={logo.onClick}>
                        <div className='row2'>
                            <img key={index} src={logo.imageUrl} alt={logo.name} />
                        </div>
                        </Link>
                    )
                })}
            </div>

            <div className="v-portion">
                {companyLogos.slice(6,9).map((logo, index)=>{
                    return(
                        <Link to={logo.link_url} className='row3' onClick={logo.onClick}>
                        <div className='row3'>
                            <img key={index} src={logo.imageUrl} alt={logo.name} />
                        </div>
                        </Link>
                    )
                })}
            </div>

            <div className="v-portion">
                {companyLogos.slice(9,14).map((logo, index)=>{
                    const actualIndex = index + 9;
                    const isSpecialLogo = specialIndex.includes(actualIndex);
                    return(
                        <Link to={logo.link_url} className='row4' onClick={logo.onClick}>
                        <div className='row4' style={{visibility: isSpecialLogo? "hidden" : "visible"}}>
                            <img key={index} src={logo.imageUrl} alt={logo.name} />
                        </div>
                        </Link>
                    )
                })}
            </div>

            <div className="v-portion">
                {companyLogos.slice(14,21).map((logo, index)=>{
                    const actualIndex = index + 14;
                    const isSpecialLogo = specialIndex.includes(actualIndex);
                    return(
                        <Link to={logo.link_url} className='row5' onClick={logo.onClick}>
                        <div className='row5' style={{visibility: isSpecialLogo? "hidden" : "visible"}}>
                            <img key={index} src={logo.imageUrl} alt={logo.name} />
                        </div>
                        </Link>
                    )
                })}
            </div></>
        )}
            <div className='row-end'>
                <p>University Partnerships</p>
                <p>Corporate Partnerships</p>
            </div>
        </div> */}
        <Link to="/eco-system/" id='home-view-all-button' className='view-all-link-button'><Button className={'gradient-outline-button'} children={"view All"} /></Link>
    </div>
  )
}
export default UniTree