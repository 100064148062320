import React from 'react';
import AptiTest from './AptTest';
import ExpertSpeaks from "./ExpertSpeak";
import HomeBanner from "./HomeBanner";
import News from "./News";
import Pathways from "./Pathways";
import StoriesCarousel from "./StoriesCarousel";
import WorkVideos from "./WorkVideos";
import WorkGoal from './WorkGoal';
import UniTree from './Unitree';
import "./style.scss"
function index() {
  
  return (
    <>
      <HomeBanner/>
      <Pathways />
      <AptiTest/>
      <WorkVideos/>
      <WorkGoal/>
      <News/>
      <ExpertSpeaks/>
      <StoriesCarousel/>
      <UniTree/>
    </>
  )
}

export default index