import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ButtonComponent from "../../commonComponents/Button";
import BreadCrumbs from "../../commonComponents/BreadCrumbs";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import DummyImage from "../../assets/DummyUniversity.png";
import UniversityPageBg from "../../assets/amu_university_img.png";
import { useParams } from "react-router-dom";
import { UniversityDetailsAPI } from "../../utils/apiUrl";
import callAPI from "../../utils/apiAction";
import Lottie from "react-lottie";
import animationData from "../../assets/spinner_loader.json";
import ScrollToTop from "../../components/ScrollToTop.jsx";
import { useIds } from "../../utils/IdsContext.js";
import { Helmet } from "react-helmet-async";
import seoMetaData from "../../utils/seoMetaData";
import schemaData from "../../utils/schemaData";
const UniversityPath = () => {
  const { uniName } = useParams();
  // console.log("Params fetched - Uni Name ", uniName);
  const { universityId, setUniversityId, universityMap } = useIds();
  const location = useLocation();
  const [detailedUniversity, setDetailedUniversity] = useState(null);
  const [uniDetailsData, setUniDetailsData] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const isPathwayComparisonPage = location.pathname.startsWith(
    "/pathway-comparison/"
  );
  const paths1 = [
    { label: "Home", url: "/" },
    { label: "Personalise Pathway", url: "/personalize-pathway/" },
    { label: "Pathway Comparison", url: "/pathway-comparison/" },
    {
      label: detailedUniversity?.universityName,
      url: `/pathway-comparison/${uniName}/`,
    },
  ];
  const paths2 = [
    { label: "Home", url: "/" },
    { label: "Our EcoSystem", url: "/eco-system/" },
    {
      label: detailedUniversity?.universityName,
      url: `/eco-system/university-partnership/${detailedUniversity?.universityName.toLowerCase().replace(/[^\w\s-]/g, '')  // Remove all non-word characters except spaces and hyphens
    .replace(/[\s-]+/g, '-')
                    }/`
    },
  ];
  const metadata = seoMetaData[uniName] || {
    title: "Digivarsity - Work Integrated Career | Career Guidance & Planning",
    description: "Default description for universities",
    keywords: "default, keywords, for, universities"
  };
  const schemadata = schemaData[uniName] || {
    schemaScript:'',
    altText:"default text for image",
    ogTags:{
      url:"default url for university",
      title:"default title for university",
      description:"default description for university",
      image:"default image url for univeristy"
    }
  };
  const paths = isPathwayComparisonPage ? paths1 : paths2;

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
  };
  const fetchUniversityData = async (id) => {
    try {
      const queryParams = { unvId: id };
      const { data } = await callAPI(
        UniversityDetailsAPI,
        "POST",
        queryParams,
        {
          Authorization: "Bearer YourAccessToken",
        }
      );
      setUniDetailsData(data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  useEffect(() => {
    if (Object.keys(universityMap).length > 0) {
      const id = Object.keys(universityMap).find(
        (key) => universityMap[key] === uniName
      );
      if (id) {
        setUniversityId(id);
        if (uniDetailsData && Array.isArray(uniDetailsData)) {
        const foundUniversity = uniDetailsData.find(
          (item) => item.univId.toString() === id.toString()
        );
        if (foundUniversity) {
          setDetailedUniversity(foundUniversity);
        }
      }
      // console.log("id from dynamic is: ", id);
      }
    }
    // Fetch data if not already available
  if (universityId && !uniDetailsData) {
    fetchUniversityData(universityId);
  } else if (uniDetailsData && Array.isArray(uniDetailsData)) {
    const foundUniversity = uniDetailsData.find(
      (item) => item.univId.toString() === universityId.toString()
    );
    if (foundUniversity) {
      setDetailedUniversity(foundUniversity);
      setLoading(false);
    }
  }
  }, [uniName, universityId, setUniversityId, universityMap, uniDetailsData]);

    // React to location.pathname change
    useEffect(() => {
    // This ensures when the location.pathname changes (URL change), it triggers the effect
    if (location.pathname.includes("eco-system")) {
      const params = location.pathname.split("/").filter(Boolean); // Split the path and filter out empty values
      if (params.length === 4) {
        const newUnicourName = params[2];
        // const newCourseName = params[2];
        if (newUnicourName !== uniName) {
          navigate(`/eco-system/${newUnicourName}/`);
        }
      }
    }
  }, [location.pathname, navigate, uniName]);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Lottie options={defaultOptions} height={300} width={300} />
      </div>
    );
  }
  return (
    <>
    <Helmet>
    <meta property="og:site_name" content="Digivarsity"/>
    <meta property="og:type" content="website"/>
    <meta property="og:url" content={schemadata.ogTags.url}/>
    <meta property="og:title" content={schemadata.ogTags.title}/>
    <meta property="og:description" content={schemadata.ogTags.description}/>
    <meta property="og:image" content={schemadata.ogTags.image}/>
    <meta property="og:image:width" content="864"/>
    <meta property="og:image:height" content="480"/>

    <title>{metadata.title}</title>
        <meta name="description" content={metadata.description} />
        <meta name="keywords" content={metadata.keywords} />
    <link rel="canonical" href={`https://www.digivarsity.com/eco-system/university-partnership/${detailedUniversity?.universityName.toLowerCase().replace(/[^\w\s-]/g, '')  // Remove all non-word characters except spaces and hyphens
    .replace(/[\s-]+/g, '-')
                    }/`}/>
    <script
          dangerouslySetInnerHTML={{
            __html: `
              (function(h,o,t,j,a,r){
                  h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                  h._hjSettings={hjid:5075643,hjsv:6};
                  a=o.getElementsByTagName('head')[0];
                  r=o.createElement('script');r.async=1;
                  r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                  a.appendChild(r);
              })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
            `,
          }}
        /> 
        {/* Meta Pixel Code */}
        <script>
          {`
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '517004411113375');
            fbq('track', 'PageView');
          `}
        </script>
        <noscript>
          {`<img height="1" width="1" style="display:none"
          src="https://www.facebook.com/tr?id=517004411113375&ev=PageView&noscript=1" />`}
        </noscript>

        <script type="application/ld+json">
          {schemadata.schemaScript}
        </script>
        {/* Meta Pixel Code */}
    </Helmet>
      <ScrollToTop />
      <div
        className="university-path"
        role="img"
        aria-label={schemadata.altText}
        aria-labelledby={schemaData.altText}
        style={{
          backgroundSize: "cover",
          backgroundImage: `url(${detailedUniversity?.universityBanner}), linear-gradient(90deg, #000 0%, rgba(44, 13, 48, 0.5) 100%)`,
          backgroundPosition: "center center",
          backgroundBlendMode: "multiply",
        }}
      >
      <img 
    src={detailedUniversity?.universityBanner} 
    alt={schemadata.altText} 
    style={{ width: 0, height: 0, opacity: 0, position: 'absolute' }} 
    aria-hidden="true"
  />
        <div className="top-section">
          <ButtonComponent
            className={"back-button"}
            onClick={() => navigate(-1)}
          >
            {" "}
            <KeyboardBackspaceIcon /> Back{" "}
          </ButtonComponent>
          <BreadCrumbs paths={paths} color="white" />
        </div>

        <div className="heading-container">
          <div className="content">
            <img
              src={detailedUniversity?.universityLogo || DummyImage}
              alt="univimg"
              className="univimg"
            />
            <h1 className="university-name">
              {detailedUniversity?.universityName}
            </h1>
          </div>
        </div>
      </div>
      <div className="about-university">
        <h2 className="heading">About University</h2>
        <p className="content">{detailedUniversity?.description}</p>
      </div>
    </>
  );
};

export default UniversityPath;
