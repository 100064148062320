import React from "react";
import { Link as ReactRouterLink } from 'react-router-dom';
import { news } from "../Home/News";
import { NewsCardWithoutLink } from "../Digiverse/LatestNews";
const MoreNews = () => {
  const formatTitleForURL = (title) => {
    return title.toLowerCase().replace(/ /g, '-');
  };
  return (
    <div className="more-news">
      {news.map((item, index) => {
        return (
          <div className="more-news-card">
            {/* <ReactRouterLink to={"/news/" + (item.route)} style={{ textDecoration: "none" }}> */}
              <NewsCardWithoutLink
                image_src={item.image_src}
                publisher={item.publisher}
                route={item.route}
                date={item.date}
                title={item.title}
                content={item.content}
                className={'newscard'}
                news_link={item.news_link}
              />
            {/* </ReactRouterLink> */}
            
          </div>
        );
      })}
    </div>
  );
};
export default MoreNews;
