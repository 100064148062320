import React from 'react'
import BreadCrumbs from "../../commonComponents/BreadCrumbs"
import { news } from '../Home/News';
import { Helmet } from 'react-helmet-async';
const paths = [
  { label: 'Home', url: '/' },
  { label: 'Digiverse', url: '/digiverse/' },
  { label: 'News', url: `/news/${news[0].route}` },
];
const NewsPath = () => {
  return (
    <div className='news-path'>
    <Helmet><link rel="canonical" href={window.location.href}/>
    <script
          dangerouslySetInnerHTML={{
            __html: `
              (function(h,o,t,j,a,r){
                  h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                  h._hjSettings={hjid:5075643,hjsv:6};
                  a=o.getElementsByTagName('head')[0];
                  r=o.createElement('script');r.async=1;
                  r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                  a.appendChild(r);
              })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
            `,
          }}
        /> 
        {/* Meta Pixel Code */}
        <script>
          {`
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '517004411113375');
            fbq('track', 'PageView');
          `}
        </script>
        <noscript>
          {`<img height="1" width="1" style="display:none"
          src="https://www.facebook.com/tr?id=517004411113375&ev=PageView&noscript=1" />`}
        </noscript>
        {/* Meta Pixel Code */}
    </Helmet>
      <BreadCrumbs paths={paths} color="black"/>
      <h1 className='heading'>Latest News</h1>
    </div>
  )
}
export default NewsPath