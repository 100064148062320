import React from 'react';
import './style.scss'
import FaqBanner from './FaqBanner';
import FaqList from './FaqList';
function index() {
  return (
    <>
    <FaqBanner/>
    {/* <FaqList/> */}
    </>
  )
}

export default index