import React from 'react';
import AboutBanner from './AboutBanner';
import AboutBridge from './AboutBridge';
import AboutMission from './AboutMission';
import AboutWork from './AboutWork';
import AboutLearn from './AboutLearn';
import './style.scss'
import Footer from '../../components/FooterLayout';
function index() {
  return (
    <>
    <div className="about-content">
      <AboutBanner/>
      <AboutBridge/>
      <AboutMission/>
      <AboutWork/>
      <AboutLearn/>
      <Footer/>
    </div>
    </>
  )
}
export default index