import React from "react";
import ButtonComponent from "../../commonComponents/Button";
import Alert from "../../assets/Alert.png"
import { Link } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";


const AdmissionClosed = ({ sourceComponent, onClose }) => {
    return(
        <div className="admission">
            <div className="admission-popup-container">
                <div className="closeIcon">
                    <ButtonComponent id={"signin-closebutton"}>
                        <CloseIcon className="search-icon" onClick={onClose}/>
                    </ButtonComponent>
                </div>
                <div className="popup-layout">
                    <img src={Alert} alt="Alert" />
                    <div className="content">
                        <h3>Admissions Closed for Now!</h3>
                        <div className="admission-message">
                            The admission cycle has ended, but stay tuned for the next one. In the meantime, connect with our career advisor for guidance and support.
                        </div>
                    </div>
                </div>
                <div className="button-component">
                <Link to="/confirm-slot">
                    <ButtonComponent
                        type="submit"
                        children="Speak to our Career Advisor"
                        className={"continue-gradient-button"}
                        id={"header-continue-button"}
                        style={{textTransform: 'none', width: '100%'}}
                    />
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default AdmissionClosed