import React from 'react'
import CuratedBanner from './CuratedBanner'
import './style.scss';
const index = () => {
  return (
    <div>
      <CuratedBanner/>
    </div>
  )
}
export default index
