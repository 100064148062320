import React from 'react'
import CustomizePathwayPath from './CustomizePathwayPath'
import PathwayStepper from './PathwayStepper'
import './style.scss';

const index = () => {
  return (
    <>  
        <CustomizePathwayPath />
        <PathwayStepper />
    </>
  )
}

export default index