import React, { useEffect, useState } from "react";
import ButtonComponent from "../../commonComponents/Button";
const AllLocationsBox = ({
  allCities,
  onCloseFunction,
  selectedCities,
  currentIndex,
  handleCityChange,
  handleSubmitBtn,
}) => {
  const [tempSelectedCities, setTempSelectedCities] = useState([]);

  useEffect(() => {
    setTempSelectedCities(selectedCities);
  },[]);

  return (
    <div className="all-locations-box">
      <div className="top-section">
        <h3>Select your preferred Work Location</h3>
        <ButtonComponent
          style={{ padding: "0" }}
          children={
            <>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
              >
                <mask
                  id="mask0_2950_15166"
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                  width="30"
                  height="30"
                >
                  <rect width="30" height="30" fill="#D9D9D9" />
                </mask>
                <g mask="url(#mask0_2950_15166)">
                  <path
                    d="M15.0097 16.392L10.6957 20.7037C10.5032 20.8962 10.2698 20.9902 9.9955 20.986C9.72121 20.9817 9.48781 20.8834 9.29529 20.691C9.10278 20.4985 9.00652 20.2631 9.00652 19.9847C9.00652 19.7063 9.10278 19.4709 9.29529 19.2785L13.5964 14.9796L9.2825 10.7011C9.08999 10.5087 8.99586 10.2733 9.00014 9.99488C9.00439 9.71646 9.10278 9.48104 9.29529 9.28862C9.48781 9.09621 9.72335 9 10.0019 9C10.2805 9 10.516 9.09621 10.7085 9.28862L15.0097 13.6003L19.2903 9.28862C19.4829 9.09621 19.7163 9 19.9906 9C20.2649 9 20.4983 9.09621 20.6908 9.28862C20.8969 9.49468 21 9.7335 21 10.0051C21 10.2767 20.8969 10.5087 20.6908 10.7011L16.3896 14.9796L20.7036 19.2912C20.8961 19.4837 20.9924 19.7169 20.9924 19.9911C20.9924 20.2653 20.8961 20.4985 20.7036 20.691C20.4974 20.897 20.2585 21 19.9867 21C19.715 21 19.4829 20.897 19.2903 20.691L15.0097 16.392Z"
                    fill="#1C1B1F"
                  />
                </g>
              </svg>
            </>
          }
          onClick={() => {
            handleSubmitBtn(currentIndex, tempSelectedCities);
            onCloseFunction();
          }}
        />
      </div>
      <div className="all-city-container">
        {allCities.map((city, index) => {
          const isChecked = selectedCities?.includes(city?.cityName);
          return (
            <div className="all-city-container-content">
              <label key={index}>
                {" "}
                <input
                  type="checkbox"
                  checked={isChecked}
                  onChange={() => {
                    handleCityChange(city?.cityName);
                  }}
                />{" "}
                <h4>{city?.cityName}</h4>{" "}
              </label>
            </div>
          );
        })}
      </div>
      <div className="confirm-button-section">
        <ButtonComponent children="confirm" className={"confirm-button"} onClick={onCloseFunction} />
      </div>
    </div>
  );
};

export default AllLocationsBox;
