import React from 'react'
import NewsPath from './NewsPath'
import MainNews from './MainNews'
import MoreNews from './MoreNews'
import './style.scss'
const index = () => {
  return (
    <>
        <NewsPath />
        <MainNews />
        <MoreNews />
    </>
  )
}
export default index