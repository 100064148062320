import React from 'react';
import DigiverseBanner from './DigiverseBanner';
import RecentUpdates from './RecentUpdates';
import LatestNews from "./LatestNews";
import LatestEvents from "./LatestEvents";
import RelatedReports from "./RelatedReports";
import CareerLounges from "./CareerLounges";
import './style.scss'
function index() {
  return (
    <>
    <div className="digiverse-content">
        <DigiverseBanner/>
        <RecentUpdates />
        <LatestNews />
        <LatestEvents />
        <RelatedReports />
        <CareerLounges />
    </div>
    </>
  )
}
export default index