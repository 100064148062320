import React, {useState, useEffect} from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, EffectCoverflow, Pagination, Navigation } from 'swiper/modules';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import ModalComponent from '../../commonComponents/ModalComponent';
import CloseIcon from '@mui/icons-material/Close';
import { AssetsAPI } from '../../utils/apiUrl';
import callAPI from '../../utils/apiAction';
export function StoriesCarouselVideo({onClose, story_link}) {
    return(
      <>
        <div className="scvideo">
          <div className="scvideodiv">
            <div className="close-icon" onClick={onClose}>
              <CloseIcon color="white"
              />
            </div>
            <iframe src={`${story_link}?autoplay=1`} width="100%" height="500px" allow="autoplay; encrypted-media" />
          </div>
          
        </div>
      </>
    )
    };
const StoryCard = ({ id, content, name, designation, img_src, story_link, tags }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleOpen = () => setIsModalOpen(true);
    const handleClose = () => setIsModalOpen(false);
    return (
        <div key={id} className='story-card'>
            <div className='left-section'>
                <p>{tags[1].tagName}</p>
                <div className='info'>
                    <p>{tags[0].tagName}</p>
                    <h5>{tags[2].tagName}</h5>
                    {/* <h5>{tags[3].tagName}</h5> */}
                </div>
            </div>
            <div>
                <div className='div_imgbg right-section' style={{ backgroundImage: `url(${img_src})`, backgroundRepeat: "no-repeat", backgroundSize: "cover", display: "flex", flexDirection: "column", justifyContent: "center", alignItems:"center", position: "relative", borderTopLeftRadius: "10px", borderTopRightRadius: "10px", borderBottomRightRadius: "10px" }} >
                    <div style={{ position: "absolute", top: "0", width: "100%", height: "100%", backgroundColor: "black", opacity: "0.5", borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}></div>
                    <ModalComponent buttonId={"home-stories-play-button"} buttonContent={<PlayCircleIcon style={{ color: "white", fontSize: "45px" }} />} style={{ position: "relative", outline: "none"}} children={<StoriesCarouselVideo onClose={handleClose} story_link={story_link}/>} open={isModalOpen} setOpen={setIsModalOpen} onOpen={handleOpen} onClose={handleClose}/>
                </div>

            </div>
            
                
        </div>
    )
}

export default function StoriesCarousel() {
    const [storyCardResponse, setStoryCardResponse] = useState([]); // Initially set to null
    const [loading, setLoading] = useState(true); // Add loading state
  
    useEffect(() => {
      const fetchAssetData = async () => {
        try {
          const { data } = await callAPI(
            AssetsAPI,
            "POST",
            { pageName: "Home" },
            {
              Authorization: "Bearer YourAccessToken",
            }
          );
          setStoryCardResponse(data);
          setLoading(false); // Set loading to false after fetching data
        } catch (error) {
          console.log(error);
          setLoading(false); // Set loading to false even if there's an error
        }
      };
      fetchAssetData();
    }, []);
    const [scData, setScData] = useState([]);
    useEffect(() => {
      if (storyCardResponse && storyCardResponse.length > 0) {
        const scData = storyCardResponse[0].sections[2] ;
        if (
          scData &&
          scData.subSections &&
          scData.subSections.length > 0 &&
          scData.subSections[0].sectionAssets.length > 0
        ) {
          setScData(scData.subSections[0].sectionAssets);
        }
      }
    }, [storyCardResponse]);
    return (
        <>
            <div className='stories-carousel'>
                <h2>Stories of Change</h2>
                <Swiper
                key={scData.length}
                    effect={'coverflow'}
                    grabCursor={true}
                    centeredSlides={true}
                    lazyLoading={true}
                    speed={2000}
                    loop={true}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    breakpoints={{
                        360: {
                          slidesPerView: 1.50,
                        },
                        395:{
                            slidesPerView: "auto",
                        },
                        640: {
                          slidesPerView: "auto",
                        },
                        768: {
                          slidesPerView: "auto",
                        },
                        1024: {
                          slidesPerView: 2.5,
                        },
                        1440: {
                          slidesPerView: 2.5,
                        }
                      }}
                    // slidesPerView={2.5}
                    coverflowEffect={{
                        rotate: 0,
                        stretch: 0,
                        depth: 150,
                        modifier: 1,
                        slideShadows : true, 
                    }}
                    pagination={{ el: ".swiper-pagination", clickable: true }}
                    navigation={{
                        nextEl: ".swiper-button-next",
                        prevEl: ".swiper-button-prev",
                        clickable: true
                    }}
                    modules={[Autoplay, EffectCoverflow, Pagination, Navigation]}
                    className="mySwiper"
                >
                    {scData.map((story) => {
                        return (
                            <>
                                <SwiperSlide>
                                    <StoryCard 
                                        id ={story.id}
                                        content={story.content}
                                        name={story.name}
                                        designation={story.designation}
                                        // location={story.location}
                                        img_src={story.imageUrl}
                                        story_link={story.videoUrl}
                                        tags={story.tags}
                                    />
                                </SwiperSlide>
                            </>
                        )
                    })}

                    <div className="slide-controller">
                        <div className="swiper-button-prev slider-arrow" style={{ color: "white" }}></div>
                        <div className="swiper-button-next slider-arrow" style={{ color: "white" }}></div>
                        <br></br>
                        <br></br>
                        <div className="swiper-pagination"></div>
                    </div>
                </Swiper>

            </div>
        </>
    );
}
