import React from 'react'
import './style.scss'
import EventPath from './EventPath'
import EventsOverview from './EventsOverview'

const index = () => {
  return (
    <>
        <EventPath />
        <EventsOverview />
    </>
  )
}

export default index