import React, { useState } from 'react'
import ButtonComponent from '../commonComponents/Button'
import { Link, useNavigate } from 'react-router-dom'
import { useLoginState } from '../utils/LoginStateContext';
import PopoverComponent from '../commonComponents/Popover';
import Signin from '../pages/CustomizePathway/Signin';
//Styling present in Home = style.scss

const AlertPopover = ({ onClose }) => {
    const navigate = useNavigate();
    const { isLoggedIn, userInfo, login, logout } = useLoginState();

    const handlePathway = () =>{
       
        if(isLoggedIn){
            navigate("/pathway-comparison/")
        }
        else{
            // onClose();
            // setIsVisible(true);
        }
    }
 
    return (
        <>
            <div className='alert-popover'>
                <div className='alert-popover-top-section'>
                    <p className='alert-title'>Alert</p>
                    <ButtonComponent
                        children={<>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <path d="M8.00965 9.39205L3.69574 13.7037C3.50323 13.8962 3.26981 13.9902 2.9955 13.986C2.72121 13.9817 2.48781 13.8834 2.29529 13.691C2.10278 13.4985 2.00652 13.2631 2.00652 12.9847C2.00652 12.7063 2.10278 12.4709 2.29529 12.2785L6.59645 7.97958L2.2825 3.70113C2.08999 3.50871 1.99586 3.27329 2.00014 2.99488C2.00439 2.71646 2.10278 2.48104 2.29529 2.28862C2.48781 2.09621 2.72335 2 3.00191 2C3.28046 2 3.51598 2.09621 3.7085 2.28862L8.00965 6.60031L12.2903 2.28862C12.4829 2.09621 12.7163 2 12.9906 2C13.2649 2 13.4983 2.09621 13.6908 2.28862C13.8969 2.49468 14 2.7335 14 3.0051C14 3.2767 13.8969 3.50871 13.6908 3.70113L9.38964 7.97958L13.7036 12.2912C13.8961 12.4837 13.9924 12.7169 13.9924 12.9911C13.9924 13.2653 13.8961 13.4985 13.7036 13.691C13.4974 13.897 13.2585 14 12.9867 14C12.715 14 12.4829 13.897 12.2903 13.691L8.00965 9.39205Z" fill="#9D9D9D" />
                            </svg>
                        </>}
                        onClick={onClose}
                        style={{paddingRight: 0}}
                        className={'cross-button'}
                    />
                </div>
                <p className='alert-message'>You’ve already selected 3 pathways. Please modify your choices in 'My Pathway' to add more.</p>
                <div className='alert-popover-bottom-section'>
                    <ButtonComponent children={"Cancel"} onClick={onClose} className={'cancel-button'}/>
                    <ButtonComponent onClick={handlePathway} children={"Go to My Pathway"} className={'confirm-button'} />
                    
                </div>
            </div>
        </>
    )
}

export default AlertPopover