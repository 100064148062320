import React from "react";
import AccountTree from "../../assets/account_tree.png";
import Category from "../../assets/category.png";
import Hub from "../../assets/hub.png";
import Clinical from "../../assets/clinical_notes.png";
import Strategy from "../../assets/strategy.png"
function AboutLearn() {
  return (
    <div className="learn-section">
        <h2>Learn, Work, and Succeed</h2>
      <div className="learn-right">
        <div>Practical application of theoretical knowledge <img src={AccountTree}/> </div>
        <div>Enhance skills and career prospects <img src={Category}/> </div>
        <div>Access to valuable industry connections <img src={Hub}/> </div>
        <div>Development of relevant professional skills <img src={Clinical}/> </div>
        <div>Real-world experience through internships<img src={Strategy}/> </div>
      </div>
    </div>
  );
}
export default AboutLearn;
