import React from 'react'
import PrivacyPolicyComponent from './PrivacyPolicyComponent'
import "./style.scss";
const index = () => {
  return (
    <>
        <PrivacyPolicyComponent />
    </>
  )
}
export default index