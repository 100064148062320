import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import ButtonComponent from "../../commonComponents/Button";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import DummyPlace from "../../assets/DummyLocation.png";
import { LocationListAPI } from "../../utils/apiUrl";
import { useActiveStep } from "../../utils/ActiveStepContext";
import callAPI from "../../utils/apiAction";
import OhNoComponent from "./OhNoComponent";
import Lottie from "react-lottie";
import animationData from "../../assets/spinner_loader.json";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import { useLoginState } from "../../utils/LoginStateContext";
import Signin from "./Signin";
const LocationCard = ({ id, img_src, city_name, selected, onSelect, testid }) => {
  return (
    <ButtonComponent
    id={`${testid}`}
      className={`content-button ${selected ? "selected" : ""}`}
      onClick={() => onSelect(id)}
    >
      <div key={id} className={`content `}>
        <>
          <img src={img_src || DummyPlace} alt="city" />
          <h5>{city_name}</h5>
        </>
      </div>
    </ButtonComponent>
  );
};
const Location = ({onClose}) => {
  const { selectLocation, selectedLocation } = useActiveStep();
  const LocationURL = LocationListAPI;
  const [locationData, setLocationData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [locationDataAvailable, setLocationDataAvailable] = useState(null);
  const { updateDataAvailability } = useActiveStep();
  sessionStorage.setItem("LocationID", selectedLocation?.id);
  sessionStorage.setItem("LocationName", selectedLocation?.name);
  const [city, setCity] = useState(null);
  const [enteredCity, setEnteredCity] = useState("");
  const [enteredState, setEnteredState] = useState("");
  const location = useLocation();
  const {isLoggedIn, logout, login} = useLoginState();
  const { activeStep, setActiveStep, incrementStep, decrementStep, resetStep,canAdvanceToNextStep, sSource, setSource } = useActiveStep();
console.log("ssource: ", sSource)
  const { source } = location.state || {};
  // console.log(source);
  useEffect(() => {
    if (city !== null) {
      setEnteredCity("");
    } else if (enteredCity.trim() !== "") {
      setCity(null);
    }
  }, [city, enteredCity]);
  useEffect(()=>{
    if(!isLoggedIn){
      sessionStorage.setItem("resumestep", 3);
      setActiveStep(0);
    }
  },[])
  const handleEnteredCityChange = (event) => {
    const newEnteredCity = event.target.value;
    setCity(null);
    setEnteredCity(newEnteredCity);
  };
  useEffect(() => {
    // Select the elements with class `location-page` and `opportunity-page`
    const locationPage = document.querySelector('.location-page');
    const opportunityPage = document.querySelector('.opportunity-page');
    
    // Apply the style to their sibling elements
    if (locationPage && locationPage.nextElementSibling) {
      locationPage.nextElementSibling.style.display = 'flex';
    }
    
    if (opportunityPage && opportunityPage.nextElementSibling) {
      opportunityPage.nextElementSibling.style.display = 'flex';
    }

    // Cleanup (optional, if styles need to reset on component unmount)
    return () => {
      if (locationPage && locationPage.nextElementSibling) {
        locationPage.nextElementSibling.style.display = '';
      }
      if (opportunityPage && opportunityPage.nextElementSibling) {
        opportunityPage.nextElementSibling.style.display = '';
      }
    };
  }, []); 
  const queryParams = {
    _industry: sSource === "course" ? 0 : sessionStorage.getItem("IndustryID"),
    _qualification: sSource === "course" ? 0 :sessionStorage.getItem("QualificationID"),
    _aspiration: sSource === "course" ? 0 :sessionStorage.getItem("RoleID"),
    _courseId: sessionStorage.getItem("DegreeID"),
    _unvId: sessionStorage.getItem("UniversityID"),
    _city: enteredCity || "",
    _state: enteredState || "",
    _pincode: 0,
  };
  const fetchDefComboData = async () => {
    try {
      let finalQueryParams = { ...queryParams };
      if (city !== null) {
        finalQueryParams._city = city;
      }
      const { data } = await callAPI(LocationURL, "POST", {
        _industry: sessionStorage.getItem("IndustryID"),
        _qualification: sessionStorage.getItem("QualificationID"),
        _aspiration: sessionStorage.getItem("RoleID"),
        _courseId: sessionStorage.getItem("DegreeID"),
        _unvId: sessionStorage.getItem("UniversityID"),
        _city:"",
        _state:  "",
        _pincode: 0,
      }, {
        Authorization: "Bearer YourAccessToken",
      });
      setLocationData(data);
      if (data.length !== 0 && data !== null) {
        setLocationDataAvailable(true);
        updateDataAvailability(true);
      } else {
        setLocationDataAvailable(false);
        updateDataAvailability(false);
      }
    } catch (error) {
      console.log(error);
      updateDataAvailability(false);
    } finally {
    }
  };
  const fetchComboData = async () => {
    try {
      let finalQueryParams = { ...queryParams };
      if (city !== null) {
        finalQueryParams._city = city;
      }
      // console.log(finalQueryParams);
      const { data } = await callAPI(LocationURL, "POST", finalQueryParams, {
        Authorization: "Bearer YourAccessToken",
      });
      setLocationData(data);
      if (data.length !== 0 && data !== null) {
        setLocationDataAvailable(true);
        updateDataAvailability(true);
      } else {
        setLocationDataAvailable(false);
        updateDataAvailability(false);
      }
    } catch (error) {
      console.log(error);
      updateDataAvailability(false);
    } finally {
    }
  };

  useEffect(() => {
    (async () => {
      setPageLoading(true);
      await fetchComboData();
      setPageLoading(false);
    })();
  }, []);

  useEffect(() => {
    if (city || enteredCity || enteredState) {
      (async () => {
        setIsLoading(true);
        await fetchComboData();
        setIsLoading(false);
      })();
    }else {
      if(sSource !== "course"){
        // Reset and fetch default locations when all fields are empty
      (async () => {
        await fetchDefComboData();
      })();
      }
    }
  }, [city, enteredCity, enteredState]);


  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
  };

  if (pageLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Lottie options={defaultOptions} height={300} width={300} />
      </div>
    );
  }
  const handleLocationClick = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition);
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  };

  function showPosition(position) {
    axios
      .get(
        `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${position.coords.latitude}&longitude=${position.coords.longitude}`
      )
      .then((response) => {
        const city = response.data.city;
        setCity(city);
      })
      .catch((error) => {
        console.error(error);
        setCity("Error");
      });
  }
  return (
    <>
     <Helmet>
     <script>
        {`!function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '517004411113375');
        fbq('track', 'PageView');
        fbq('track', 'Add to cart');`}
      </script>
      <noscript>{`
        <img
          height="1"
          width="1"
          style="display:none"
          src="https://www.facebook.com/tr?id=517004411113375&ev=PageView&noscript=1"
          alt="Facebook Pixel"
        />
      `}</noscript>
     </Helmet>
{isLoggedIn ? 
        <div className="location-page">
          <h1>Where Will Your Internship Adventure Be?</h1>
          <h4>Select your preferred location of internship</h4>

          <div className="location-section">
            <ButtonComponent
              onClick={handleLocationClick}
              className="location-detect"
            >
              <input
                value={city || ""}
                className="location-detect-inputbox"
                id="personalizepathway-location-detect"
                placeholder="Detect my current location"
                readOnly
              />
              <LocationOnIcon />
            </ButtonComponent>
            <h5>or</h5>
            <input
              value={enteredCity}
              onChange={handleEnteredCityChange}
              className="location-input-box"
              id="personalizepathway-location-Entercity"
              placeholder="Enter city"
            />
            <h5>or</h5>
            <input
              value={enteredState}
              onChange={(e) => {
                setEnteredState(e.target.value);
              }}
              className="location-input-box"
              id="personalizepathway-location-Enterstate"
              placeholder="Enter state"
            />
          </div>

          <div className="error-message-section">
            {city === "Error" && (
              <h5 className="error-messages-location">
                Failed to detect your location. Please try again.
              </h5>
            )}
            {locationData === null && city === null && (
              <h5 className="error-messages-location">
                Currently there are no jobs available at selected location.
                Please try another location or select from the below available
                locations
              </h5>
            )}
          </div>
          {locationDataAvailable !== null && locationDataAvailable ? (<>
          <h3>Popular Cities</h3>
          <div className="popular-cities">
            {isLoading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Lottie options={defaultOptions} height={300} width={300} />
              </div>
            ) : (
              locationData !== null &&
              locationData.slice(0, 7).map((item, index) => {
                return (
                  <LocationCard
                  testid={`location-card-${index}`}
                    id={item?.id}
                    img_src={item?.img_src}
                    city_name={item?.cityName}
                    selected={selectedLocation?.id === item?.id}
                    onSelect={() => selectLocation(item?.id, item?.cityName)}
                  />
                );
              })
            )}
          </div>
          </>):(<OhNoComponent />)}
        </div> : <div>
          <Signin sourceComponent={"course"}/>
        </div>}
       
        
      
    </>
  );
};

export default Location;
