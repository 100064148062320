import React from 'react'
import ReportsPath from './ReportsPath'
import DetailedReport from './DetailedReport'
import RelatedReports from './RelatedReports'
import './style.scss'

const index = () => {
  return (
    <>
      <ReportsPath />
      <DetailedReport />
      <RelatedReports />
    </>
  )
}

export default index