import React from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
import Grow from '@mui/material/Grow';
import Fade from '@mui/material/Fade';
import Alert from '@mui/material/Alert';

function getTransitionComponent(transitionName) {
  switch (transitionName) {
    case 'fade':
      return Fade;
    case 'grow':
      return Grow;
    case 'slide':
      return Slide;
    default:
      return Slide; // Default to Slide if transition name is not recognized
  }
}

const AlertComponent = ({ open, onClose, message, severity, variant, autoHideDuration, anchorOrigin, transition, disableAutoHide, className, backdropWant }) => {
  const Transition = getTransitionComponent(transition);


  return (
    <>
    {backdropWant && open && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 100,
          }}
        ></div>
      )}
    <Snackbar
      open={open}
      onClose={onClose}
      // autoHideDuration={autoHideDuration}
      autoHideDuration={!disableAutoHide ? autoHideDuration : null}
      TransitionComponent={Transition}
      anchorOrigin={anchorOrigin}
      className={className}
    >
      <Alert variant={variant} severity={severity} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
    </>
  );
};

AlertComponent.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  severity: PropTypes.oneOf(['error', 'warning', 'info', 'success']),
  variant: PropTypes.oneOf(['filled', 'outlined', 'standard']),
  autoHideDuration: PropTypes.number,
  anchorOrigin: PropTypes.shape({
    vertical: PropTypes.oneOf(['top', 'bottom']),
    horizontal: PropTypes.oneOf(['left', 'center', 'right'])
  }),
  transition: PropTypes.oneOf(['fade', 'grow', 'slide']).isRequired,
  children: PropTypes.node.isRequired,
};

AlertComponent.defaultProps = {
  severity: 'info',
  variant: 'filled',
  autoHideDuration: 4000,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left'
  },
  transition: 'slide',
  disableAutoHide: false,
};

export default AlertComponent;
