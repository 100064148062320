//AboutBanner.jsx
import React from "react";
import BreadCrumbs from "../../commonComponents/BreadCrumbs";
import FaqList from "./FaqList";
import Footer from "../../components/FooterLayout";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import { Helmet } from "react-helmet-async";

const paths = [
  { label: "Home", url: "/" },
  { label: "FAQs", url: "/faq/" },
];

const FaqBanner = () => {
  return (
    <>
      <Helmet>
        <title>Digivarsity FAQs: All Your Questions Answered</title>
        <meta
          name="description"
          content="Find answers to common questions about Digivarsity's programs, admissions, and more. Visit our FAQ page for detailed information and guidance."
        ></meta>
        <link rel="canonical" href={`${window.location.origin}${window.location.pathname}`}/>
        <script
          dangerouslySetInnerHTML={{
            __html: `
              (function(h,o,t,j,a,r){
                  h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                  h._hjSettings={hjid:5075643,hjsv:6};
                  a=o.getElementsByTagName('head')[0];
                  r=o.createElement('script');r.async=1;
                  r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                  a.appendChild(r);
              })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
            `,
          }}
        /> 
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "FAQPage",
            mainEntity: [
              {
                "@type": "Question",
                name: "So, what exactly is Digivarsity?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "Digivarsity is your gateway to innovative work-integrated degree programs from India's top universities! We blend academic learning with real-world experience to equip you with the skills and knowledge needed for long-term career success. Think of us as the bridge between higher education and the future workforce.",
                },
              },
              {
                "@type": "Question",
                name: "Are the programs listed on Digivarsity approved by UGC?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "Absolutely! All the programs you find on Digivarsity are UGC-approved, so you can be assured of their credibility and quality.",
                },
              },
              {
                "@type": "Question",
                name: "Why should I choose a Work Integrated Degree with Digivarsity?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "At Digivarsity, we offer a unique dual advantage: you get to learn while gaining valuable work experience at the same time. Our platform connects you with top Indian universities and leading companies looking for fresh, young talent. This blend of online learning and real-world experience sets you up for success like no other program can.",
                },
              },
              {
                "@type": "Question",
                name: "Do all Online Degree programs on Digivarsity come with a work experience program?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "Yes, all the Online Degree programs listed in Digivarsity come with work experience. You can find the best work opportunities that’s right for you, while completing your degree.",
                },
              },
              {
                "@type": "Question",
                name: "Do employers recognize Work Integrated Degrees?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "Yes, they do! Employers see Work Integrated Degrees as valuable assets. They appreciate graduates who come with work experience because it shows they're highly skilled and knowledgeable. This makes them more likely to offer you a job!",
                },
              },
              {
                "@type": "Question",
                name: "How useful is a Work Integrated Degree program?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "A Work Integrated Degree can be incredibly useful, especially if you have clear personal and professional goals. It helps you gain valuable work experience, which looks great on your resume and can open up more career opportunities for you.",
                },
              },
              {
                "@type": "Question",
                name: "Why are Work Integrated Degree programs better than other degree programs?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "Work Integrated Degree programs offer a unique blend of academic learning and practical work experience. This means you get to apply what you learn in real-world settings, enhance your resume, build a professional network, and even earn a salary while studying. Plus, these programs are often closely aligned with industry needs, which can boost your employability and career prospects.",
                },
              },
              {
                "@type": "Question",
                name: "How exactly do Work Integrated Degree programs work?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "With a Work Integrated Degree program, you have the flexibility to attend classes and complete coursework online. You'll divide your time between studying and gaining real-world experience through relevant internships. This means you'll be earning your degree while also gaining practical skills and experience in the field.",
                },
              },
              {
                "@type": "Question",
                name: "What's the difference between a Regular degree and a Work Integrated Degree with work experience?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "Instead of waiting until after graduation to start gaining work experience, a Work Integrated Degree lets you dive into the real world while you're still studying. By the time you graduate, you'll already have valuable skills and experience under your belt, giving you a head start in the job market.",
                },
              },
              {
                "@type": "Question",
                name: "How long does it take to complete a Work Integrated Degree program?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "The length of Work Integrated Degree programs can vary depending on the level and specifics of the program you choose. Typically, undergraduate programs take about 3 years, while master's programs take around 2 years. However, the beauty of these programs is that they offer flexibility, allowing you to complete your studies at a pace that works best for you.",
                },
              },
              {
                "@type": "Question",
                name: "How many hours of learning will I have to put in while I am completing my Work Integrated Degree program?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "The number of hours vary according to the Work Integrated Degree program you choose. You can find further details on the detailed program page of every program.",
                },
              },
              {
                "@type": "Question",
                name: "Is the Apprenticeship/Internship mandatory?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "Yes, it's mandatory to complete your Apprenticeship/Internship as part of your degree program. It's a fantastic opportunity to apply what you've learned in a real-world setting, earn a monthly stipend, and gain valuable work experience and skills.",
                },
              },
              {
                "@type": "Question",
                name: "Will I get paid during the Apprenticeship/Internship?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "Absolutely! You'll receive a monthly stipend for the entire duration of your Apprenticeship/Internship, which is a great bonus while gaining valuable experience.",
                },
              },
              {
                "@type": "Question",
                name: "How do I access the online class learning materials?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "All the details and learning materials are available online through your personal login details on your Learning Management System (LMS). You'll get access to everything you need after enrolling.",
                },
              },
              {
                "@type": "Question",
                name: "Who can apply for a Degree with Work Experience?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "Anyone who's completed their 10+2 or equivalent from a recognized board can apply for any of our Work Integrated Degrees with a work opportunity. We welcome students from diverse backgrounds to join us on this journey!",
                },
              },
              {
                "@type": "Question",
                name: "What educational qualifications do I need before enrolling in a Work Integrated Degree program?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "For most undergraduate programs, you'll need to have passed your class 12 exams and obtained your passing certificate. For postgraduate programs, you'll typically need a relevant undergraduate degree. However, specific requirements can vary depending on the program. You can check out the individual requirements for each program listed on Digivarsity's program page.",
                },
              },
              {
                "@type": "Question",
                name: "What documents do I need to meet the eligibility criteria?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "You will need a scanned copy of your passport-size color photograph, the original qualifying examination certificate/marksheet, and a KYC document such as Aadhaar Card, PAN, or Voter ID. Additionally, you'll need a scanned copy of a document mentioning your date of birth, like your 10th marksheet or school leaving certificate.",
                },
              },
              {
                "@type": "Question",
                name: "How can I pay the program fee?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "You can pay the fee through either Debit/Credit Card or Net Banking. Specific payment terms may vary from university to university.",
                },
              },
              {
                "@type": "Question",
                name: "Who are Digivarsity career advisors?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "Digivarsity career advisors are a friendly team of trained education experts dedicated to helping you find the perfect Work Integrated Degree program for you. They're here to guide you every step of the way!",
                },
              },
              {
                "@type": "Question",
                name: "How do I request a call back? And how does it work?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "Booking a call back from a career advisor is easy! Just visit the contact us page on Digivarsity, fill out the request call back section, and submit your details. Your designated career advisor will then give you a call. Alternatively, you can directly call on +91 720 467 7888.",
                },
              },
              {
                "@type": "Question",
                name: "Is there a cost for a session with the career advisor?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "No, the consultation sessions with Digivarsity's career advisors are free of cost. So if you are unsure about your choices, you can talk to one of Digivarsity’s career advisors and find what is right for you.",
                },
              },
            ],
          })}
        </script>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: 1,
                name: "Home",
                item: "https://www.digivarsity.com/",
              },
              {
                "@type": "ListItem",
                position: 2,
                name: "FAQs",
                item: "https://www.digivarsity.com/faq/",
              },
            ],
          })}
        </script>
        {/* Meta Pixel Code */}
        <script>
          {`
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '517004411113375');
            fbq('track', 'PageView');
          `}
        </script>
        <noscript>
          {`<img height="1" width="1" style="display:none"
          src="https://www.facebook.com/tr?id=517004411113375&ev=PageView&noscript=1" />`}
        </noscript>
        {/* Meta Pixel Code */}
      </Helmet>

      <div className="faq-parent">
        <div className="faq-banner">
          <div className="flex-it">
            <div className="left-section">
              <div className="breadcrumbs-div">
                <BreadCrumbs paths={paths} />
              </div>
              <div className="title">
                <h1>
                  Frequently Asked <br /> Questions
                </h1>
              </div>
            </div>

            <div className="svgs svg-large">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="656"
                height="500"
                viewBox="0 0 656 500"
                fill="none"
              >
                <g filter="url(#filter0_f_2877_47719)">
                  <path
                    d="M265.061 -26.3534C265.061 -26.3534 215.34 87.4658 235.357 185.386C262.294 317.161 397.919 356.442 421.022 469.456C444.124 582.471 370.976 691.478 370.976 691.478"
                    stroke="#0049D7"
                    stroke-width="60"
                  />
                </g>
                <defs>
                  <filter
                    id="filter0_f_2877_47719"
                    x="0.608398"
                    y="-238.35"
                    width="654.926"
                    height="1146.53"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                  >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="BackgroundImageFix"
                      result="shape"
                    />
                    <feGaussianBlur
                      stdDeviation="100"
                      result="effect1_foregroundBlur_2877_47719"
                    />
                  </filter>
                </defs>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="592"
                height="500"
                viewBox="0 0 592 500"
                fill="none"
              >
                <g filter="url(#filter0_f_2877_47720)">
                  <path
                    d="M252.431 -176.286C252.431 -176.286 212.021 -16.9422 241.197 125.782C280.46 317.851 422.092 386.564 455.766 551.289C489.44 716.014 424.742 866.328 424.742 866.328"
                    stroke="#FA1F3C"
                    stroke-width="60"
                  />
                </g>
                <defs>
                  <filter
                    id="filter0_f_2877_47720"
                    x="0.615234"
                    y="-383.648"
                    width="694.863"
                    height="1461.82"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                  >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="BackgroundImageFix"
                      result="shape"
                    />
                    <feGaussianBlur
                      stdDeviation="100"
                      result="effect1_foregroundBlur_2877_47720"
                    />
                  </filter>
                </defs>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="480"
                height="500"
                viewBox="0 0 480 500"
                fill="none"
              >
                <g filter="url(#filter0_f_2877_47721)">
                  <path
                    d="M258.24 -144.231C258.24 -144.231 212.861 -9.19531 237.146 109.606C269.828 269.482 408.248 322.481 436.278 459.596C464.307 596.711 395.101 724.969 395.101 724.969"
                    stroke="#06C07D"
                    stroke-width="60"
                  />
                </g>
                <defs>
                  <filter
                    id="filter0_f_2877_47721"
                    x="-0.00195312"
                    y="-353.774"
                    width="672.963"
                    height="1292.97"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                  >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="BackgroundImageFix"
                      result="shape"
                    />
                    <feGaussianBlur
                      stdDeviation="100"
                      result="effect1_foregroundBlur_2877_47721"
                    />
                  </filter>
                </defs>
              </svg>
            </div>

            <div className="svgs svg-small">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="346"
                height="500"
                viewBox="0 0 346 500"
                fill="none"
              >
                <g opacity="0.6" filter="url(#filter0_f_3049_33410)">
                  <path
                    d="M187.878 152.267C187.878 152.267 162.416 210.554 172.667 260.699C186.461 328.18 255.914 348.296 267.745 406.17C279.576 464.045 242.117 519.867 242.117 519.867"
                    stroke="#0049D7"
                    stroke-width="60"
                  />
                </g>
                <defs>
                  <filter
                    id="filter0_f_3049_33410"
                    x="0.235352"
                    y="0.270508"
                    width="439.821"
                    height="676.298"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                  >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="BackgroundImageFix"
                      result="shape"
                    />
                    <feGaussianBlur
                      stdDeviation="70"
                      result="effect1_foregroundBlur_3049_33410"
                    />
                  </filter>
                </defs>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="304"
                height="500"
                viewBox="0 0 304 500"
                fill="none"
              >
                <g opacity="0.6" filter="url(#filter0_f_3049_33411)">
                  <path
                    d="M181.403 103.486C181.403 103.486 160.709 185.086 175.65 258.175C195.757 356.533 268.286 391.721 285.53 476.076C302.775 560.431 269.643 637.407 269.643 637.407"
                    stroke="#FA1F3C"
                    stroke-width="60"
                  />
                </g>
                <defs>
                  <filter
                    id="filter0_f_3049_33411"
                    x="0.231445"
                    y="-43.877"
                    width="460.272"
                    height="833.128"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                  >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="BackgroundImageFix"
                      result="shape"
                    />
                    <feGaussianBlur
                      stdDeviation="70"
                      result="effect1_foregroundBlur_3049_33411"
                    />
                  </filter>
                </defs>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="247"
                height="500"
                viewBox="0 0 247 500"
                fill="none"
              >
                <g opacity="0.6" filter="url(#filter0_f_3049_33412)">
                  <path
                    d="M184.732 119.903C184.732 119.903 161.494 189.054 173.93 249.892C190.667 331.764 261.551 358.905 275.905 429.121C290.259 499.337 254.818 565.018 254.818 565.018"
                    stroke="#06C07D"
                    stroke-width="60"
                  />
                </g>
                <defs>
                  <filter
                    id="filter0_f_3049_33412"
                    x="0.269531"
                    y="-29.6416"
                    width="449.058"
                    height="748.89"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                  >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="BackgroundImageFix"
                      result="shape"
                    />
                    <feGaussianBlur
                      stdDeviation="70"
                      result="effect1_foregroundBlur_3049_33412"
                    />
                  </filter>
                </defs>
              </svg>
            </div>
          </div>
          <div className="faq-motto">
            <KeyboardDoubleArrowDownIcon />
            <p>Scroll down</p>
            <KeyboardDoubleArrowDownIcon />
          </div>
        </div>
        <FaqList />
        <Footer />
      </div>
    </>
  );
};

export default FaqBanner;
