const seoMetaData = {
  "acharya-nagarjuna-university": {
    "title": "Acharya Nagarjuna University - Distance Learning Work Integrated Courses",
    "description": "Enrol in Acharya Nagarjuna University for quality distance education. Explore various UG and PG degree apprenticeships designed for your career growth.",
    "keywords": "acharya nagarjuna university, acharya nagarjuna university distance education, nagarjuna university distance education, anu distance education, acharya nagarjuna distance education, acharya nagarjuna university ug courses, acharya nagarjuna university work integrated courses, acharya nagarjuna university degree apprenticeships"
}
,
    "aligarh-muslim-university-online": {
      title: "Aligarh Muslim University Online - Work Integrated UG & PG Courses",
      description: "Explore Aligarh Muslim University's online work-integrated degree courses. Enrol in AMU Online Degree Apprenticeship UG & PG programs for a successful career.",
      keywords: "aligarh muslim university, amu online, aligarh muslim university course, amu online courses, aligarh muslim university online courses, amu online degrees, aligarh muslim university pg courses, aligarh muslim university ug courses, aligarh muslim university work integrated courses, aligarh muslim university degree apprenticeships"
    },
    "andhra-university": {
      title: "Andhra University Online - Work Integrated UG & PG Courses",
      description: "Advance your career with Andhra University's work-integrated online courses. Choose from UG and PG degrees, designed for flexible, real-world learning.",
      keywords: "andhra university online, andhra university online courses, andhra university courses online, andhra university pg courses, andhra university online degree, andhra university ug courses, andhra university degree courses, andhra university work integrated courses, andhra university degree apprenticeships"
    },
    "jain-centre-for-distance-and-online-education": {
        title: "Jain University Open & Distance Work Integrated Learning Programs",
        description: "Jain University offers work-integrated distance education programs. Enrol in ODL Jain's online UG/PG apprenticeship courses, which offer industry-focused degrees.",
        keywords: "jain university distance education, jain distance education, jain odl, jain open university, jain university odl, odl jain university, distance education jain university, odl jain, jain university distance learning, jain university distance education courses, odl jain work integrated program"
      },
      "mizoram-university-online": {
        title: "Mizoram University Online - Work Integrated UG & PG Courses",
        description: "Enroll in Mizoram University's work-integrated programs. MZU Online offers UG & PG degrees with apprenticeship courses and industry-driven learning options.",
        keywords: "mizoram university, mizoram university online, mzu online, mizoram university online courses, mizoram university online degree, mizoram university ugc approved, mizoram university courses, mizoram university ug courses, mizoram university work integrated courses, mizoram university degree apprenticeship program"
      },
      "sastra-university": {
        title: "SASTRA University Online - Work Integrated UG & PG Courses",
        description: "SASTRA University offers a variety of online work-integrated degree programs. Explore Online UG and PG programs for practical, hands-on learning experiences.",
        keywords: "sastra university, sastra online, sastra university online, sastra online degree, sastra university online courses, sastra online courses, sastra university courses, sastra university pg courses, sastra university ug courses, sastra university work integrated courses, sastra university degree apprenticeship program"
      },
      "the-northcap-university": {
        title: "Northcap University Online - Work Integrated UG & PG Courses",
        description: "Northcap University Online offers work-integrated UG and PG Degree programs. Discover flexible, career-focused learning paths with a robust curriculum.",
        keywords: "northcap university, northcap university courses, ncu courses, ncu online, ncu online courses, northcap university online, ncu work integrated programs, ncu degree apprenticeships"
      },
      "uttaranchal-university": {
        title: "Uttaranchal University Online - Work Integrated UG & PG Courses",
        description: "Uttaranchal University's work-integrated online degree courses help you balance career and education. Explore the UG and PG programs online and enrol today!",
        keywords: "uttaranchal university, uttaranchal university online, uu online, uttaranchal university online courses, uttaranchal university ugc approved, uttaranchal university online degree, uttaranchal university pg courses, uttaranchal university ug courses, uttaranchal university degree apprenticeships, uttaranchal university work integrated courses"
      }
  };

 

  
  
  export default seoMetaData;