import React from 'react';
import ProgramBanner from './ProgramBanner';
import ProgramSkills from './ProgramSkills';
import CareerProgram from './CareerProgram';
import './style.scss';
import Footer from '../../components/FooterLayout';
function index() {
  return (
    <>
    <div className="integrated-content">
      <ProgramBanner/>
      <ProgramSkills/>
      <CareerProgram/>
      <Footer/>
    </div>
    
    </>
  )
}
export default index