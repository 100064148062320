import React, {useState} from "react";
import BreadCrumbs from "../../commonComponents/BreadCrumbs";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import ButtonComponent from "../../commonComponents/Button";
import { useActiveStep } from "../../utils/ActiveStepContext";
import { Link, useNavigate } from "react-router-dom";
import AlertComponent from "../../commonComponents/AlertComponent"
import { useScroll } from "../../commonComponents/ScrollContext";
import { useLoginState } from "../../utils/LoginStateContext";
import { Helmet } from "react-helmet-async";
const CustomizePathwayPath = () => {
  const navigate = useNavigate();
  const {activeStep, decrementStep} = useActiveStep();
  const [showAlert, setShowAlert] = useState(true);
  const paths = [
    { label: "Home", url: "/" },
    { label: "Personalize-Pathway", url: "/personalize-pathway/" },
  ];
  const { scrollToChoosePathway } = useScroll();
  const { isLoggedIn, userInfo, login, logout } = useLoginState();
  const resumeStep = sessionStorage.getItem("resumestep");
  const handleBack = () => {
    if(resumeStep){
      navigate(-1);
    }
    if(activeStep===0){
      if(resumeStep){
        navigate(-1);
      }
      else{
        navigate("/");

      }
    }
    else if (activeStep===1 && isLoggedIn){
      navigate("/");
      const delayTime = 400;
      const timer = setTimeout(() => {
        scrollToChoosePathway();
      }, delayTime);
      return () => clearTimeout(timer);

    }
    else{
      decrementStep();
    }

  };
  return (
    <div className="customize-pathway">
    <Helmet><link rel="canonical" href={window.location.href}/>
    <script
          dangerouslySetInnerHTML={{
            __html: `
              (function(h,o,t,j,a,r){
                  h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                  h._hjSettings={hjid:5075643,hjsv:6};
                  a=o.getElementsByTagName('head')[0];
                  r=o.createElement('script');r.async=1;
                  r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                  a.appendChild(r);
              })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
            `,
          }}
        /> 
    </Helmet>
      <div className="top-section">
        <div className="pathway-breadcrumb-div">
        <ButtonComponent id={"pathway-back"} className={"back-button"} onClick={handleBack}> <KeyboardBackspaceIcon /> Back </ButtonComponent>
        <BreadCrumbs paths={paths} color="black" />
        </div>
      </div>
      {activeStep===0 && (
        <div className="motive-container">
          <div className="motive">
            <AccessTimeIcon />
            <p>Find your best career pathway in just 2 minutes!</p>
          </div>
        </div>
      )}
    </div>
  );
};
export default CustomizePathwayPath;
