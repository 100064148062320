import React, {useState, useEffect} from 'react'
import ModalComponent from "../../commonComponents/ModalComponent";
import FavoriteBorderIcon from "@mui/icons-material/Favorite";
import CloseIcon from '@mui/icons-material/Close';
import callAPI from "../../utils/apiAction";
import { AssetsAPI } from "../../utils/apiUrl";
export function WorkGoalSecVideo({onClose, video}) {
  return(
    <>
      <div className="video">
        <div className="videodiv">
          <div className="close-icon" onClick={onClose}>
            <CloseIcon color="white"
            />
          </div>
          <video src={video} controls autoPlay> </video>
        </div>    
      </div>
    </>
  )
  };
const WorkVideo = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOpen = () => setIsModalOpen(true);
  const handleClose = () => setIsModalOpen(false);
  const [watchHowResponse, setWatchHowResponse] = useState([]); // Initially set to null
  const [loading, setLoading] = useState(true); // Add loading state
  useEffect(() => {
    const fetchAssetData = async () => {
      try {
        const { data } = await callAPI(
          AssetsAPI,
          "POST",
          { pageName: "Home" },
          {
            Authorization: "Bearer YourAccessToken",
          }
        );
        setWatchHowResponse(data);
        setLoading(false); // Set loading to false after fetching data
      } catch (error) {
        console.log(error);
        setLoading(false); // Set loading to false even if there's an error
      }
    };
    fetchAssetData();
  }, []);
  const [whData, setWhData] = useState([]);
  const [mWhData, setMWhData] = useState([]);
  useEffect(() => {
    if (watchHowResponse && watchHowResponse.length > 0) {
      const data = watchHowResponse[0].sections[3] ;
      if (
        data &&
        data.subSections &&
        data.subSections.length > 0 &&
        data.subSections[0].sectionAssets.length > 0
      ) {
        setWhData(data.subSections[0].sectionAssets);
      }
      const mobdata = watchHowResponse[0].sections[4] ;
      if (
        mobdata &&
        mobdata.subSections &&
        mobdata.subSections.length > 0 &&
        mobdata.subSections[0].sectionAssets.length > 0
      ) {
        setMWhData(mobdata.subSections[0].sectionAssets);
      }
    }
  }, [watchHowResponse]);
  return (
    <div className='work-video'>
        <h2>Watch How Work Empowers Successful Careers</h2>
        <ModalComponent
              buttonContent={
                <>
                  <img src={whData[0] && whData[0].imageUrl} alt="" className="imgWorkGoal" />
                  <img src={mWhData[0] && mWhData[0].imageUrl} alt="" className="imgMobWorkGoal" />
                </>
              }
              buttonId={"home-work-video"}
              style={{ backgroundColour: "white" }}
              children={<WorkGoalSecVideo onClose={handleClose} video={whData[0] && whData[0].videoUrl} />}
              open={isModalOpen}
              setOpen={setIsModalOpen}
              onOpen={handleOpen}
              onClose={handleClose}
            />
    </div>
  )
}

export default WorkVideo;