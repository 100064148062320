import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Popover from '@mui/material/Popover';
import Button from './Button';

function PopoverComponent({anchorEl,handleClick,handleClose, buttonContent, buttonId, popoverContent, anchorOrigin, buttonClassName, popoverClassName }) {

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <Button variant="contained" onClick={handleClick} className={buttonClassName} id={buttonId} color="primary" >
        {buttonContent}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={anchorOrigin}
        className={popoverClassName}
      >
        {popoverContent}
      </Popover>
    </>
  );
}

PopoverComponent.propTypes = {
    popoverContent: PropTypes.node.isRequired,
    anchorOrigin: PropTypes.shape({
        vertical: PropTypes.oneOf(['top', 'center', 'bottom', 'left', 'right']),
        horizontal: PropTypes.oneOf(['top', 'center', 'bottom', 'left', 'right']),
    }),
};

PopoverComponent.defaultProps = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
};

export default PopoverComponent;
