import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Box, LinearProgress, Typography } from "@mui/material";

const LinearProgressWithLabel = ({color, ...props}) => {
  return (
    <Box display="flex" alignItems="center" p={3}>
      <Box width="100%" mr={3}>
        <LinearProgress variant="determinate" color="primary" size="lg"  style={{background: "linear-gradient(90deg, rgba(169, 65, 111, 0.10) 0%, rgba(114, 64, 178, 0.10) 53.5%, rgba(45, 76, 176, 0.10) 100%), rgba(0, 0, 0, 0.05)", width: "100%", color: "red"}} {...props}  />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
};

LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

const LinearProgressComponent = ({ color, onProgressChange }) => {
  const [loading, setLoading] = useState(true);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const simulateProgress = () => {
      setTimeout(() => {
        if(progress === 100){
          onProgressChange();
        }
        else if (progress < 100) {
          const newProgress = progress + 10;
          setProgress(newProgress);
        } 
        else {
          setLoading(false);
        }
      }, 500); 
    };

    if (loading) {
      simulateProgress();
    }
  }, [loading, progress]);

  return loading ? (
    <div style={{width: "100%"}}>
      <LinearProgressWithLabel value={progress} color={color} />
    </div>
  ) : null;
};


export default LinearProgressComponent;
