import React, { useState, useEffect } from "react";
import Rectangle from "../../assets/Rectangle.png";
import Rectangle1 from "../../assets/Rectangle2.png";
import Rectangle2 from "../../assets/Rectangle3.png";
import Rectangle3 from "../../assets/Rectangle4.png";
import Rectangle4 from "../../assets/Rectangle5.png";
import Rectangle5 from "../../assets/Rectangle6.png";
import ButtonComponent from "../../commonComponents/Button";
import { useScroll } from "../../commonComponents/ScrollContext";
import { OurEcoSystemAPI } from "../../utils/apiUrl";
import callAPI from "../../utils/apiAction";
import { Link, useNavigate } from "react-router-dom";
import DummyJob from "../../assets/DummyJob.png";
import DummyJobCorp from "../../assets/DummyJobCorp.png";
import { useIds } from "../../utils/IdsContext";
export const public_private_partnership = [
  { id: "0001", img_src: Rectangle, name: "AMTRON" },
  { id: "0002", img_src: Rectangle1, name: "CSC Academy" },
  { id: "0003", img_src: Rectangle2, name: "Government of Telangana " },
  { id: "0004", img_src: Rectangle3, name: "Andhra Pradesh State Council Of Higher Education " },
  { id: "0005", img_src: Rectangle4, name: "National Skill Development Corporation" },
  // { id: "0006", img_src: Rectangle5 },
];

function Partners() {
  const { publicPrivateRef, universityRef, corporateRef } = useScroll();
  const { universityId, setUniversityId, setCorporateId } = useIds();

  const isMobileView = () => {
    return window.innerWidth <= 640 && window.innerHeight <= 800;
  };
  const navigate = useNavigate();
  const updateURLWithUniversityName = () => {
    const currentPathname = window.location.pathname;
    const newURL = currentPathname.replace(/\/(\d+)$/, `/UniversityDetails`);
    navigate(newURL);
  };

  useEffect(() => {
    updateURLWithUniversityName();
  }, [universityId]);

  const [ecoData, setEcoData] = useState(null);
  const OurEcoSystemURL = OurEcoSystemAPI;
  useEffect(() => {
    const queryParams = {
      _courseId: 0,
    };
    const fetchEcoData = async () => {
      try {
        const { data } = await callAPI(OurEcoSystemURL, "POST", queryParams, {
          Authorization: "Bearer YourAccessToken",
        });
        setEcoData(data);
      } catch (error) {
        console.log(error);
      }
    };
    
    fetchEcoData();
  }, []);
 
  
  const [showAll, setShowAll] = useState(false);
  const handleViewAll = () => {
    setShowAll(true);
  };
  const [showAllUni, setShowAllUni] = useState(false);
  const handleViewAllUni = () => {
    setShowAllUni(true);
  };

  const isCurrentViewMobile = isMobileView();
  const itemsToShow = isCurrentViewMobile ? 4 : 5;

  
  const partnersToShow = showAll
    ? ecoData && ecoData[0]?.corporatePartner
    : ecoData && ecoData[0]?.corporatePartner.slice(0, itemsToShow);
  
    const uniPartnersToShow = showAllUni
    ? ecoData && ecoData[0]?.universities
    : ecoData && ecoData[0]?.universities.slice(0, itemsToShow);
  return (
    <>
      <div className="ecosystem-page">
        <div className="partners">
          <h2>Public Private Partnerships</h2>
          <div className="partners-section" ref={publicPrivateRef}>
            {ecoData !== null && ecoData[0]?.pubPvtPartners
              ? ecoData[0]?.pubPvtPartners.map((item, index) => {
                  let marginTopValue = 0;
                  if (index % 4 === 1 || index % 4 === 3) {
                    marginTopValue = "30px";
                  } else if (index % 4 === 2) {
                    marginTopValue = "60px";
                  } else {
                    marginTopValue = 0;
                  }
                  return (
                    <>
                      <div
                        className="public-partners"
                        style={{ marginTop: marginTopValue }}
                        key={item.id}
                      >
                        <img src={item.img_src} width={80} height={75} />
                      </div>
                    </>
                    
                  );
                })
              : public_private_partnership.slice(0, itemsToShow).map((item, index) => {
                  let marginTopValue = 0;
                  if (!isMobileView()) {
                    if (index % 4 === 1 || index % 4 === 3) {
                      marginTopValue = "30px";
                    } else if (index % 4 === 2) {
                      marginTopValue = "60px";
                    } else {
                      marginTopValue = 0;
                    }
                  } else {
                    if (index % 4 === 1 || index % 4 === 2) {
                      marginTopValue = "30px";
                    } else {
                      marginTopValue = 0;
                    }
                  }
                  return (
                    <div className="public-partners-container-box">
                    <div
                      className="public-partners"
                      style={{ marginTop: marginTopValue }}
                      key={item.id}
                    >
                      <img src={item.img_src} width={80} height={75} />
                    </div>
                    <p>{item.name}</p>
                    
                    </div>

                  );
                })}
          </div>
          {ecoData !== null && ecoData[0]?.pubPvtPartners?.length > 5 && (
            <ButtonComponent
              children="View All"
              className={"view-all-button"}
            />
          )}
          {ecoData === null && public_private_partnership.length > 5 && (
            <ButtonComponent
              children="View All"
              className={"view-all-button"}
            />
          )}{" "}
        </div>

        <div className="universities-partners" ref={universityRef}>
          <h2>University Partnerships</h2>
          <div className="partners-section">
          {ecoData !== null &&
            uniPartnersToShow.map((item, index) => {
                let marginTopValue = 0;
                if (!isMobileView()) {
                  if (index % 5 === 1 || index % 5 === 3) {
                    marginTopValue = "30px";
                  } else if (index % 5 === 2) {
                    marginTopValue = "60px";
                  }
                } else {
                  if (index % 4 === 1 || index % 4 === 2) {
                    marginTopValue = "30px";
                  } else {
                    marginTopValue = 0;
                  }
                }
                const univId = item.id;
                return (
                  <Link
                    id={`ecosys-up-${index}`}
                    to={`/eco-system/university-partnership/${item?.universityName.toLowerCase().replace(/[^\w\s-]/g, '')  // Remove all non-word characters except spaces and hyphens
    .replace(/[\s-]+/g, '-')
                    }/`}
                    onClick={() => setUniversityId(univId)}
                    className="public-partners-container-box"
                  >
                    <div
                      className="public-partners"
                      style={{ marginTop: marginTopValue }}
                      key={item?.id}
                    >
                      <img
                        src={item?.logo}
                        width={80}
                        height={75}
                        alt="partner logo"
                      />
                    </div>
                    <p>{item?.universityName}</p>
                  </Link>
                );
              })}
          </div>
          {ecoData !== null &&
            ecoData[0]?.universities?.length > 5 &&
            !showAllUni && (
              <ButtonComponent
                id={"ecosys-up-viewmore"}
                children="View More"
                className={"view-all-button"}
                onClick={handleViewAllUni}
              />
            )}
        </div>
        <div className="corporate-partners" ref={corporateRef}>
          <h2>Corporate Partnerships</h2>
          <div className="partners-section">
            {ecoData !== null &&
              partnersToShow.map((item, index) => {
                let marginTopValue = 0;
                if (!isMobileView()) {
                  if (index % 5 === 1 || index % 5 === 3) {
                    marginTopValue = "30px";
                  } else if (index % 5 === 2) {
                    marginTopValue = "60px";
                  }
                } else {
                  if (index % 4 === 1 || index % 4 === 2) {
                    marginTopValue = "30px";
                  } else {
                    marginTopValue = 0;
                  }
                }
                const corporateId = item.corporateId;
                const corporateType = item.corporateType;
                return (
                  <Link
                    id={`ecosys-cp-${index}`}
                    to={
                      corporateType === 0
                        ? `/eco-system/curated-partnership/curated-details`
                        : `/eco-system/corporate-partnership/${item?.corporateName.toLowerCase().replace(/[^\w\s-]/g, '')  // Remove all non-word characters except spaces and hyphens
    .replace(/[\s-]+/g, '-')
                    }/`}
                    key={item.id}
                    onClick={() => setCorporateId(corporateId)}
                    className="public-partners-container-box"
                  >
                    <div
                      className="public-partners"
                      style={{ marginTop: marginTopValue }}
                    >
                      <img
                        src={item.logo || DummyJobCorp}
                        width={80}
                        height={75}
                        alt={`Partner ${index + 1}`}
                      />
                    </div>
                    <p>{item?.corporateName}</p>
                  </Link>
                );
              })}
          </div>
          {ecoData !== null &&
            ecoData[0]?.corporatePartner?.length >5 &&
            !showAll && (
              <ButtonComponent
                id={"ecosys-cp-viewmore"}
                children="View More"
                className={"view-all-button"}
                onClick={handleViewAll}
              />
            )}
        </div>
      </div>
    </>
  );
}

export default Partners;
