import { Link, useNavigate, useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import BannerBackground from "../../assets/CourseDetails-Banner.png";
import UniLogo1 from "../../assets/Univ-logo1.png";
import UniLogo2 from "../../assets/Univ-logo2-1.png";
import UniLogo3 from "../../assets/Univ-logo3.png";
import UniLogo4 from "../../assets/Univ-logo4.png";
import UniLogo5 from "../../assets/Univ-logo5.png";
import ButtonComponent from "../../commonComponents/Button";
import BreadCrumbs from "../../commonComponents/BreadCrumbs";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { CoureDetailsAPI } from "../../utils/apiUrl";
import callAPI from "../../utils/apiAction";
import { useLocation } from "react-router-dom";
import html2canvas from "html2canvas";
import PopoverComponent from "../../commonComponents/Popover";
import ShareComponent from "../../components/ShareComponent";
import { useIds } from "../../utils/IdsContext";
import DummyJob from "../../assets/DummyJob.png";
import { Helmet } from "react-helmet-async";
import seoCoursesMetaData from "../../utils/seoCoursesMetaData";
const DescriptionWithReadMore = ({ text, maxWords }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };
  const words = text.split(" ");
  const shortText = words.slice(0, maxWords).join(" ");
  const isTruncated = words.length > maxWords;
  return (
    <div>
      <p>
        {isExpanded || !isTruncated ? text : `${shortText}...`}
        {isTruncated && (
          <button className="readmore" onClick={toggleReadMore}>
            {isExpanded ? "...read less" : "read more"}
          </button>
        )}
      </p>
    </div>
  );
};

const CoursePath = () => {
  const navigate = useNavigate();
  const CourseDetailsURL = CoureDetailsAPI;
  const location = useLocation();
  const { uniName, feeProps, source } = location.state || {};
  const { img } = location.state || {};
  const {unicourName, courseName}= useParams();
  // console.log(unicourName);
  const { universityId, setUniversityId, courseId, universityMap, courseMap, setCourseId } = useIds();
  const univId = universityId;
  const shouldShowIntOpp = () => {
    const pathname = location.pathname;
    return (
      !pathname.includes("/pathway-comparison/") &&
      pathname.includes("/eco-system/")
    );
  };
  const [courseDetailsData, setCourseDetailsData] = useState([]);
  const [courseData, setCourseData] = useState();
  const [feeData, setFeeData] = useState();
  const [jobData, setJobData] = useState();
  const [universityData, setUniversityData] = useState(null);

      const fetchCourseData = async (cid,uid) => {
        try {
          const [CourseDetailsResponse] = await Promise.all([
            callAPI(CourseDetailsURL, "POST", {
              courseId: cid,
              univId: uid,
            }),
          ]);
          const { data } = CourseDetailsResponse;
          setCourseDetailsData(data);

          if (data && data[0] && data[0].courses) {
            setCourseData(data[0].courses[0]);
            setCourseData(data[0].courses[0]);
            setFeeData(data[0].feesDetails);
            setJobData(data[0].jobList);
            // console.log(data[0].universityDetails);
            setUniversityData(data[0].universityDetails);
          } else {
            // console.log("No courses found in the response");
          }
        } catch (e) {
          console.log(e);
        }
      };

      useEffect(()=>{
        // console.log(universityData);
      },[universityData])

  useEffect(() => {
    // console.log("in effect");
    if (Object.keys(universityMap).length > 0) {
      const id = Object.keys(universityMap).find(
        (key) => universityMap[key] === unicourName
      );
      // console.log(id);
      if(id){
        setUniversityId(id);
        sessionStorage.setItem("universityId", universityId);
      }
    }
  }, [unicourName, universityId, setUniversityId, universityMap]);

  useEffect(() => {
    if (Object.keys(courseMap).length > 0) {
      const id = Object.keys(courseMap).find(
        (key) => courseMap[key] === courseName
      );
      if(id){
        setCourseId(id);
        sessionStorage.setItem("courseId", courseId);
      }
    }
  }, [courseName, courseId, setCourseId, courseMap]);
  
  useEffect(()=>{
    if(courseId && universityId){
      fetchCourseData(courseId, universityId);
    }
  },[courseId && universityId, courseId, universityId, unicourName, courseName])

  // React to location.pathname change
  useEffect(() => {
    // This ensures when the location.pathname changes (URL change), it triggers the effect
    if (location.pathname.includes("eco-system")) {
      const params = location.pathname.split("/").filter(Boolean); // Split the path and filter out empty values
      if (params.length === 4) {
        const newUnicourName = params[2];
        const newCourseName = params[3];
        if (newUnicourName !== unicourName || newCourseName !== courseName) {
          navigate(`/eco-system/${newUnicourName}/${newCourseName}`);
        }
      }
    }
  }, [location.pathname, navigate, unicourName, courseName]);


  const paths = [
    { label: "Home", url: "/" },
    { label: "Pathway Comparison", url: "/pathway-comparison/" },
    { label: `${courseData && courseData.courseName}`,url: 
       `/pathway-comparison/${unicourName}/${courseName}/`,
  }
  ];
  if (shouldShowIntOpp()) {
    paths[1] = { label: "Ecosystem", url: "/eco-system/" };
    paths[2] = {
      label: `${universityData && universityData[0].universityName}`,
      url: `/eco-system/university-partnership/${universityData &&universityData[0].universityName.toLowerCase().replace(/[^\w\s-]/g, '')  // Remove all non-word characters except spaces and hyphens
    .replace(/[\s-]+/g, '-')
                    }/`,
    };
    paths[3] = {
      label: `${courseData && courseData.courseName}`,url: 
       `/eco-system/${unicourName}/${courseName}/`
    };
  }

  const [anchorElShare, setAnchorElShare] = useState(null);
  const closePopover = (setter) => {
    setter(null);
  };
  const handleClickShare = (event) => {
    setAnchorElShare(event.currentTarget);
    handleFinalShare();
  };
  const [shareImageUrl, setShareImageUrl] = useState("");
  const handleFinalShare = async () => {
    // Check if the elements exist before proceeding
    const summaryPath = document.querySelector(".summary-path");
    if (!summaryPath) {
      console.error("Summary path element not found");
      return;
    }

    const topSection = document.querySelector(".top-section");
    if (!topSection) {
      console.error("Top section element not found");
      return;
    }

    const button = document.querySelector(".secondary-share-btn");
    if (!button) {
      console.error("Share button not found");
      return;
    }

    // Hide the top section
    topSection.style.display = "none";

    // Proceed with generating the canvas
    html2canvas(summaryPath)
      .then((canvas) => {
        // Show the top section again
        topSection.style.display = "";

        // Get the image data
        const imgData = canvas.toDataURL("image/png");
        setShareImageUrl(imgData); // Directly setting the share image URL
      })
      .catch((err) => {
        // Show the top section again in case of error
        topSection.style.display = "";
        console.error("Error generating share content: ", err);
      });
  };
  const metadata = seoCoursesMetaData[unicourName]?.[courseName] || {
    title: "Digivarsity - Work Integrated Career | Career Guidance & Planning",
    description: "Default description for universities",
    keywords: "default, keywords, for, universities"
  };

  return (
    <>
    <Helmet>
    <title>{metadata.title}</title>
        <meta name="description" content={metadata.description} />
        <meta name="keywords" content={metadata.keywords} />
    <link rel="canonical" href={`https://www.digivarsity.com/eco-system/${unicourName}/${courseName}/`}/>
    <script
          dangerouslySetInnerHTML={{
            __html: `
              (function(h,o,t,j,a,r){
                  h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                  h._hjSettings={hjid:5075643,hjsv:6};
                  a=o.getElementsByTagName('head')[0];
                  r=o.createElement('script');r.async=1;
                  r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                  a.appendChild(r);
              })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
            `,
          }}
        /> 
        {/* Meta Pixel Code */}
        <script>
          {`
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '517004411113375');
            fbq('track', 'PageView');
          `}
        </script>
        <noscript>
          {`<img height="1" width="1" style="display:none"
          src="https://www.facebook.com/tr?id=517004411113375&ev=PageView&noscript=1" />`}
        </noscript>
        {/* Meta Pixel Code */}
    </Helmet>
      <div className="summary-path" id="couseHeader">
        <div className="top-section" id="course-details-breadcrumbs-section">
          <ButtonComponent
            className={"back-button"}
            onClick={() => navigate(-1)}
          >
            {" "}
            <KeyboardBackspaceIcon /> Back{" "}
          </ButtonComponent>
          <BreadCrumbs paths={paths} color="white" />
        </div>
        <div className="article-box">
          <h1>{courseData && courseData.courseName}</h1>
          <p>
            {courseData && (
              <DescriptionWithReadMore
                text={courseData.longDesc}
                maxWords={35}
              />
            )}
          </p>
          <div className="icons-article-box">
            <div className="icon-article-box border-right">
              <img
                src={
                  jobData && jobData.length > 0
                    ? jobData[0].universityLogo
                    : img
                }
                alt="UniLogo"
              />
            </div>

            {uniName !== "Aligarh Muslim University - Online" ? (
              ""
            ) : (
              <>
                <div className="icon-article-box border-right">
                  <img src={UniLogo2} alt="UniLogo" />
                </div>
                <div className="icon-article-box border-right">
                  <img src={UniLogo3} alt="UniLogo" />
                </div>
                <div className="icon-article-box border-right">
                  <img src={UniLogo4} alt="UniLogo" />
                </div>
                <div className="icon-article-box">
                  <img src={UniLogo5} alt="UniLogo" />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default CoursePath;
