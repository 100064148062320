import React from "react";
import { useNavigate } from "react-router-dom";
import ButtonComponent from "../../commonComponents/Button";
import BreadCrumbs from "../../commonComponents/BreadCrumbs";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { Helmet } from "react-helmet-async";
const ConfirmSlotPath = () => {
  const paths = [
    { label: "Home", url: "/" },
    { label: "Personalise Pathway", url: "/personalize-pathway/" },
    { label: "Summary", url: "/pathway-comparison-summary/" },
    { label: "Confirm Slot", url: "/confirm-slot/" },
  ];
  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <link rel="canonical" href={`${window.location.origin}${window.location.pathname}`}/>
        {/* <!-- Meta Pixel Code --> */}
        <script>
          {`
!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '517004411113375');
fbq('track', 'PageView');
fbq('track', 'Complete registration');
`}
        </script>
        <noscript>{`<img height="1" width="1" style="display:none"
src="https://www.facebook.com/tr?id=517004411113375&ev=PageView&noscript=1"
/>`}</noscript>
        {/* <!-- End Meta Pixel Code --> */}
      </Helmet>
      <div className="find-pathway-path">
        <div className="top-section">
          <ButtonComponent
            className={"back-button"}
            onClick={() => navigate(-1)}
          >
            {" "}
            <KeyboardBackspaceIcon /> Back{" "}
          </ButtonComponent>
          <BreadCrumbs paths={paths} color="black" />
        </div>
      </div>
    </>
  );
};
export default ConfirmSlotPath;
