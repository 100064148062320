import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { InitiateAssessmentAPI } from "../../utils/apiUrl";
import callAPI from "../../utils/apiAction";
import CloseIcon from "@mui/icons-material/Close";
import ButtonComponent from "../../commonComponents/Button";
import { useLoginState } from "../../utils/LoginStateContext";
import ModalComponent from "../../commonComponents/ModalComponent";
import { AptitudeTestComponent } from "./AptTest";
import Signin from "../CustomizePathway/Signin";
import { AptStatusAPI } from "../../utils/apiUrl";
const Menu = ({ onClose }) => {
  const AptStatusURL = AptStatusAPI;
  const location = useLocation();
  const navigate = useNavigate();
  const [currentPath, setCurrentPath] = useState(location.pathname);
  const [prevPath, setPrevPath] = useState(location.pathname);
  const { isLoggedIn } = useLoginState();
  const InitiateAssessmentURL = InitiateAssessmentAPI;
  const [aptiData, setAptiData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOpen = () => {
    setIsModalOpen(true);
  };
  const _mobileNo = sessionStorage.getItem("mobileNo");
  // console.log(_mobileNo);
  const handleClose = () => setIsModalOpen(false);
  const queryParams = {
    mobileNo: sessionStorage.getItem("mobileNo"),
  };

  useEffect(() => {
    if (currentPath !== location.pathname) {
      onClose();
      setPrevPath(location.pathname);
    }
    setCurrentPath(location.pathname);
  }, [location]);

  const fetchAptiData = async () => {
    try {
      const { data } = await callAPI(
        InitiateAssessmentURL,
        "POST",
        queryParams,
        {
          Authorization: "Bearer YourAccessToken",
        }
      );
      setAptiData(data);
    } catch (error) {
      console.log(error);
    }
  };
  const handleCloseMenu = () => {
    onClose();
  };

  const [testStatus, setTestStatus] = useState("");
  // const fetchData = async () => {
  //   try {
  //     const [Response] = await Promise.all([
  //       callAPI(AptStatusURL, "POST", {
  //         _mobileNo: _mobileNo,
  //       }),
  //     ]);
  //     const { data } = Response;
  //     // console.log(data[0].status);
  //     if (data && data.length > 0 && data[0].status) {
  //       setTestStatus(data[0].status);
  //       if(testStatus && testStatus === "Completed"){
  //         navigate("/aptitude/");
  //       }
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  // useEffect(()=>{
  //   if(testStatus && testStatus === "Completed"){
  //     navigate("/aptitude/");
  //   }
  // },[testStatus]);

  
  return (
    <>
      <div className="menu">
        <div className="left-section">
          <nav>
            <ul>
              <Link to="/" onClick={handleCloseMenu} id="menu-home-button">
                <li>Home</li>
              </Link>
              <Link to="about/" onClick={handleCloseMenu} id="menu-about-button">
                <li>About Us</li>
              </Link>
              <Link to="work-integrated-programs/" onClick={handleCloseMenu} id="menu-wip-button">
                <li>Work Integrated Programs</li>
              </Link>
              {/* {isLoggedIn === true ? ( */}
                <Link to="/aptitude/give-test/" onClick={handleCloseMenu} id="menu-apttest-button">
                      <li>
                        Aptitude Test
                      </li>
                    </Link>
              {/* ) : (
                
                <ModalComponent
                  buttonContent={
                    <li style={{ textTransform: "capitalize", padding: "0" }}>
                      Aptitude Test
                    </li>
                  }
                  style={{ backgroundColour: "white" }}
                  buttonClassName={"aptitude-test-menu-button"}
                  children={
                    <Signin
                      sourceComponent={"aptitude-test"}
                      onClose={handleClose}
                    />
                  }
                  open={isModalOpen}
                  setOpen={setIsModalOpen}
                  onOpen={handleOpen}
                  onClose={handleClose}
                />
              )} */}
              <Link to="/eco-system/" onClick={handleCloseMenu} id="menu-ecosystem-button">
                <li>Our Ecosystem</li>
              </Link>
              <Link to="/digiverse/" onClick={handleCloseMenu} id="menu-digiverse-button">
                <li>Digiverse</li>
              </Link>
              <Link to="/contact-us/" onClick={handleCloseMenu} id="menu-contact-button">
                <li>Contact Us</li>
              </Link>
              <Link to="/faq/" onClick={handleCloseMenu} id="menu-faqs-button">
                <li>FAQs</li>
              </Link>
            </ul>
          </nav>
        </div>
        <div className="right-section">
          <ButtonComponent onClick={handleCloseMenu} id={"menu-close-button"}>
            {" "}
            <CloseIcon />{" "}
          </ButtonComponent>
        </div>
      </div>
    </>
  );
};

export default Menu;
