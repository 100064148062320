import {getAccessToken } from '../components/getToken';
import encrypt from '../configuration/encrypt';

const callAPI = async (url, method, params = {}) => {
  try {

    const accessToken = await getAccessToken();
    let finalUrl = url;

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`,
    };

    let requestBody = null;
    let data = null;
    let response = null;
    if (method === 'POST') {
      requestBody = JSON.stringify(params);
      const textToEncrypt =  requestBody;
      data = encrypt(textToEncrypt);

      response = await fetch(finalUrl, {
        method: method,
        headers: headers,
        body: JSON.stringify({data}),
      });
    } else {
      const queryParams = new URLSearchParams(params).toString();
      const encryptedData = encrypt(queryParams);
      finalUrl = `${url}?data=${encryptedData}`;

      response = await fetch(finalUrl, {
        method: method,
        headers: headers,
      });
    }

    if (response.status === 401) {
      return callAPI(url, method, params);
    }

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const responseData = await response.json();

    if(responseData.statusCode===401)
    {
      await getAccessToken();
      return callAPI(url, method, params);
    }

    return responseData;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

export default callAPI;