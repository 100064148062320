const schemaData = {
  "aligarh-muslim-university-online": {
    schemaScript:
      `{
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": [
        {
          "@type": "ListItem",
          "position": 1,
          "name": "Home",
          "item": "https://www.digivarsity.com/"
        },
        {
          "@type": "ListItem",
          "position": 2,
          "name": "Our Ecosystem",
          "item": "https://www.digivarsity.com/eco-system/"
        },
        {
          "@type": "ListItem",
          "position": 3,
          "name": "Aligarh Muslim University Online",
          "item": "https://www.digivarsity.com/eco-system/university-partnership/aligarh-muslim-university-online/"
        }
      ]
    }`,
    altText: "Aligarh Muslim University Work Integrated Online Degrees",
    ogTags: {
      url: "https://www.digivarsity.com/eco-system/university-partnership/aligarh-muslim-university-online/",
      title: "Aligarh Muslim University Online - Work Integrated UG & PG Courses",
      description: "Explore Aligarh Muslim University's online work-integrated degree courses. Enrol in AMU Online Degree Apprenticeship UG & PG programs for a successful career.",
      image: "https://d1edxb1fw29brw.cloudfront.net/universityimage/AMU.png"
    }
  },
  "andhra-university": {
    schemaScript:
      `{
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": [
        {
          "@type": "ListItem",
          "position": 1,
          "name": "Home",
          "item": "https://www.digivarsity.com/"
        },
        {
          "@type": "ListItem",
          "position": 2,
          "name": "Our Ecosystem",
          "item": "https://www.digivarsity.com/eco-system/"
        },
        {
          "@type": "ListItem",
          "position": 3,
          "name": "Andhra University Online",
          "item": "https://www.digivarsity.com/eco-system/university-partnership/andhra-university/"
        }
      ]
    } `,
    altText: "Andhra University Online Work Integrated Degree Courses",
    ogTags: {
      url: "https://www.digivarsity.com/eco-system/university-partnership/andhra-university/",
      title: "Andhra University Online - Work Integrated UG & PG Courses",
      description: "Advance your career with Andhra University's work-integrated online courses. Choose from UG and PG degrees, designed for flexible, real-world learning.",
      image: "https://d1edxb1fw29brw.cloudfront.net/universityimage/andhra-university.png"
    }
  },
  "jain-centre-for-distance-and-online-education": {
    schemaScript:
      `{
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": [
        {
          "@type": "ListItem",
          "position": 1,
          "name": "Home",
          "item": "https://www.digivarsity.com/"
        },
        {
          "@type": "ListItem",
          "position": 2,
          "name": "Our Ecosystem",
          "item": "https://www.digivarsity.com/eco-system/"
        },
        {
          "@type": "ListItem",
          "position": 3,
          "name": "Jain University Distance Education",
          "item": "https://www.digivarsity.com/eco-system/university-partnership/jain-centre-for-distance-and-online-education/"
        }
      ]
    }`,
    altText: "Jain University Distance Education Work Integrated Degrees",
    ogTags: {
      url: "https://www.digivarsity.com/eco-system/university-partnership/jain-centre-for-distance-and-online-education/",
      title: "Jain University Open & Distance Work Integrated Learning Programs",
      description: "Jain University offers work-integrated distance education programs. Enrol in ODL Jain's online UG/PG apprenticeship courses, which offer industry-focused degrees.",
      image: "https://d1edxb1fw29brw.cloudfront.net/universityimage/jain-university.png"
    }
  },
  "mizoram-university-online": {
    schemaScript:
      `
      {
                "@context": "https://schema.org","@type": "BreadcrumbList","itemListElement": 
                [{
                        "@type": "ListItem",
                        "position": 1,
                        "name": "Home",
                        "item": "https://www.digivarsity.com/"
                    },
                    {
                        "@type": "ListItem",
                        "position": 2,
                        "name": "Our Ecosystem",
                        "item": "https://www.digivarsity.com/eco-system/"
                    },
                    {
                        "@type": "ListItem",
                        "position": 3,
                        "name": "Mizoram University Online",
                        "item": "https://www.digivarsity.com/eco-system/university-partnership/mizoram-university-online/"
                    }
                ]
    }`,
    altText: "Mizoram University Online Work Integrated Degree Courses",
    ogTags: {
      url: "https://www.digivarsity.com/eco-system/university-partnership/mizoram-university-online/",
      title: "Mizoram University Online - Work Integrated UG & PG Courses",
      description: "Enrol in Mizoram University's work-integrated programs. MZU Online offers UG & PG degrees with apprenticeship courses with industry-driven learning options.",
      image: "https://d1edxb1fw29brw.cloudfront.net/universityimage/MZU.png"
    }
  },
  "uttaranchal-university": {
    schemaScript:
    `{
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": [
      {
        "@type": "ListItem",
        "position": 1,
        "name": "Home",
        "item": "https://www.digivarsity.com/"
      },
      {
        "@type": "ListItem",
        "position": 2,
        "name": "Our Ecosystem",
        "item": "https://www.digivarsity.com/eco-system/"
      },
      {
        "@type": "ListItem",
        "position": 3,
        "name": "Uttaranchal University Online",
        "item": "https://www.digivarsity.com/eco-system/university-partnership/uttaranchal-university/"
      }
    ]
  }
  `,
  altText: "Uttaranchal University Online - Work Integrated Degrees",
  ogTags: {
    url: "https://www.digivarsity.com/eco-system/university-partnership/uttaranchal-university/",
    title: "Uttaranchal University Online - Work Integrated UG & PG Courses",
    description: "Uttaranchal University's work-integrated online degree courses help you balance career and education. Explore the UG and PG programs online and enrol today!",
    image: "https://d1edxb1fw29brw.cloudfront.net/universityimage/uttaranchal-university.png"
  }
  },
  "sastra-university": {
    schemaScript:
    `{
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": [
      {
        "@type": "ListItem",
        "position": 1,
        "name": "Home",
        "item": "https://www.digivarsity.com/"
      },
      {
        "@type": "ListItem",
        "position": 2,
        "name": "Our Ecosystem",
        "item": "https://www.digivarsity.com/eco-system/"
      },
      {
        "@type": "ListItem",
        "position": 3,
        "name": "SASTRA University Online",
        "item": "https://www.digivarsity.com/eco-system/university-partnership/sastra-university/"
      }
    ]
  }
  `,
  altText: "Sastra University Online Work Integrated Courses",
  ogTags: {
    url: "https://www.digivarsity.com/eco-system/university-partnership/sastra-university/",
    title: "Sastra University Online - Work Integrated UG & PG Courses",
    description: "SASTRA University offers a variety of online work-integrated degree programs. Explore Online UG and PG programs for practical, hands-on learning experiences.",
    image: "https://d1edxb1fw29brw.cloudfront.net/universityimage/Sastra%20banner.png"
  }
  },
  "the-northcap-university": {
    schemaScript:
    `{
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": [
      {
        "@type": "ListItem",
        "position": 1,
        "name": "Home",
        "item": "https://www.digivarsity.com/"
      },
      {
        "@type": "ListItem",
        "position": 2,
        "name": "Our Ecosystem",
        "item": "https://www.digivarsity.com/eco-system/"
      },
      {
        "@type": "ListItem",
        "position": 3,
        "name": "Northcap University Online",
        "item": "https://www.digivarsity.com/eco-system/university-partnership/the-northcap-university/"
      }
    ]
  }`,
  altText: "Northcap University Online - Work Integrated Degree Courses",
  ogTags: {
    url: "https://www.digivarsity.com/eco-system/university-partnership/the-northcap-university/",
    title: "Northcap University Online - Work Integrated UG & PG Courses",
    description: "Northcap University Online offers work-integrated UG and PG Degree programs. Discover flexible, career-focused learning paths with a robust curriculum.",
    image: "https://d1edxb1fw29brw.cloudfront.net/universityimage/Northcap%20Banner.png"
  }
  },
  "acharya-nagarjuna-university": {
    schemaScript:
      `{
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement": [
              {
                "@type": "ListItem",
                "position": 1,
                "name": "Home",
                "item": "https://www.digivarsity.com/"
              },
              {
                "@type": "ListItem",
                "position": 2,
                "name": "Our Ecosystem",
                "item": "https://www.digivarsity.com/eco-system/"
              },
              {
                "@type": "ListItem",
                "position": 3,
                "name": "Acharya Nagarjuna University",
                "item": "https://www.digivarsity.com/eco-system/university-partnership/acharya-nagarjuna-university/"
              }
            ]
          }`,
    altText: "Acharya Nagarjuna University Distance Education Work Integrated Degrees",
    ogTags: {
      url: "https://www.digivarsity.com/eco-system/university-partnership/acharya-nagarjuna-university/",
      title: "Acharya Nagarjuna University - Distance Learning Work Integrated Courses",
      description: "Enrol in Acharya Nagarjuna University for quality distance education. Explore various UG and PG degree apprenticeships designed for your career growth.",
      image: "https://d1edxb1fw29brw.cloudfront.net/universityimage/ANU.png"
    }
  }
};

export default schemaData;