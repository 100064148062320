import React, { useState, useEffect } from "react";
import AccordionComponent from "../commonComponents/AccordianComponent";
import { Link, useNavigate } from "react-router-dom";
import callAPI from "../utils/apiAction";
import {
  AptAPI,
  LogoutAPI,
  GetConfirmedPathwayAPI,
  // LogoutAPI,
  StudentStatusAPI,
} from "../utils/apiUrl";
import { useLoginState } from "../utils/LoginStateContext";

const AfterSigninProfile = ({ onClose }) => {
  const username = sessionStorage.getItem("name");
  const { isLoggedIn, userInfo, login, logout } = useLoginState();
  const navigate = useNavigate();
  const GetConfirmedPathwayURL = GetConfirmedPathwayAPI;
  const StudentStatusURL = StudentStatusAPI;
  const LogoutURL = LogoutAPI;
  const [getConfirmJobData, setGetConfirmJobData] = useState(null);
  const [pathUni, setPathUni] = useState(null);
  const [pathCourse, setPathCourse] = useState(null);
  const [isReportAvailable, setIsReportAvailable] = useState(false);
  const AptURL = AptAPI;
const ClearSessionURL = LogoutAPI;
  const clearSession = async() => {
      try{
        const { data } = await callAPI(
          ClearSessionURL,
          "POST",
          {},
          {
            Authorization: "Bearer YourAccessToken",
          }
        );
        // console.log("Login Info: ", data);

      }catch(error){

      }
    }
  const handleLogout = () => {
    // clearSession();
    logout();
  }
  useEffect(() => {
    const queryParams = {
      mobileNo: sessionStorage.getItem("mobileNo"),
    };

    const fetchConfirmJobsData = async () => {
      try {
        const { data } = await callAPI(
          GetConfirmedPathwayURL,
          "POST",
          queryParams,
          {
            Authorization: "Bearer YourAccessToken",
          }
        );
        if (data.length > 3) {
          setGetConfirmJobData(data.slice(0, 3));
        } else {
          setGetConfirmJobData(data);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchConfirmJobsData();
  }, []);
  const [downloadLink, setDownloadLink] = useState(null);
  const [linkLoading, setLinkLoading] = useState(true);

  useEffect(() => {
    const queryParams = {
      mobileNo: sessionStorage.getItem("mobileNo"),
    };

    const fetchReportStatus = async () => {
      try {
        setLinkLoading(true);
        const { data, reportAvailable } = await callAPI(
          AptURL,
          "POST",
          queryParams,
          {
            Authorization: "Bearer YourAccessToken",
          }
        );
        setIsReportAvailable(reportAvailable);
        if (data && data.reports[0]?.report2) {
          setDownloadLink(data.reports[0].report2);
        }
      } catch (e) {
        console.log(e);
      } finally {
        setLinkLoading(false);
      }
    };

    fetchReportStatus();
  }, []);
  const [status, setStatus] = useState(null);

  useEffect(() => {
    const queryParams = {
      mobileNo: sessionStorage.getItem("mobileNo"),
    };
    const fetchStudentStatus = async () => {
      try {
        const { data } = await callAPI(StudentStatusURL, "POST", queryParams, {
          Authorization: "Bearer YourAccessToken",
        });

        setStatus(data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchStudentStatus();
  }, []);

  useEffect(() => {
    if (status && status.universityName && status.courseName) {
      // console.log("University Name:", status.universityName);
      // console.log("Course Name:", status.courseName);
      setPathUni(status.universityName);
      setPathCourse(status.courseName);
    }
  }, [status]);

  const handleDecisionRoute = () => {
    if (status && status === true) {
      navigate("/my-pathway/");
    } else {
      navigate("/pathway-comparison/");
    }
  };

  const handleMyPathway = async () => {
    const queryParams = {
      mobileNo: sessionStorage.getItem("mobileNo"),
    };
    try {
      const { data } = await callAPI(
        GetConfirmedPathwayURL,
        "POST",
        queryParams,
        {
          Authorization: "Bearer YourAccessToken",
        }
      );
      if (data.length > 3) {
        setGetConfirmJobData(data.slice(0, 3));
        // navigate("/pathway-comparison/")
      } else {
        setGetConfirmJobData(data);
        // navigate("/pathway-comparison/")
      }
      onClose();
    } catch (error) {
      console.log(error);
    }
  };

  // const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(
      status && status.paymentStatus === true
        ? `/my-pathway/`
        : `/pathway-comparison/`,
      {
        state: {
          uniname: status?.universityName || "Hi", // Fallback if universityName is not available
          coursename: status?.courseName || "Hi", // Fallback if courseName is not available
        },
      }
    );
  };

  return (
    <div className="after-signin-profile">
      {isReportAvailable ? (
        <ul className="after-signin-list">
          <h2>Hi {username}!</h2>

          <li
            id={"signin-mypathway"}
            style={{ color: "black", cursor: "pointer" }}
            onClick={handleNavigate}
          >
            My Pathway
          </li>
          {/* <li style={{ color: "black", cursor: "pointer" }} onClick={()=>{window.open(downloadLink, '_self');}} >Download Aptitude Report</li> */}
          <li
            id={"signin-download"}
            style={{ color: "black", cursor: "pointer" }}
            onClick={() => {
              window.open(downloadLink, "_self");
            }}
            disabled={linkLoading}
          >
            {!isReportAvailable
              ? "Download Aptitude Report"
              : "Download Aptitude Report"}
          </li>

          <AccordionComponent
            panelId="panel1"
            heading="Support"
            headingClassname="accordian-title"
            content={
              <ul className="accordian-list">
                <Link
                  id={"signin-chatwithus"}
                  to="https://api.whatsapp.com/send/?phone=917204677888&text&type=phone_number&app_absent=0"
                  target="_blank"
                  style={{ color: "#555555" }}
                  onClick={() => onClose()}
                >
                  <li>Chat with us</li>
                </Link>
                <Link
                  id="signin-connectwith"
                  to="/confirm-slot"
                  style={{ color: "#555555" }}
                  onClick={() => onClose()}
                >
                  <li>Connect with counsellor</li>
                </Link>
              </ul>
            }
            className={"signin-profile-accordian"}
          />
          <Link id="signin-logout" onClick={handleLogout} style={{ color: "black" }}>
            <li>Logout</li>
          </Link>
        </ul>
      ) : (
        <ul className="after-signin-list">
          <h2>Hi {username}!</h2>

          <li
            style={{ color: "black", cursor: "pointer" }}
            onClick={handleNavigate}
          >
            My Pathway
          </li>
          {/* <li style={{ color: "black", cursor: "pointer" }} onClick={()=>{window.open(downloadLink, '_self');}} >Download Aptitude Report</li> */}

          <AccordionComponent
            panelId="panel1"
            heading="Support"
            headingClassname="accordian-title"
            content={
              <ul className="accordian-list">
                <Link
                  to="https://api.whatsapp.com/send/?phone=917204677888&text&type=phone_number&app_absent=0"
                  target="_blank"
                  style={{ color: "#555555" }}
                  onClick={() => onClose()}
                >
                  <li>Chat with us</li>
                </Link>
                <Link
                  to="/confirm-slot"
                  style={{ color: "#555555" }}
                  onClick={() => onClose()}
                >
                  <li>Connect with counsellor</li>
                </Link>
              </ul>
            }
            className={"signin-profile-accordian"}
          />
          <Link onClick={handleLogout} style={{ color: "black" }}>
            <li>Logout</li>
          </Link>
        </ul>
      )}
    </div>
  );
};

export default AfterSigninProfile;
