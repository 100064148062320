import React from "react";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import Button from "../../commonComponents/Button";
import BreadCrumbs from "../../commonComponents/BreadCrumbs";
import { useScroll } from "../../commonComponents/ScrollContext";
import EcoBanner from "../../assets/ecosystemBanner.png";
import ButtonComponent from "../../commonComponents/Button";
import { Helmet } from "react-helmet-async";
const paths = [
  { label: "Home", url: "/" },
  { label: "Our Ecosystem", url: "/eco-system/" },
];
const EcosystemClickableCard = ({ id, title, onClick }) => {
  return (
    <ButtonComponent id={id} onClick={onClick} className="ecoPatners-button">
      <div className="ecoPatners">
        {" "}
        <span className="ecoPatners-title">{title}</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <mask
            id="mask0_2877_47316"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="24"
            height="24"
          >
            <rect
              x="24"
              width="24"
              height="24"
              transform="rotate(90 24 0)"
              fill="#D9D9D9"
            />
          </mask>
          <g mask="url(#mask0_2877_47316)">
            <path
              d="M6.35582 6.2942L7.40002 5.25L17.25 15.0904L17.25 6.14422L18.75 6.14422L18.75 17.6442L7.25005 17.6442L7.25005 16.1442L16.1962 16.1442L6.35582 6.2942Z"
              fill="white"
            />
          </g>
        </svg>
      </div>
    </ButtonComponent>
  );
};
const EcoSystemBanner = () => {
  const {
    scrollToPublicPrivatePartners,
    scrollToUniversityPartners,
    scrollToCorporatePartners,
  } = useScroll();
  return (
    <>
      <Helmet>
        <meta property="og:site_name" content="Digivarsity" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.digivarsity.com/eco-system/" />
        <meta property="og:title" content="Explore Work Integrated Online Universities & Corporate Internships" />
        <meta property="og:description" content="Find the best work integrated online universities in India offering affordable education. Discover corporate internships to enhance your career prospects. Join now!" />
        <meta property="og:image" content="https://www.digivarsity.com/static/media/ecosystemBanner.c974c9b675900397a04e.png" />
        <meta property="og:image:width" content="674" />
        <meta property="og:image:height" content="380" />

      <title>Explore Work Integrated Online Universities & Corporate Internships</title>
        <meta
          name="description"
          content="Find the best work integrated online universities in India offering affordable education. Discover corporate internships to enhance your career prospects."
        />
        <meta name="keywords" content="online universities, corporate internships, online uni, online universities in India, best online universities, affordable education, best companies for apprenticeships, companies available for internship, colleges offering online degrees, corporate internships in India" />
     
      <link rel="canonical" href={window.location.href}/>

      <script
          dangerouslySetInnerHTML={{
            __html: `
              (function(h,o,t,j,a,r){
                  h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                  h._hjSettings={hjid:5075643,hjsv:6};
                  a=o.getElementsByTagName('head')[0];
                  r=o.createElement('script');r.async=1;
                  r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                  a.appendChild(r);
              })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
            `,
          }}
        /> 
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement": [
              {
                "@type": "ListItem",
                "position": 1,
                "name": "Home",
                "item": "https://www.digivarsity.com/"
              },
              {
                "@type": "ListItem",
                "position": 2,
                "name": "Our Ecosystem",
                "item": "https://www.digivarsity.com/eco-system/"
              }
            ]
          })}
        </script>

        {/* Meta Pixel Code */}
        <script>
          {`
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '517004411113375');
            fbq('track', 'PageView');
          `}
        </script>
        <noscript>
          {`<img height="1" width="1" style="display:none"
          src="https://www.facebook.com/tr?id=517004411113375&ev=PageView&noscript=1" />`}
        </noscript>
        {/* Meta Pixel Code */}
      </Helmet>
      <div className="eco-banner">
        <div className="ecosystem-path">
          <BreadCrumbs paths={paths} />
        </div>
        <div className="eco-left-section">
          <div className="eco-title">
            <div className="title-left-section">
              <h1>Our Ecosystem</h1>
              <EcosystemClickableCard
                id="ecosys-ppp"
                title="Public Private Partnerships"
                onClick={scrollToPublicPrivatePartners}
              />
              <EcosystemClickableCard
                id="ecosys-cp"
                title="Corporate Partnerships"
                onClick={scrollToCorporatePartners}
              />
              <EcosystemClickableCard
                id="ecosys-up"
                title="University Partnerships"
                onClick={scrollToUniversityPartners}
              />
            </div>
            <div>
              <img
                src={EcoBanner}
                alt="Our Ecosystem - Work Integrated Online Universities & Corporate Internships"
                className="eco-right-section"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default EcoSystemBanner;
