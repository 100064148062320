import React from "react";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import Button from "../../commonComponents/Button";
import BreadCrumbs from "../../commonComponents/BreadCrumbs";
import { useScroll } from "../../commonComponents/ScrollContext";
import "./style.scss";
import { Helmet } from "react-helmet-async";
const paths = [
  { label: "Home", url: "/" },
  { label: "Work Integrated Programs", url: "/work-integrated-programs/" },
];
const ProgramBanner = () => {
  const { scrollToFooter } = useScroll();
  return (
    <>
      <Helmet>
        <title>
          Work Integrated Learning Programs - Online Degree Apprenticeships
        </title>
        <meta
          property="og:title"
          content="Work Integrated Learning Programs - Online Degree Apprenticeships"
        />
        <meta property="og:site_name" content="Digivarsity" />
        <meta
          property="og:url"
          content="https://www.digivarsity.com/work-integrated-programs/"
        />
        <meta
          property="og:description"
          content="Explore work integrated learning programs (WILP) at Digivarsity. Enroll in our online degree apprenticeships and achieve career success with our dynamic blended learning."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://www.digivarsity.com/static/media/career1.e4d1f2b9d76502e7cc47.png"
        />
        <meta property="og:image:width" content="566" />
        <meta property="og:image:height" content="380" />
        <meta
          name="keywords"
          content="
  work integrated learning program, 
  degree apprenticeships, 
  career oriented courses, 
  work integrated learning, 
  degree based on work experience, 
  wilp program, 
  degree with apprenticeships, 
  wilp courses, 
  online degrees
"
        />

        <meta
          name="description"
          content="Explore work integrated learning programs (WILP) at Digivarsity. Enroll in our online degree apprenticeships and achieve career success with our dynamic blended learning."
        ></meta>
        <link rel="canonical" href={`${window.location.origin}${window.location.pathname}`}/>
        <script
          dangerouslySetInnerHTML={{
            __html: `
              (function(h,o,t,j,a,r){
                  h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                  h._hjSettings={hjid:5075643,hjsv:6};
                  a=o.getElementsByTagName('head')[0];
                  r=o.createElement('script');r.async=1;
                  r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                  a.appendChild(r);
              })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
            `,
          }}
        /> 
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: 1,
                name: "Home",
                item: "https://www.digivarsity.com/",
              },
              {
                "@type": "ListItem",
                position: 2,
                name: "Work Integrated Programs",
                item: "https://www.digivarsity.com/work-integrated-programs/",
              },
            ],
          })}
        </script>
        {/* Meta Pixel Code */}
        <script>
          {`
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '517004411113375');
            fbq('track', 'PageView');
          `}
        </script>
        <noscript>
          {`<img height="1" width="1" style="display:none"
          src="https://www.facebook.com/tr?id=517004411113375&ev=PageView&noscript=1" />`}
        </noscript>
        {/* Meta Pixel Code */}
      </Helmet>
      <div className="program-banner">
        <div className="program-path">
          <BreadCrumbs paths={paths} />
        </div>
        <div className="left-section">
          <div className="title">
            <h1>
              <span>Work</span> Integrated Degrees
            </h1>
            <br />
            <h2>Making you career-ready</h2>
            <div className="program-motto">
              <KeyboardDoubleArrowDownIcon />
              <Button id={"workintegrated-scrolldown"} className={"scrollDown"} onClick={scrollToFooter}>
                Scroll down
              </Button>
              <KeyboardDoubleArrowDownIcon />
            </div>
          </div>
        </div>
      </div>
      <div class="understanding-prog-container">
        <div className="understanding-prog">
          <h2>Understanding Work Integrated Degree Programs</h2>
          <p>
            <span>Work Integrated Degree Programs</span> are the ultimate fusion
            of academic excellence and real-world experience! <br /> <br /> Say
            hello to hands-on learning that prepares you for success in today's
            competitive job market. Imagine diving headfirst into real workplace
            environments while earning your degree - that's the power of Work
            Integrated Degree Programs. These innovative initiatives seamlessly
            blend classroom instruction with practical work experience,
            equipping you with the skills, critical thinking abilities, and
            industry-specific knowledge needed to thrive in your chosen field.{" "}
            <br /> <br /> It's not just education; it's preparation for a
            successful career.
          </p>
        </div>
      </div>
    </>
  );
};
export default ProgramBanner;
