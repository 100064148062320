import React from 'react'
import CoursePath from './CoursePath'
import './style.scss'
import CourseInfo from './CourseInfo'
const index = () => {
  return (
    <div className='course-download-header course-previewContainer'>
        <CoursePath />
        <CourseInfo/>
    </div>
  )
}
export default index