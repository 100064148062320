import React from 'react'
import UniversityPath from './UniversityPath'
import AboutUniversity from './AboutUniversity'
import './style.scss'
function index() {
  return (
    <>
      <UniversityPath />
      <AboutUniversity />  
    </>
  )
}
export default index