import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { GetSlotsAvailableAPI, BookSlotsAPI } from "../../utils/apiUrl";
import callAPI from "../../utils/apiAction";
import ButtonComponent from "../../commonComponents/Button";
import AlertComponent from "../../commonComponents/AlertComponent";
const DateComponent = ({ id, day, month, weekday, selected, onSelect }) => {
  return (
    <>
      <ButtonComponent
        className={`content-button ${selected ? "selected" : ""}`}
        onClick={() => onSelect(id)}
      >
        <h4>{weekday}</h4>
        <h2>{day}</h2>
        <h4>{month}</h4>
      </ButtonComponent>
    </>
  );
};
const SlotComponent = ({
  id,
  time,
  meridiemType,
  availability,
  selected,
  onSelect,
}) => {
  const isSlotAvailable = availability === "Available" ? true : false;
  return (
    <>
      <ButtonComponent
        className={`content-button ${selected ? "selected" : ""}`}
        onClick={() => onSelect(id)}
        disabled={!isSlotAvailable}
      >
        <h3>
          {time} {meridiemType}
        </h3>
      </ButtonComponent>
    </>
  );
};
const BookSlot = () => {
  const [getSlotsAvailableData, setGetSlotsAvailableData] = useState(null);
  const [bookSlotsData, setBookSlotsData] = useState(null);
  const GetSlotsAvailableURL = GetSlotsAvailableAPI;
  const BookSlotsURL = BookSlotsAPI;
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  const dayAfterTomorrow = new Date(tomorrow);
  dayAfterTomorrow.setDate(dayAfterTomorrow.getDate() + 1);
  const userName = sessionStorage.getItem("name");
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [checkboxSelected, setCheckboxSelected] = useState(true);
  const [showConfirmedMessage, setShowConfirmedMessage] = useState(false);
  const formatDate = (date) => {
    const d = new Date(date);
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  };
  const getWeekdayName = (dayNumber) => {
    const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"];
    return daysOfWeek[dayNumber];
  };
  const getMonthName = (monthNumber) => {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return monthNames[monthNumber];
  };
  const getDayOfMonth = (day) => {
    return day.toString().padStart(2, "0");
  };
  const getDayOfMonthSuffix = (day) => {
    const suffixes = ["th", "st", "nd", "rd"];
    const lastDigit = day % 10;
    const lastTwoDigits = day % 100;
    if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
      return `${day}th`;
    }
    switch (lastDigit) {
      case 1:
        return `${day}st`;
      case 2:
        return `${day}nd`;
      case 3:
        return `${day}rd`;
      default:
        return `${day}th`;
    }
  };
  const formattedDates = [
    formatDate(today),
    formatDate(tomorrow),
    formatDate(dayAfterTomorrow),
  ];
  const [selectedDate, setSelectedDate] = useState(formatDate(today));

  // Get Slots API
  const queryParams = {
    date: selectedDate,
    mobileNo: sessionStorage.getItem("mobileNo"),
  };
  const fetchGetSlotData = async () => {
    try {
      const { data } = await callAPI(
        GetSlotsAvailableURL,
        "POST",
        queryParams,
        {
          Authorization: "Bearer YourAccessToken",
        }
      );
      setGetSlotsAvailableData(data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchGetSlotData();
  }, [selectedDate]);
  // useEffect(() => {
  //   console.log(getSlotsAvailableData);
  //   // console.log(getSlotsAvailableData[0].bookedStats[0].bookedStatus)
  // }, [getSlotsAvailableData]);

  const [showAlert, setShowAlert] = useState(false);
  const fetchBookSlotData = async () => {
    // Book Slots
    if (!selectedSlot) {
      setShowAlert(true);
      return;
    }
    const queryParams = {
      mobileNo: sessionStorage.getItem("mobileNo"),
      date: selectedDate,
      slotId: selectedSlot?.slotId,
    };
    try {
      const { statusCode } = await callAPI(BookSlotsURL, "POST", queryParams, {
        Authorization: "Bearer YourAccessToken",
      });
      if (statusCode === 200) {
        setBookSlotsData(statusCode);
        setShowConfirmedMessage(true);
        fetchGetSlotData();
        window.scroll(0,0);
      }
    } catch (error) {
      console.log(error);
    }
  };
 
 
  return (
    <>
      <div>
        {showConfirmedMessage ? (
          <div className="confirm-message-section">
            <h1>
              Thank you <span>{userName}!</span>
              <br></br>
              Our advisor will call you on {selectedDate} at{" "}
              {selectedSlot?.slotTime} {selectedSlot?.meridiemType}.
            </h1>
            <Link to="/">
              <ButtonComponent
                children="Go to Home Page"
                className={"home-page-button"}
                id={"confirmslot-gotohomepage"}
              />
            </Link>
          </div>
        ) : getSlotsAvailableData !== null &&
          getSlotsAvailableData[0]?.bookedStats[0]?.bookedStatus !== 0 ? (
          <div className="confirm-message-already-section">
            <h1>
              Thank you <span>{userName}!</span>
              <br></br>
              <div className="short-message-section">
                {getSlotsAvailableData !== null &&
                getSlotsAvailableData[0]?.bookedStats[0]?.bookedStatus !== 0
                  ? `Our Advisor will call you on ${getDayOfMonthSuffix(
                      new Date(
                        getSlotsAvailableData[0]?.bookedStats[0]?.date
                      ).getDate()
                    )} ${new Date(
                      getSlotsAvailableData[0]?.bookedStats[0]?.date
                    ).toLocaleDateString("en-US", {
                      month: "long",
                    })}, ${new Date(
                      getSlotsAvailableData[0]?.bookedStats[0]?.date
                    ).getFullYear()} at   ${
                      getSlotsAvailableData[0]?.bookedStats[0]?.slotTime
                    } ${
                      getSlotsAvailableData[0]?.bookedStats[0]?.meridiemType
                    }. In case of any help, please reach us at 720 467 7888 (Mon - Sat 9.00 AM to 8.00 PM)`
                  : null}
              </div>
            </h1>
            <Link to="/">
              <ButtonComponent
                id={"confirmslot-gohomepage"}
                children="Go to Home Page"
                className={"home-page-button"}
              />
            </Link>
          </div>
        ) : (
          <div className="book-slot">
            <div className="date-section">
              <AlertComponent
                open={showAlert}
                onClose={() => setShowAlert(false)}
                message="Please select a time slot!"
                severity="error"
                autoHideDuration={6000}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                color="red"
              />
              <h1 className="heading">
                {getSlotsAvailableData !== null &&
                getSlotsAvailableData[0]?.bookedStats[0]?.bookedStatus !== 0
                  ? `Your slot is already booked for ${getDayOfMonthSuffix(
                      new Date(
                        getSlotsAvailableData[0]?.bookedStats[0]?.date
                      ).getDate()
                    )} ${new Date(
                      getSlotsAvailableData[0]?.bookedStats[0]?.date
                    ).toLocaleDateString("en-US", {
                      month: "long",
                    })}, ${new Date(
                      getSlotsAvailableData[0]?.bookedStats[0]?.date
                    ).getFullYear()} at   ${
                      getSlotsAvailableData[0]?.bookedStats[0]?.slotTime
                    } ${getSlotsAvailableData[0]?.bookedStats[0]?.meridiemType}`
                  : `Hi ${userName}, Choose the best time to reach you`}{" "}
              </h1>
              <h4 className="sub-heading">Select a date</h4>
              <div className="date-container" id="confirmslot-selectdate">
                {formattedDates.map((item) => {
                  return (
                    <DateComponent
                      weekday={getWeekdayName(new Date(item).getDay())}
                      day={getDayOfMonth(parseInt(item.split("-")[2]))}
                      month={getMonthName(new Date(item).getMonth())}
                      selected={selectedDate === item}
                      onSelect={() => setSelectedDate(item)}
                    />
                  );
                })}
              </div>
              <h4 className="sub-heading">
                Select a time for{" "}
                {getWeekdayName(new Date(selectedDate).getDay())}{" "}
                {getDayOfMonthSuffix(parseInt(selectedDate.split("-")[2]))}{" "}
                {getMonthName(new Date(selectedDate).getMonth())}
              </h4>
            </div>
            <div className="slots-section">
              <div className="slots-container" id="confirmSlot-selecttime">
                {getSlotsAvailableData !== null &&
                  getSlotsAvailableData[0]?.availableSlots.map((item) => {
                    return (
                      <SlotComponent
                        id={item?.slotId}
                        meridiemType={item?.meridiemType}
                        availability={item?.availability}
                        time={item?.slotTime}
                        selected={selectedSlot === item}
                        onSelect={() => setSelectedSlot(item)}
                      />
                    );
                  })}
              </div>
            </div>
            <div className="confirmation-section">
              <div className="checkbox-section">
                <input
                  type="checkbox"
                  id="confirmSlot-checkbox"
                  checked={checkboxSelected}
                  onChange={(e) => e.preventDefault()}
                  readonly
                />
                <h5>I would like to receive call back from a career advisor</h5>
              </div>
              <h5 className="privacy-note">
                Your information is safe with us. We prioritize your safety.{" "}
              </h5>
              <ButtonComponent
                id={"button-confirmslot"}
                children={"Confirm Slot"}
                className={
                  getSlotsAvailableData !== null &&
                  getSlotsAvailableData[0]?.bookedStats[0]?.bookedStatus !== 0
                    ? "confirm-slot-button-disabled"
                    : "confirm-slot-button"
                }
                onClick={fetchBookSlotData}
                disabled={
                  getSlotsAvailableData !== null &&
                  getSlotsAvailableData[0]?.bookedStats[0]?.bookedStatus !== 0
                }
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default BookSlot;
