const seoCoursesMetaData = {
    
    "jain-centre-for-distance-and-online-education": {
      "master-of-commerce-with-apprenticeship": {
        title: "Jain University M.Com - Work Integrated Distance Learning",
        description: "Discover ODL Jain University’s MCom Apprenticeship program, combining distance learning with work experience to enhance your professional skills & qualifications.",
        keywords: "jain university mcom, jain mcom distance learning, jain mcom apprenticeship, jain work integrated mcom, jain mcom apprenticeship degree, jain distance mcom course, jain mcom"
      },
      "master-of-arts-economics-with-apprenticeship": {
        title: "Jain University M.A. Economics - Work Integrated Distance Learning",
        description: "Explore Jain University’s MA Economics with Apprenticeship, a work-integrated distance learning program designed to provide both academic and practical experience.",
        keywords: "jain university ma economics, ma economics jain university, jain university ma economics fees, jain ma economics distance learning, jain ma economics apprenticeship, jain work integrated ma economics, jain ma economics distance course"
      },
      "master-of-science-psychology-with-apprenticeship": {
        title: "Jain University M.Sc Psychology - Work Integrated Distance Learning",
        description: "Jain's Work Integrated MSc Psychology offers flexible distance education with an apprenticeship, blending academic knowledge with practical psychological skills.",
        keywords: "jain university msc psychology, msc psychology jain university, jain university msc psychology distance education, msc psychology in jain university, jain msc psychology, jain university msc psychology syllabus, jain university msc psychology fee structure, jain university msc psychology distance education, jain msc psychology apprenticeship, jain work integrated msc psychology"
      },
      "bachelor-of-arts-with-apprenticeship": {
        title: "Jain University B.A. - Work Integrated Distance Learning",
        description: "Explore Jain University’s BA with Apprenticeship, a work-integrated distance learning program designed for academic excellence and practical work experience.",
        keywords: "jain university ba, jain ba, ba in jain university, jain ba apprenticeship, jain work integrated ba, jain university ba distance education, jain university ba syllabus, jain university ba fee structure"
      },
      "bachelor-of-commerce-with-apprenticeship": {
        title: "Jain University B.Com - Work Integrated Distance Learning",
        description: "Earn your BCom Degree from Jain University with a flexible distance learning and work integrated option, combining academic theory with practical work experience.",
        keywords: "jain university bcom, jain bcom distance learning, jain bcom apprenticeship, jain work integrated bcom, jain bcom apprenticeship degree, jain distance bcom course, jain bcom"
      },
      "bachelor-of-computer-applications-with-apprenticeship": {
        title: "Jain University BCA - Work Integrated Distance Learning",
        description: "Study BCA at Jain University through distance education with an apprenticeship. Find out about fees, eligibility, and the benefits of BCA work-integrated learning.",
        keywords: "jain university bca, jain university bca fees, jain bca, bca jain university, jain bca fees, jain university bca eligibility, jain university bca syllabus, jain bca apprenticeship, jain work integrated bca, jain bca apprenticeship degree, jain bca distance education, jain university bca distance education"
      },
      "bachelor-of-business-administration": {
        title: "Jain University BBA - Work Integrated Distance Learning",
        description: "Jain University BBA offers a unique combination of distance education & apprenticeship. Explore fees, eligibility, and syllabus in our Work Integrated BBA Program.",
        keywords: "jain university bba, jain university bba fees, jain bba, bba jain university, jain bba fees, jain university bba eligibility, jain university bba syllabus, jain bba apprenticeship, jain work integrated bba, jain bba apprenticeship degree, jain bba distance education, jain university bba distance education"
      },
      "master-of-business-administration": {
        title: "Jain University MBA - Work Integrated Distance Learning",
        description: "Jain University Work Integrated MBA offers distance learning combined with an apprenticeship. Explore syllabus, fees, & eligibility for a career-focused MBA program.",
        keywords: "jain university mba, jain university mba fees, jain mba, mba jain university, jain mba fees, jain university mba eligibility, jain university mba syllabus, jain mba apprenticeship, jain work integrated mba, jain mba apprenticeship degree, jain distance education mba, jain university mba distance education"
      }
    }
  };
  
  export default seoCoursesMetaData;
  