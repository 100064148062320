import React from 'react'
import { ReportCard } from '../Digiverse/RelatedReports';
import { Link as ReactRouterLink, useParams } from 'react-router-dom';
import { reports } from '../Digiverse/RelatedReports';

const RelatedReports = () => {
  const { reportId } = useParams();
  return (
    <>
    <div className='more-reports-heading'>
      <h1>Related Reports</h1>
    </div>
    <div className="more-reports">
      {reports.filter(report => report.route !== reportId).map((item, index) => {
        return (
          <div >
            <ReactRouterLink to={"/reports/"+item.route} style={{textDecoration: "none"}}>
            <ReportCard
              id={item.id}
              image_src={item.image_src}
              time={item.time}
              title={item.title}
              className={'reportcard'}
            />
            </ReactRouterLink>
          </div>
        );
      })}
    </div>
    </>
  )
}

export default RelatedReports