import React from 'react'
import SummaryCard from './SummaryCard'
import SummaryPath from './SummaryPath'
import './style.scss'

const index = () => {
  return (
    <div>
        <SummaryPath />
        <SummaryCard />
    </div>
    
  )
}

export default index