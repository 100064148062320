import React, { useState, useEffect } from "react";
import BreadCrumbs from "../../commonComponents/BreadCrumbs";
import ButtonComponent from "../../commonComponents/Button";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import CuratedPatternSVG from "../../assets/Curated-Pattern.svg";
import CuratedHumanSVG from "../../assets/Curated-Human.png";
import { JobAPI } from "../../utils/apiUrl";
import { CorporateCuratedAPI } from "../../utils/apiUrl";
import callAPI from "../../utils/apiAction";
import { AddToLikeListAPI } from "../../utils/apiUrl";
import DummyJob from "../../assets/DummyJob.png";
import ModalComponent from "../../commonComponents/ModalComponent";
import Signin from "../CustomizePathway/Signin";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { useLoginState } from "../../utils/LoginStateContext";
import PopoverComponent from "../../commonComponents/Popover";
import ShareComponent from "../../components/ShareComponent";
import { useParams } from "react-router-dom";
import { useIds } from "../../utils/IdsContext";

const ComboCard = ({
  img_src,
  title,
  location,
  goals,
  degree_name,
  university_name,
  university_logo,
  selected,
  onSelect,
  uniqueId,
  courseId,
  univId,
  jobId,
  total_like_count,
  liked_flag,
}) => {
  const isLikeFlag = liked_flag === "true" ? true : false;
  const [likeCount, setLikeCount] = useState(0);
  const [hasLiked, sethasLiked] = useState(isLikeFlag);
  const [likeCountByApi, setLikeCountByApi] = useState(total_like_count);
  // const isUserLogin = sessionStorage.getItem('signInCompleted');
  const { isLoggedIn, userInfo, login, logout } = useLoginState();
  const AddToLikeListURL = AddToLikeListAPI;
  const [isModalOpenOld, setIsModalOpenOld] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOpen = () => setIsModalOpen(true);
  const handleClose = () => setIsModalOpen(false);

  const handleLike = async () => {
    if (isLoggedIn === false) {
      setIsModalOpenOld(true);
    } else {
      sethasLiked((prevHasLiked) => !prevHasLiked);
      let newLikeCount = hasLiked ? likeCount - 1 : likeCount + 1;
      setLikeCount(newLikeCount);
      const queryParams = {
        mobileNo: sessionStorage.getItem("mobileNo"),
        courseId: courseId,
        univId: univId,
        jobId: jobId,
        isLike: !hasLiked,
      };
      try {
        const { data } = await callAPI(AddToLikeListURL, "POST", queryParams, {
          Authorization: "Bearer YourAccessToken",
        });
        setLikeCountByApi(data[0]?.totalLikedCount);
      } catch (error) {
        console.log("Error updating like count : ", error);
      }
    }
  };


  return (
    <div className={`combocard ${selected ? "selected" : ""}`} key={uniqueId}>
      <figure className="combocard__image-container">
        <img src={img_src ? img_src : DummyJob} alt="" />
        <figcaption className="image-caption">{title}</figcaption>
        {location && location.length > 3 ? (
          <ul className="location">
            {location.slice(0, 3).map((loc) => (
              <li key={loc.cityId}>{loc.cityName}</li>
            ))}
            <li>More+</li>
          </ul>
        ) : (
          <ul className="location">
            {location &&
              location
                .slice(0, 3)
                .map((loc) => <li key={loc.cityId}>{loc.cityName}</li>)}
          </ul>
        )}
      </figure>
      <svg width={0} height={0}>
        <defs>
          <linearGradient id="gradientColors" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor="#A9416F" />
            <stop offset="50%" stopColor="#7240B2" />
            <stop offset="100%" stopColor="#2D4CB0" />
          </linearGradient>
        </defs>
      </svg>

      <ul className="combocard__goals">
        {goals &&
          goals.map((it) => (
            <li>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <circle cx="12" cy="12" r="11.5" fill="white" />
                <circle cx="12" cy="12" r="11.5" stroke="#1F5EFF" />
                <circle
                  cx="12"
                  cy="12"
                  r="11.5"
                  stroke="url(#paint0_linear_7285_5400)"
                />
                <path
                  d="M10.0971 14.2539L16.6635 8.21381C16.8184 8.07127 17.0025 8 17.2155 8C17.4286 8 17.6126 8.07127 17.7676 8.21381C17.9225 8.35635 18 8.52561 18 8.7216C18 8.91759 17.9225 9.08686 17.7676 9.2294L10.6395 15.7862C10.4845 15.9287 10.3037 16 10.0971 16C9.8905 16 9.70971 15.9287 9.55475 15.7862L6.22314 12.7216C6.06818 12.5791 5.99393 12.4098 6.00039 12.2138C6.00684 12.0178 6.08755 11.8486 6.24251 11.706C6.39747 11.5635 6.58148 11.4922 6.79455 11.4922C7.00762 11.4922 7.19163 11.5635 7.34659 11.706L10.0971 14.2539Z"
                  fill="#1F5EFF"
                />
                <path
                  d="M10.0971 14.2539L16.6635 8.21381C16.8184 8.07127 17.0025 8 17.2155 8C17.4286 8 17.6126 8.07127 17.7676 8.21381C17.9225 8.35635 18 8.52561 18 8.7216C18 8.91759 17.9225 9.08686 17.7676 9.2294L10.6395 15.7862C10.4845 15.9287 10.3037 16 10.0971 16C9.8905 16 9.70971 15.9287 9.55475 15.7862L6.22314 12.7216C6.06818 12.5791 5.99393 12.4098 6.00039 12.2138C6.00684 12.0178 6.08755 11.8486 6.24251 11.706C6.39747 11.5635 6.58148 11.4922 6.79455 11.4922C7.00762 11.4922 7.19163 11.5635 7.34659 11.706L10.0971 14.2539Z"
                  fill="url(#paint1_linear_7285_5400)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_7285_5400"
                    x1="-1.56462e-07"
                    y1="12"
                    x2="24"
                    y2="12"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#B03A6E" />
                    <stop offset="0.535" stop-color="#773BC5" />
                    <stop offset="1" stop-color="#2B4DC0" />
                  </linearGradient>
                  <linearGradient
                    id="paint1_linear_7285_5400"
                    x1="6"
                    y1="12"
                    x2="18"
                    y2="12"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#B03A6E" />
                    <stop offset="0.535" stop-color="#773BC5" />
                    <stop offset="1" stop-color="#2B4DC0" />
                  </linearGradient>
                </defs>
              </svg>
              {it.goalName}
            </li>
          ))}
      </ul>
      <div className="combocard__uni-details">
        {university_logo ? (
          <>
            <img src={university_logo} />
          </>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="50"
            height="50"
            viewBox="0 0 64 66"
            fill="none"
          >
            <g filter="url(#filter0_dddii_7373_16462)">
              <circle cx="32" cy="28" r="26" fill="white" />
            </g>
            <mask
              id="mask0_7373_16462"
              maskUnits="userSpaceOnUse"
              x="14"
              y="7"
              width="36"
              height="36"
            >
              <rect x="14" y="7" width="36" height="36" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_7373_16462)">
              <path
                d="M20.1152 37V35.5H24.4997V28.75H22.3075V27.25H24.5342C24.6035 25.452 25.2857 23.9247 26.581 22.6679C27.8762 21.4112 29.4325 20.7 31.2497 20.5345V14.2694H38.8652V18.5384H32.7497V20.5345C34.567 20.7 36.1232 21.4112 37.4185 22.6679C38.7137 23.9247 39.396 25.452 39.4652 27.25H41.692V28.75H39.4997V35.5H43.8842V37H20.1152ZM25.9997 35.5H31.2497V28.75H25.9997V35.5ZM32.7497 35.5H37.9997V28.75H32.7497V35.5ZM26.0515 27.25H37.948C37.867 25.7328 37.2457 24.4784 36.0842 23.4869C34.9227 22.4957 33.5612 22 31.9997 22C30.4382 22 29.0767 22.4957 27.9152 23.4869C26.7537 24.4784 26.1325 25.7328 26.0515 27.25Z"
                fill="#C3C3C3"
              />
            </g>
            <defs>
              <filter
                id="filter0_dddii_7373_16462"
                x="0.271265"
                y="0.567816"
                width="63.4575"
                height="64.8897"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feMorphology
                  radius="1.43218"
                  operator="dilate"
                  in="SourceAlpha"
                  result="effect1_dropShadow_7373_16462"
                />
                <feOffset />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0.0862745 0 0 0 0 0.141176 0 0 0 0 0.172549 0 0 0 0.12 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_7373_16462"
                />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="1.43218" />
                <feGaussianBlur stdDeviation="1.43218" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0.0862745 0 0 0 0 0.141176 0 0 0 0 0.172549 0 0 0 0.16 0"
                />
                <feBlend
                  mode="normal"
                  in2="effect1_dropShadow_7373_16462"
                  result="effect2_dropShadow_7373_16462"
                />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feMorphology
                  radius="5.72874"
                  operator="erode"
                  in="SourceAlpha"
                  result="effect3_dropShadow_7373_16462"
                />
                <feOffset dy="5.72874" />
                <feGaussianBlur stdDeviation="5.72874" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0.0862745 0 0 0 0 0.141176 0 0 0 0 0.172549 0 0 0 0.16 0"
                />
                <feBlend
                  mode="normal"
                  in2="effect2_dropShadow_7373_16462"
                  result="effect3_dropShadow_7373_16462"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect3_dropShadow_7373_16462"
                  result="shape"
                />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="-2.86437" />
                <feGaussianBlur stdDeviation="0.537069" />
                <feComposite
                  in2="hardAlpha"
                  operator="arithmetic"
                  k2="-1"
                  k3="1"
                />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0.0862745 0 0 0 0 0.141176 0 0 0 0 0.172549 0 0 0 0.06 0"
                />
                <feBlend
                  mode="normal"
                  in2="shape"
                  result="effect4_innerShadow_7373_16462"
                />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="1.43218" />
                <feGaussianBlur stdDeviation="0.537069" />
                <feComposite
                  in2="hardAlpha"
                  operator="arithmetic"
                  k2="-1"
                  k3="1"
                />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0"
                />
                <feBlend
                  mode="normal"
                  in2="effect4_innerShadow_7373_16462"
                  result="effect5_innerShadow_7373_16462"
                />
              </filter>
            </defs>
          </svg>
        )}

        <div className="uni-content">
          <h4>{degree_name}</h4>
          <h5>
            from <strong>{university_name}</strong>
          </h5>
        </div>
      </div>

      <div className="combocard__like-share">
        {selected ? (
          <ButtonComponent
            children="Selected"
            className={"gradient-border-button"}
            onClick={onSelect}
          />
        ) : (
          <ButtonComponent
            children="Select"
            className={"gradient-border-button"}
            onClick={onSelect}
          />
        )}
        <div className="like-share-button">
          {isLoggedIn === false ? (
            <ModalComponent
              buttonContent={
                <>
                  <FavoriteBorderIcon /> {total_like_count}
                </>
              }
              style={{ backgroundColour: "white" }}
              children={
                <Signin sourceComponent={"like"} onClose={handleClose} />
              }
              open={isModalOpen}
              setOpen={setIsModalOpen}
              onOpen={handleOpen}
              onClose={handleClose}
            />
          ) : (
            <ButtonComponent onClick={handleLike}>
              {" "}
              {hasLiked ? (
                <FavoriteIcon sx={{ fill: "#A9416F" }} />
              ) : (
                <FavoriteBorderIcon />
              )}{" "}
              {likeCountByApi || 0}{" "}
            </ButtonComponent>
          )}
        </div>
      </div>
    </div>
  );
};

const CuratedBanner = () => {
  const CorporateCuratedURL = CorporateCuratedAPI;
  const { corporateId } = useIds();

  const [response, setResponse] = useState([]);
  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [CorporateCuratedResponse] = await Promise.all([
          callAPI(CorporateCuratedURL, "POST", {
            corporateId: corporateId,
            corporateType: "2",
          }),
        ]);
        const { data } = CorporateCuratedResponse;
        setResponse(data);
      } catch (e) {
        console.log(e);
      }
    };

    fetchData();
  }, []);

  const shuffledResponse = shuffleArray(response);
  const randomThree = shuffledResponse.slice(0, 3);

  const paths = [
    { label: "Home", url: "/" },
    { label: "Our Ecosystem", url: "/eco-system/" },
    {
      label: `${response[0] && response[0].company}`,
      url: `/eco-system/curated-partnership/${corporateId}`,
    },
  ];
  return (
    <div>
      <div className="curated-path">
        <div className="top-section">
          <div className="bread-curated-div">
            <BreadCrumbs paths={paths} color="black" />
          </div>
        </div>
        <div className="curated-banner-svg">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="649"
            height="436"
            viewBox="0 0 649 436"
            fill="none"
          >
            <path
              d="M649 448.995H447.136L324.569 250.652H526.403L649 448.995ZM323.845 -73L425.065 88.5376L201.476 449H0L323.845 -73Z"
              fill="url(#paint0_linear_2877_49086)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_2877_49086"
                x1="324.5"
                y1="-73"
                x2="324.5"
                y2="449"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#F1F1F3" />
                <stop offset="1" stop-color="white" stop-opacity="0.08" />
              </linearGradient>
            </defs>
          </svg>
        </div>
        <div className="curated-company-logosvg">
          <img src={response[0] && response[0].companyLogo} alt="" />
          <h1>Career Launch Programs</h1>
        </div>
        <div className="curated-pattern-svg">
          <img src={CuratedPatternSVG} alt="" />
        </div>
        <div className="curated-human-svg">
          <img src={CuratedHumanSVG} alt="" />
        </div>
      </div>
      <div className="grid-box">
        <div className="grid-heading">
          <h1>Curated Careers at {response[0] && response[0].company}</h1>
        </div>
        <div className="curated-grid">
          {randomThree &&
            randomThree.map((each, index) => (
              <ComboCard
                key={index}
                title={each.jobTitle}
                location={each.cityDetails}
                goals={each.goalDetails}
                degree_name={each.courseName}
                university_name={each.universityName}
                total_like_count={each.totalLikedCount}
              />
            ))}
        </div>
      </div>
    </div>
  );
};
export default CuratedBanner;
