import React from 'react'
import './style.scss'
import FindPathwayPath from './FindPathwayPath'
import JobCompare from './JobCompare'
import PersonaliseCareer from './PersonaliseCareer'
const index = () => {
  return (
    <>
        <FindPathwayPath />
        <JobCompare />
        <PersonaliseCareer/>
    </>
  )
}
export default index