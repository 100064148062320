import React from 'react'
import CorporateBanner from './CorporateBanner'
import './style.scss';
const index = () => {
  return (
    <div>
      <CorporateBanner/>
    </div>
  )
}
export default index
