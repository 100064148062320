import React, { useState, useEffect } from "react";
import BreadCrumbs from "../../commonComponents/BreadCrumbs";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import "./style.scss";
import Footer from "../../components/FooterLayout";
import { AptAPI } from "../../utils/apiUrl";
import callAPI from "../../utils/apiAction";
import { useLoginState } from "../../utils/LoginStateContext";
import Signin from "../CustomizePathway/Signin";
import ModalComponent from "../../commonComponents/ModalComponent";
import { Helmet } from "react-helmet-async";

const Aptitude = () => {
  const AptURL = AptAPI;
  const paths = [
    { label: "Home", url: "/" },
    { label: "Aptitude Test", url: "/aptitude/" },
  ];
  const mobileNo = sessionStorage.getItem("mobileNo");
  const [reportsAvailable, setReportsAvailable] = useState(false);
  const { isLoggedIn, userInfo, login, logout } = useLoginState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOpen = () => setIsModalOpen(true);
  const handleClose = () => setIsModalOpen(false);
  const [downloadLink, setDownloadLink] = useState("");
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [Response] = await Promise.all([
          callAPI(AptURL, "POST", {
            mobileNo: mobileNo,
          }),
        ]);
        const { data } = Response;
        // console.log(data);
        if (data && data.reports && data.reports.length > 0) {
          setDownloadLink(data.reports[0].report2);
          // console.log(data.reports[0].report2);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);
  return (
    <div>
<Helmet>
<link rel="canonical" href={`${window.location.origin}${window.location.pathname}`}/>
{/* Meta Pixel Code */}
<script>
          {`
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '517004411113375');
            fbq('track', 'PageView');
          `}
        </script>
        <noscript>
          {`<img height="1" width="1" style="display:none"
          src="https://www.facebook.com/tr?id=517004411113375&ev=PageView&noscript=1" />`}
        </noscript>
        {/* Meta Pixel Code */}
</Helmet>
      <div className="apt-parent">
        <div className="apt-banner">
          <div className="flex-it">
            <div className="left-section">
              <div className="breadcrumbs-div">
                <BreadCrumbs paths={paths} />
              </div>
              <div className="title">
                <h1>Aptitude Test</h1>
                <p>
                  Discover your ideal career with this comprehensive assessment
                </p>
              </div>
            </div>

            <div className="svgs svg-large">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="656"
                height="400"
                viewBox="0 0 656 500"
                fill="none"
              >
                <g filter="url(#filter0_f_2877_47719)">
                  <path
                    d="M265.061 -26.3534C265.061 -26.3534 215.34 87.4658 235.357 185.386C262.294 317.161 397.919 356.442 421.022 469.456C444.124 582.471 370.976 691.478 370.976 691.478"
                    stroke="#0049D7"
                    stroke-width="60"
                  />
                </g>
                <defs>
                  <filter
                    id="filter0_f_2877_47719"
                    x="0.608398"
                    y="-238.35"
                    width="654.926"
                    height="1146.53"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                  >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="BackgroundImageFix"
                      result="shape"
                    />
                    <feGaussianBlur
                      stdDeviation="100"
                      result="effect1_foregroundBlur_2877_47719"
                    />
                  </filter>
                </defs>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="592"
                height="400"
                viewBox="0 0 592 500"
                fill="none"
              >
                <g filter="url(#filter0_f_2877_47720)">
                  <path
                    d="M252.431 -176.286C252.431 -176.286 212.021 -16.9422 241.197 125.782C280.46 317.851 422.092 386.564 455.766 551.289C489.44 716.014 424.742 866.328 424.742 866.328"
                    stroke="#FA1F3C"
                    stroke-width="60"
                  />
                </g>
                <defs>
                  <filter
                    id="filter0_f_2877_47720"
                    x="0.615234"
                    y="-383.648"
                    width="694.863"
                    height="1461.82"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                  >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="BackgroundImageFix"
                      result="shape"
                    />
                    <feGaussianBlur
                      stdDeviation="100"
                      result="effect1_foregroundBlur_2877_47720"
                    />
                  </filter>
                </defs>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="480"
                height="400"
                viewBox="0 0 480 500"
                fill="none"
              >
                <g filter="url(#filter0_f_2877_47721)">
                  <path
                    d="M258.24 -144.231C258.24 -144.231 212.861 -9.19531 237.146 109.606C269.828 269.482 408.248 322.481 436.278 459.596C464.307 596.711 395.101 724.969 395.101 724.969"
                    stroke="#06C07D"
                    stroke-width="60"
                  />
                </g>
                <defs>
                  <filter
                    id="filter0_f_2877_47721"
                    x="-0.00195312"
                    y="-353.774"
                    width="672.963"
                    height="1292.97"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                  >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="BackgroundImageFix"
                      result="shape"
                    />
                    <feGaussianBlur
                      stdDeviation="100"
                      result="effect1_foregroundBlur_2877_47721"
                    />
                  </filter>
                </defs>
              </svg>
            </div>

            <div className="svgs svg-small">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="346"
                height="400"
                viewBox="0 0 346 400"
                fill="none"
              >
                <g opacity="0.6" filter="url(#filter0_f_3049_33410)">
                  <path
                    d="M187.878 152.267C187.878 152.267 162.416 210.554 172.667 260.699C186.461 328.18 255.914 348.296 267.745 406.17C279.576 464.045 242.117 519.867 242.117 519.867"
                    stroke="#0049D7"
                    stroke-width="60"
                  />
                </g>
                <defs>
                  <filter
                    id="filter0_f_3049_33410"
                    x="0.235352"
                    y="0.270508"
                    width="439.821"
                    height="676.298"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                  >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="BackgroundImageFix"
                      result="shape"
                    />
                    <feGaussianBlur
                      stdDeviation="70"
                      result="effect1_foregroundBlur_3049_33410"
                    />
                  </filter>
                </defs>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="304"
                height="400"
                viewBox="0 0 304 601"
                fill="none"
              >
                <g opacity="0.6" filter="url(#filter0_f_3049_33411)">
                  <path
                    d="M181.403 103.486C181.403 103.486 160.709 185.086 175.65 258.175C195.757 356.533 268.286 391.721 285.53 476.076C302.775 560.431 269.643 637.407 269.643 637.407"
                    stroke="#FA1F3C"
                    stroke-width="60"
                  />
                </g>
                <defs>
                  <filter
                    id="filter0_f_3049_33411"
                    x="0.231445"
                    y="-43.877"
                    width="460.272"
                    height="833.128"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                  >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="BackgroundImageFix"
                      result="shape"
                    />
                    <feGaussianBlur
                      stdDeviation="70"
                      result="effect1_foregroundBlur_3049_33411"
                    />
                  </filter>
                </defs>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="247"
                height="400"
                viewBox="0 0 247 601"
                fill="none"
              >
                <g opacity="0.6" filter="url(#filter0_f_3049_33412)">
                  <path
                    d="M184.732 119.903C184.732 119.903 161.494 189.054 173.93 249.892C190.667 331.764 261.551 358.905 275.905 429.121C290.259 499.337 254.818 565.018 254.818 565.018"
                    stroke="#06C07D"
                    stroke-width="60"
                  />
                </g>
                <defs>
                  <filter
                    id="filter0_f_3049_33412"
                    x="0.269531"
                    y="-29.6416"
                    width="449.058"
                    height="748.89"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                  >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="BackgroundImageFix"
                      result="shape"
                    />
                    <feGaussianBlur
                      stdDeviation="70"
                      result="effect1_foregroundBlur_3049_33412"
                    />
                  </filter>
                </defs>
              </svg>
            </div>
          </div>
        </div>
        <div className="apt-menus">
          <div className="apt-head">
          <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
  <circle cx="32" cy="32" r="31.5" fill="#FAF1FF" stroke="#E4D3EE"/>
  <mask id="mask0_10917_20699" style={{"mask-type":"alpha"}} maskUnits="userSpaceOnUse" x="12" y="12" width="40" height="40">
    <rect x="12" y="12" width="40" height="40" fill="#D9D9D9"/>
  </mask>
  <g mask="url(#mask0_10917_20699)">
    <path d="M32.5 38.1858C32.2992 38.1858 32.1122 38.1537 31.9392 38.0896C31.7661 38.0257 31.6015 37.9157 31.4454 37.7596L26.2629 32.5771C26.0151 32.329 25.8928 32.0389 25.8958 31.7067C25.8992 31.3744 26.0215 31.079 26.2629 30.8204C26.5215 30.5621 26.8185 30.4286 27.1537 30.42C27.4893 30.4114 27.7864 30.5364 28.045 30.795L31.25 34V21.25C31.25 20.8953 31.3697 20.5983 31.6092 20.3592C31.8483 20.1197 32.1453 20 32.5 20C32.8547 20 33.1517 20.1197 33.3908 20.3592C33.6303 20.5983 33.75 20.8953 33.75 21.25V34L36.955 30.795C37.2031 30.5472 37.4974 30.4249 37.8379 30.4279C38.1787 30.4313 38.4785 30.5621 38.7371 30.8204C38.9785 31.079 39.1035 31.3718 39.1121 31.6987C39.1207 32.0257 38.9957 32.3185 38.7371 32.5771L33.5546 37.7596C33.3985 37.9157 33.2339 38.0257 33.0608 38.0896C32.8878 38.1537 32.7008 38.1858 32.5 38.1858ZM23.0129 45C22.171 45 21.4583 44.7083 20.875 44.125C20.2917 43.5417 20 42.829 20 41.9871V38.7179C20 38.3632 20.1197 38.0662 20.3592 37.8271C20.5983 37.5876 20.8953 37.4679 21.25 37.4679C21.6047 37.4679 21.9017 37.5876 22.1408 37.8271C22.3803 38.0662 22.5 38.3632 22.5 38.7179V41.9871C22.5 42.1154 22.5535 42.2329 22.6604 42.3396C22.7671 42.4465 22.8846 42.5 23.0129 42.5H41.9871C42.1154 42.5 42.2329 42.4465 42.3396 42.3396C42.4465 42.2329 42.5 42.1154 42.5 41.9871V38.7179C42.5 38.3632 42.6197 38.0662 42.8592 37.8271C43.0983 37.5876 43.3953 37.4679 43.75 37.4679C44.1047 37.4679 44.4017 37.5876 44.6408 37.8271C44.8803 38.0662 45 38.3632 45 38.7179V41.9871C45 42.829 44.7083 43.5417 44.125 44.125C43.5417 44.7083 42.829 45 41.9871 45H23.0129Z" fill="url(#paint0_linear_10917_20699)"/>
  </g>
  <defs>
    <linearGradient id="paint0_linear_10917_20699" x1="20" y1="32.5" x2="45" y2="32.5" gradientUnits="userSpaceOnUse">
      <stop stop-color="#A9416F"/>
      <stop offset="0.535" stop-color="#7240B2"/>
      <stop offset="1" stop-color="#2D4CB0"/>
    </linearGradient>
  </defs>
</svg>
            <h2>
            To access your comprehensive report with detailed metrics
            </h2>
          </div>
          <div className="apt-buttons">
            
            {!isLoggedIn ? (
              <ModalComponent
                buttonContent={"Download Report"}
                buttonId={"download"}
                style={{ backgroundColour: "white" }}
                children={
                  <Signin
                    sourceComponent={"aptitude-test"}
                    onClose={handleClose}
                  />
                }
                open={isModalOpen}
                setOpen={setIsModalOpen}
                onOpen={handleOpen}
                onClose={handleClose}
              />
            ) : (
              <a id="download" href={downloadLink}>Download Report</a>
            )}
          </div>
        </div>
        {reportsAvailable && (
          <div className="apt-menus">
            <div className="apt-head">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="64"
                height="64"
                viewBox="0 0 64 64"
                fill="none"
              >
                <circle cx="32" cy="32" r="32" fill="#D9D9D9" />
                <circle
                  cx="32"
                  cy="32"
                  r="32"
                  fill="url(#paint0_linear_10888_33487)"
                />
                <circle
                  cx="32"
                  cy="32"
                  r="31.5"
                  stroke="#FCD28C"
                  stroke-opacity="0.1"
                />
                <mask
                  id="mask0_10888_33487"
                  style={{ "mask-type": "alpha" }}
                  maskUnits="userSpaceOnUse"
                  x="12"
                  y="12"
                  width="40"
                  height="40"
                >
                  <rect x="12" y="12" width="40" height="40" fill="#D9D9D9" />
                </mask>
                <g mask="url(#mask0_10888_33487)">
                  <path
                    d="M31.9993 39.8844C32.3807 39.8844 32.7005 39.7554 32.9585 39.4973C33.2166 39.2393 33.3456 38.9196 33.3456 38.5382C33.3456 38.1568 33.2166 37.8371 32.9585 37.579C32.7005 37.3212 32.3807 37.1923 31.9993 37.1923C31.618 37.1923 31.2982 37.3212 31.0402 37.579C30.7821 37.8371 30.6531 38.1568 30.6531 38.5382C30.6531 38.9196 30.7821 39.2393 31.0402 39.4973C31.2982 39.7554 31.618 39.8844 31.9993 39.8844ZM31.9998 33.7948C32.3542 33.7948 32.651 33.675 32.8902 33.4353C33.1296 33.1958 33.2493 32.899 33.2493 32.5448V25.0448C33.2493 24.6907 33.1295 24.3937 32.8898 24.154C32.65 23.9146 32.3531 23.7948 31.9989 23.7948C31.6445 23.7948 31.3477 23.9146 31.1085 24.154C30.8691 24.3937 30.7493 24.6907 30.7493 25.0448V32.5448C30.7493 32.899 30.8692 33.1958 31.1089 33.4353C31.3487 33.675 31.6456 33.7948 31.9998 33.7948ZM32.0023 47.8332C29.8123 47.8332 27.7538 47.4176 25.8268 46.5865C23.8999 45.7554 22.2238 44.6275 20.7985 43.2028C19.3732 41.778 18.2448 40.1026 17.4131 38.1765C16.5817 36.2504 16.166 34.1925 16.166 32.0028C16.166 29.8128 16.5816 27.7543 17.4127 25.8273C18.2438 23.9004 19.3717 22.2243 20.7964 20.799C22.2212 19.3737 23.8966 18.2453 25.8227 17.4136C27.7488 16.5822 29.8067 16.1665 31.9964 16.1665C34.1864 16.1665 36.2449 16.5821 38.1718 17.4132C40.0988 18.2443 41.7749 19.3722 43.2002 20.7969C44.6255 22.2216 45.7539 23.8971 46.5856 25.8232C47.417 27.7493 47.8327 29.8072 47.8327 31.9969C47.8327 34.1869 47.4171 36.2454 46.586 38.1723C45.7549 40.0993 44.627 41.7754 43.2023 43.2007C41.7775 44.6259 40.1021 45.7544 38.176 46.5861C36.2499 47.4175 34.192 47.8332 32.0023 47.8332ZM31.9993 45.3332C35.7216 45.3332 38.8743 44.0415 41.4577 41.4582C44.041 38.8748 45.3327 35.7221 45.3327 31.9998C45.3327 28.2776 44.041 25.1248 41.4577 22.5415C38.8743 19.9582 35.7216 18.6665 31.9993 18.6665C28.2771 18.6665 25.1243 19.9582 22.541 22.5415C19.9577 25.1248 18.666 28.2776 18.666 31.9998C18.666 35.7221 19.9577 38.8748 22.541 41.4582C25.1243 44.0415 28.2771 45.3332 31.9993 45.3332Z"
                    fill="#FCA004"
                  />
                </g>
                <defs>
                  <linearGradient
                    id="paint0_linear_10888_33487"
                    x1="0"
                    y1="0"
                    x2="71.6827"
                    y2="10.245"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#FFFBED" />
                    <stop offset="1" stop-color="#FDF6DD" />
                  </linearGradient>
                </defs>
              </svg>
              <h2>Report Not Yet Available</h2>
              <p>
                Our results usually take a little time to update. <br />
                Please check back in few minutes.
              </p>
            </div>
          </div>
        )}
        <Footer />
      </div>
    </div>
  );
};

export default Aptitude;
