import React, { useEffect, useState } from 'react'
import { reports } from '../Digiverse/RelatedReports'
import { useParams } from 'react-router-dom'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ButtonComponent from '../../commonComponents/Button';
import DownloadIcon from '@mui/icons-material/Download';
import PopoverComponent from '../../commonComponents/Popover';
import ShareComponent from '../../components/ShareComponent';
import ReportPDF from "../../assets/ReportPDF.pdf"
const DetailedReport = () => {
  const { reportId } = useParams();
  const [detailedReport, setDetailedReport] = useState({});
  const [likeCount, setLikeCount] = useState(0);
  const [hasLiked, sethasLiked] = useState(false);
  const [anchorElShare, setAnchorElShare] = useState(null);
    const closePopover = (setter) => {
        setter(null)
    }
    const handleClickShare = (event) => {
        setAnchorElShare(event.currentTarget);
    };


  const handleLike = () => {
    sethasLiked((prevHasLiked)=> !prevHasLiked);
    if(!hasLiked){
      setLikeCount((prevLikeCount) => prevLikeCount +1);
    }
    else{
      setLikeCount((prevLikeCount) => prevLikeCount -1);
    }
  }
  const[shareImageUrl, setShareImageUrl] = useState("");

  useEffect(() => {
    const foundReport = reports.find((item) => item.route === reportId);
    if (foundReport) {
      setDetailedReport(foundReport);
    }
  }, [reportId]);
  const handleShare = async (pdf_link, title) => {
    const shareText = `Checkout the report: ${title} by Digivarsity`;
    const shareURL = window.location.href;
    const finalShareText = `${shareText}\n${shareURL}\n${pdf_link}`;
  
    if (navigator.share) {
      try {
        await navigator.share({
          title: "My Latest Pathway",
          text: `${finalShareText}`,
          url: pdf_link,
        });
      } catch (error) {
        console.error("Error sharing:", error);
      }
    } else {
      const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(
        finalShareText
      )}`;
      window.open(whatsappUrl, "_blank");
    }
  };
  
    const handleDownload = () => {
      window.open(detailedReport.pdf_link, '_blank', 'noopener,noreferrer');
    };
    

    
    
  return (
    <div className='main-report'>
      <div className="main-report-card">
        <img src={detailedReport.longimage_src} alt='' className='main-report-image' />
        <img src={detailedReport.image_src} alt='' className='main-mobile-report-image' />
        <h6 className='mins-read'>{detailedReport.time} mins read - {detailedReport.date}</h6>
        <h1 className='heading-title'>{detailedReport.title}</h1>
        <div className="synopsis-content">
          <h5>Synopsis</h5>
          <p>{detailedReport.synopsis}</p>
        </div>

        <div className='like-share'>
          <ButtonComponent onClick={() => handleShare(detailedReport.pdf_link, detailedReport.title)}> <a href={`${detailedReport.pdf_link}` } target='_blank' rel="noopener noreferrer"><ShareIcon /> </a></ButtonComponent>
          <ButtonComponent onClick={handleDownload} > <a> <DownloadIcon/></a> </ButtonComponent>
        </div>
      </div>

    </div>
  )
}

export default DetailedReport