import React, {useState} from 'react'
import SelectComponent from '../commonComponents/SelectComponent';
const languages = [
    { id: "01", value: "English", label: "English"},
]

const LanguageSelect = () => {
    const [selectedValue, setSelectedValue] = useState('English');
    const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };
  return (
    <>
        <div className='lang-box'>
            <SelectComponent 
                options={languages}
                onChange={handleChange}
                value={selectedValue}
                className={'language-select'}
                labelClassName={'language-select-label'}
            />
            
        </div>
    </>
  )
}

export default LanguageSelect