import React from 'react'
import "./style.scss";
import { useNavigate } from 'react-router-dom';
import { useScroll } from '../../commonComponents/ScrollContext';
const PersonaliseCareer = () => {
    const navigate = useNavigate();
    const { scrollToChoosePathway } = useScroll();
    const handleClick = () => {
        navigate("/");
        const delayTime = 400;
        const timer = setTimeout(() => {
          scrollToChoosePathway();
        }, delayTime);
  
        return () => clearTimeout(timer);
    }
  return (
    <div>
      <div className="parent">
        <div className="heading-child">
<h4>Looking for more options? Customise Your Pathway</h4>
        </div>
        <div className="button-child">
            <button id={"pathwaycomparison-personalizecareer"} onClick={handleClick}>
                Personalize Your Career Journey
            </button>
        </div>
      </div>
    </div>
  )
}

export default PersonaliseCareer
