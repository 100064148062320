import { REFRESH_TOKEN } from "../utils/apiUrl";
import callAPI from "../utils/apiAction";
import { useState } from "react";
import encrypt from "../configuration/encrypt";
let accessTokenPromise = null;
let accessToken = "";

const fetchAccessToken = async () => {
  try {
    var mobileNo = sessionStorage.getItem("mobileNo") || 0;
    var bodyParam = JSON.stringify({ mobileNo: mobileNo, userName: "" });
    const encryptedData = await encrypt(bodyParam);
    const data = encryptedData;

    if (encryptedData !== undefined) {
      const response = await fetch(REFRESH_TOKEN, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data }),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch access token");
      }

      const tokenData = await response.json();
      accessToken = tokenData.data.token;

      const expires = new Date();
      expires.setTime(expires.getTime() + tokenData.expires_in * 1000); // Convert expires_in to milliseconds
      document.cookie = `access_token=${accessToken};expires=${expires.toUTCString()};path=/`;
    }
    return accessToken;
  } catch (error) {
    console.error("Error fetching access token:", error);
    throw error;
  }
};

const getAccessTokenFromCookie = () => {
  const cookieValue = document.cookie
    .split("; ")
    .find((row) => row.startsWith("access_token="))
    ?.split("=")[1];

  return cookieValue || "";
};

const getAccessToken = async () => {
  accessToken = await fetchAccessToken();

  return accessToken;
};

export { fetchAccessToken, getAccessToken };
