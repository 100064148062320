import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Emergency from "../../assets/emergency_home.png";
import "./disclaimer.scss";
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const Disclaimer = () => {
  const location = useLocation();
  const { url, university_name} = location.state || {}; // Destructure url from state, default to an empty object
  console.log(location.state);

  useEffect(() => {
    function getUniversityInitials(universityName) {
      let lowerCaseName = universityName.toLowerCase();
      let words = lowerCaseName.split(/[\s-()]+/);
      let initials = words.map(word => word.charAt(0)).join('');
      return initials + '_ums';
    }


    if (university_name) {
      const initials = getUniversityInitials(university_name);
      console.log(initials); 
    }
  }, [university_name]);
  
  // console.log("Received URL in Disclaimer:", location.state);
  return (
    <div className="discparentdiv">
    <Helmet>
    <link rel="canonical" href={`${window.location.origin}${window.location.pathname}`}/>
    <script>
        {`!function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '517004411113375');
        fbq('track', 'PageView');
        fbq('track', 'Purchase');`}
      </script>
      <noscript>{`
        <img
          height="1"
          width="1"
          style="display:none"
          src="https://www.facebook.com/tr?id=517004411113375&ev=PageView&noscript=1"
          alt="Facebook Pixel"
        />
      `}</noscript>
    </Helmet>
      <div className="disclaimer-div">
        <div className="imgdiv">
          <img src={Emergency} alt="Emergency" />
        </div>
        <div className="headdiv">
          <h2>Disclaimer</h2>
        </div>
        <div className="ponediv">
          <p>
            The showcased Work Integrated Degree Programs on Digivarsity are curated based on current available information provided by participating employers and universities.
            <br /><br />
            Digivarsity does not collect any fee as a part of the application process. Any payments made by students as part of admission process are directly collected by the university via the university platform.
          </p>
        </div>
        <Link to={url} target='_blank'>
          <div className="continuediv">
            <button className='primary-btn' id='disclaimer-continue-button'>Continue with Application</button>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Disclaimer;
