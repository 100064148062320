
const environment = "Development" //(process.browser ? window.NEXT_PUBLIC_APIENV : process.env.NEXT_PUBLIC_APIENV)||"Development";
export default {
  "environment":environment,
  "ApiServerURL": {
    "Development": "https://webapi.digivarsity.com/",
    "QA": "",
    "UAT": "",    
    "Portal":"https://webapi.digivarsity.com/"  
  },
  "LoggingURL":{
    "default":""
  }
}
