import React from "react";
const AboutWork = () => {
  return (
    <div className="work-section">
      <div className="work-left">
        <h2>
          Where Belief in Education Meets the Power of <span>Work</span>
        </h2>
      </div>
      <div className="work-right">
        <p>
          With a firm belief in inclusivity, affordability, and accessibility,
          we offer work-integrated degree programs designed to maximize impact.
          Through partnerships with universities, colleges, and employers, we
          provide hands-on experience, breaking the cycle of unemployability and
          poverty. Our innovative approach combines learning by practice with
          earning a monthly stipend, ensuring students are equipped with both
          skills and confidence.
          <br></br>
          <br></br>
          Today, Digivarsity is ready to make a big difference in our country's
          future by combining innovation and change. We're happy about how
          colleges and businesses work together to help people learn the skills
          they need to get good jobs.
        </p>
      </div>
    </div>
  );
};
export default AboutWork;
