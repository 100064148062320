import React from 'react'
import ConfirmSlotPath from "./ConfirmSlotPath"
import BookSlot from "./BookSlot"
import './style.scss';
const index = () => {
  return (
    <>
        <ConfirmSlotPath />
        <BookSlot />
    </>
  )
}
export default index