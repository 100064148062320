import React from 'react';
import ContactBanner from './ContactBanner';
import "./style.scss";
function index() {
  return (
   <>
   <ContactBanner/>
   </>
  )
}
export default index