import React from "react";
function AboutMission() {
  return (
    <div className="mission-section">
        <h2>Our Mission</h2>
      <div className="mission-right">
        <div>to foster innovation</div>
        <div>Boost Employment</div>
        <div>build a thriving, skilled workforce</div>
      </div>
    </div>
  );
}
export default AboutMission;
