import React, { useEffect } from "react";
import "./fonts/OpenSauceSans-Regular.ttf";
import "./theme.scss";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  Navigate,
  // ScrollRestoration
} from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';
import About from "./pages/About/index.jsx";
import Digiverse from "./pages/Digiverse/index.jsx";
import EcoSystem from "./pages/EcoSystem/index.jsx";  
import Home from "./pages/Home/index.jsx";
import IntegratedPrograms from "./pages/IntegratedPrograms/index.jsx";
import Events from "./pages/Events/index.jsx";
import Reports from "./pages/Reports/index.jsx";
import News from "./pages/News/index.jsx";
import CustomizePathway from "./pages/CustomizePathway/index.jsx";
import FindPathway from "./pages/FindPathway/index.jsx";
import Summary from "./pages/Summary/index.jsx";
import ConfirmSlot from "./pages/ConfirmSlot/index.jsx";
import University from "./pages/University/index.jsx";
import ContactUs from "../src/pages/ContactUs/index.jsx";
import HeaderLayout from "./components/HeaderLayout.jsx";
import FooterLayout from "./components/FooterLayout.jsx";
import { ActiveStepProvider } from "./utils/ActiveStepContext.js";
import { ScrollProvider } from "./commonComponents/ScrollContext.jsx";
import ScrollToTop from "./components/ScrollToTop.jsx";
import TermsPolicy from "./pages/TermsPolicy/TermsPolicy.jsx";
import PrivacyPolicy from "./pages/PrivacyPolicy/index.jsx";
import FaqBanner from "./pages/Faq/index.jsx";
import { LoginStateProvider } from "./utils/LoginStateContext.js";
import Corporate from "./pages/Corporate/index.jsx";
import CourseDetails from "./pages/CourseDetails/index.jsx";
import Curated from "./pages/Curated/index.jsx";
import EventDetails from "./pages/Digiverse/EventDetails.jsx";
import { IdsProvider } from "./utils/IdsContext.js";
import WhatsappIcon from "./assets/whatsappicon.png";
import Disclaimer from "./pages/Summary/Disclaimer.jsx";
import Aptitude from "./pages/Aptitude/Aptitude.jsx";
import ContactThank from "./pages/ContactUs/ContactThank.jsx";
import AptitudeHome from "./pages/Aptitude/AptitudeHome.jsx";
import MyPathway from "./pages/MyPathway/MyPathway.jsx";
import ZoomVerify from "./pages/ZoomVerify/ZoomVerify.jsx";
import UsePageTracking from "./pages/System/UsePageTracking.jsx";
import HdbFinancial from "./pages/Ad/HdbFinancial.jsx";


const AppContent = () => {
  const location = useLocation();
 
  
    useEffect(() => {
      // Check for the query string in the URL
      if (location.search.includes("lang=en")) {
        const path = location.pathname;
  
        // Redirect based on the pathname
        if (path === "/faqs/") {
          window.location.replace("/faq/");
        } else if (path === "/programs/") {
          window.location.replace("/work-integrated-programs/");
        } else if (path === "/employers/") {
          window.location.replace("/eco-system/");
        } else if (path === "/terms-and-conditions/") {
          window.location.replace("/terms-policy/");
        } else if (path === "/privacy-policy/") {
          window.location.replace("/privacy-policy/");
        } else if (path === "/") {
          window.location.replace("/");
        }
      }
    }, [location]);
  const isFaqPage = location.pathname.includes("/faq/");
  const isAboutPage = location.pathname.includes("/about/");
  const isAptPage = location.pathname.includes("/aptitude/");
  const isAptHomePage = location.pathname.includes("/aptitude/give-test/");
  const isWorkIntegratedPage = location.pathname.includes(
    "/work-integrated-programs/"
  );
  const shouldShowFooter = !isFaqPage && !isAboutPage && !isWorkIntegratedPage && !isAptPage && !isAptHomePage;
  // const location = useLocation();

  // useEffect(() => {
  //   if (!location.pathname.endsWith('/')) {
  //     window.location.replace(`${location.pathname}/`);
  //   }
  // }, [location]);
  useEffect(() => {
    if (!location.pathname.endsWith('/')) {
      window.history.replaceState(null, '', `${location.pathname}/`);
    }
  }, [location]);

  // Redirect to the same route but with a trailing slash
  if (!location.pathname.endsWith('/')) {
    return <Navigate to={`${location.pathname}/`} replace={true} />;
  }



  // return null;
  return (
    <>
      <HeaderLayout />
      <Routes>
        <Route path={process.env.REACT_APP_BASE_URL} element={<Home />} />
        <Route path="about/" element={<About />} />
        <Route path="aptitude/give-test/" element={<AptitudeHome />} />     
        <Route path="eco-system/" element={<EcoSystem />} />
        <Route path="work-integrated-programs/" element={<IntegratedPrograms />} />
        <Route path="digiverse/" element={<Digiverse />} />
        <Route path="events/" element={<Events />} />
        <Route path="events/:eventName" element={<EventDetails/>}></Route>
        <Route path="news/:newsId" element={<News />} />
        <Route path="reports/:reportId" element={<Reports />} />
        <Route path="personalize-pathway/" element={<CustomizePathway />} />
        <Route path="pathway-comparison/" element={<FindPathway />} />
        <Route path="pathway-comparison-summary/" element={<Summary />} />
        <Route path="eco-system/university-partnership/:uniName/" element={<University />} />
        <Route path="pathway-comparison/:uniName/" element={<University />} />
        <Route
          path="eco-system/:unicourName/:courseName/"
          element={<CourseDetails />}
        ></Route>
        <Route
          path="pathway-comparison/:unicourName/:courseName/"
          element={<CourseDetails />}
        ></Route>
        <Route
          path="eco-system/corporate-partnership/:corpName/"
          element={<Corporate />}
        />
        <Route
          path="eco-system/curated-partnership/curated-details"
          element={<Curated />}
        />
        <Route path="contact-us/" element={<ContactUs />} />
        <Route path="thank-you/" element={<ContactThank />} />
        <Route path="terms-policy/" element={<TermsPolicy />} />
        <Route path="privacy-policy/" element={<PrivacyPolicy />} />
        <Route path="confirm-slot/" element={<ConfirmSlot />} />
        <Route path="faq/" element={<FaqBanner />} />
        <Route path = "disclaimer/" element={<Disclaimer/>}/>
        <Route path="Pathways/" element={<MyPathway/>}></Route>
        <Route path="zoomverify/verifyzoom.html" element={<ZoomVerify/>}></Route>
        <Route path="hdb-financial-services/" element={<HdbFinancial/>}></Route>
        {/* Redirect old routes to new ones */}
        {/* <Route path="/employers/" element={<Navigate to="/eco-system/" replace />} /> */}
        {/* <Route path="/programs/" element={<Navigate to="/work-integrated-programs/" replace />} /> */}
        
        
      
      </Routes>
      {shouldShowFooter && <FooterLayout />}
    </>
  );
};


function App() {
  const BASE_ROUTE=process.env.REACT_APP_BASE_URL;
  return (
    <HelmetProvider>
    <Router basename={BASE_ROUTE}>
    <UsePageTracking/>
    <ScrollToTop/>
      <ActiveStepProvider>
        <LoginStateProvider>
          <IdsProvider>
          <div className="App">
            <main className="layoutSection">
              <ScrollProvider>
                <ScrollToTop />
                {/* <usePageTracking/> */}

                <AppContent />
                
                <div className="whatsapp-icon" id="whatsapp-widget">
                <a href="https://api.whatsapp.com/send/?phone=917204677888&text&type=phone_number&app_absent=0" target="_blank">
                  <img src={WhatsappIcon} alt="" /></a>
                </div>
              </ScrollProvider>
            </main>
          </div>
          </IdsProvider>
        </LoginStateProvider>
      </ActiveStepProvider>
    </Router>
    </HelmetProvider>
  );
}

export default App;
